import React, { FC } from 'react'
import uniqueId from 'lodash/uniqueId'
import ScrollArea from 'react-scrollbar'
import { Thead } from './Thead'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import { Item } from '@root-gipro/shared/Table/components/Thead'
import { ICommonProject, Notices, Plans } from '@root-gipro/store/interfaces'
import { IBodyParamsCommonProjects } from '@root-gipro/modules/commonProjects/models/const/invest-table-body.const'
import { IBodyParamsPurchases } from '@root-gipro/modules/purchases/models/consts/invest-table-body.const'

export interface TableProps {
	items: (Notices | Plans | ICommonProject)[] | null | undefined;
	theadParams: {
		theadData: Item[];
		gridTemplateColumns: string;
	};
	bodyParams: (history: History) => (IBodyParamsCommonProjects | IBodyParamsPurchases)[];
}

const ModalTable: FC<TableProps> = ({ theadParams, items, bodyParams }) => {
	const history = useHistory()

	return (
		<ScrollArea speed={0.8} smoothScrolling={true} style={{ height: '400px', paddingRight: '8px' }}>
			<Thead {...theadParams} />

			{
				<div>
					{items ? (
						items?.map((item, i: number) => (
							<div
								style={{ gridTemplateColumns: theadParams.gridTemplateColumns }}
								className={cn(styles.item, styles.grid)}
								key={uniqueId('BodyRow_')}
							>
								{bodyParams(history).map(col => (
									<div className={cn(styles[col.textAlign])} key={uniqueId('RowCol_')}>
										{col.value(item, i)}
									</div>
								))}
							</div>
						))
					) : (
						<div className={styles.item}>
							<div>Нет данных</div>
						</div>
					)}
				</div>
			}
		</ScrollArea>
	)
}

export default ModalTable
