import { IInputRightTableProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { changeCounter } from '@root-gipro/modules/userProjects/store/actions'
import { removeSpaceFromNumber } from '@root-gipro/modules/userProjects/utils/removeSpaceFromNumber'
import { intFormat } from '@root-gipro/utils/helpers/common'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export const InputRightTable: React.FC<IInputRightTableProps> = ({ uncId, uncCount }) => {
	const [uncValueCount, setUncValueCount] = useState<number | string>(uncCount)
	const dispatch = useDispatch()

	useEffect(() => {
		setUncValueCount(uncCount)
	}, [uncCount])

	return (
		<input
			key={uncId}
			onChange={e => {
				setUncValueCount(intFormat(e.currentTarget.value))
				// dispatch(changeCounter(uncId, removeSpaceFromNumber(e.currentTarget.value), true))
			}}
			onBlur={e => {
				dispatch(changeCounter(uncId, removeSpaceFromNumber(e.currentTarget.value), true))
			}}
			className='unc_counter'
			placeholder={(uncValueCount as string) || 'Задать'}
			value={uncValueCount ? uncValueCount : ''}
		/>
	)
}
