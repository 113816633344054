import '@root-gipro/modules/commonProjects/styles/RangePriceFilter.scss'
import { convertFilterValue } from '@root-gipro/modules/filter/helpers/filter-state-convert'
import {
	filtersRequest,
	refreshFilterState,
	resetFilterState,
	setInitFilterState,
	showFilterResult as showFilterResultHandler,
} from '@root-gipro/modules/filter/store/actions'
import { Filter } from '@root-gipro/modules/purchases/containers'
import '@root-gipro/modules/purchases/styles/PurchasesContainer.scss'
import LoaderContainer from '@root-gipro/shared/LoaderContainer/LoaderContainer'
import { IState } from '@root-gipro/store/interfaces/state'
import { decode, encode } from '@root-gipro/utils/helpers/queryString'
import { isEqual } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { setVersionIprName } from '../AdminPanel/store/actions'
import { setUserProjects } from '../userProjects/store/actions'
import { setUserProjectsArchive } from '../userArchive/store/actions'
import { setCommonProjects } from '../commonProjects/store/actions'

const FilterContainer: FC = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { purchasesType } = useSelector((state: IState) => state.purchases)
	const uncInfoCommonProjects = useSelector((state: IState) => state.commonProjects.uncInfo)
	const publicInfo = useSelector((state: IState) => state.public)

	const companyIdUser = Number(localStorage.getItem('companyId'))
	const companyUser: any = publicInfo.company.find(item => item.id === companyIdUser)

	const [companyIds, setCompanyIds] = useState<any>()
	const url: string = location.pathname.slice(1)

	const { getInitList, showFilterResult } = useSelector((state: IState) => state.filter)
	const { filter } = useSelector((state: IState) => state)

	const currentFilter =
		url === 'common-projects'
			? filter.commonProjects
			: url === 'user-projects'
			? filter.userProjects
			: url === 'user-archive'
			? filter.userArchive
			: filter.userProjects

	const { resultFilterState, showFilterState, countResult } = useSelector((state: IState) => currentFilter)
	const company_id = resultFilterState.companyId

	let rgStatuses = useSelector((state: IState) => state.public.rgStatuses)
	rgStatuses = [
		...rgStatuses,
		{
			id: '',
			title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
		},
	]
	useEffect(() => {
		if (showFilterState && location.search !== '' && isEqual(resultFilterState, {})) {
			dispatch(setInitFilterState(decode(location.search))) // Бесполезный dispatch. На данный момент в сагах закомментированны функции
		}
	}, [showFilterResult, publicInfo])

	const getMatchedIprArrays = (publicInfo: any, companyIds?: any[], resultFilterState?: { companyId?: string }) => {		
		return (
			publicInfo.company
				.filter((company: any) =>
					companyIds ? companyIds.includes(company.name) : resultFilterState?.companyId === company.name
				) // Фильтруем объекты по совпадению имени
				// @ts-ignore
				.map(company => company?.ipr)
				.flat()
		)
	}
	const refresh = async (fieldName: string, value: string) => {
		if (fieldName === 'companyId') {
			setCompanyIds(value)
		}
		const matchedIprArrays = await getMatchedIprArrays(publicInfo, [company_id])

		if (fieldName === 'ipr_id') {
			if (!companyIds) {
				setCompanyIds(() => resultFilterState.companyId)
			}
			dispatch(
				refreshFilterState(
					fieldName,
					value,
					url,
					convertFilterValue(
						publicInfo.filial,
						publicInfo.regions,
						publicInfo.groups,
						publicInfo.company,
						publicInfo.uncThs,
						publicInfo.uncThDescriptions,
						rgStatuses,
						publicInfo.uncMains,
						matchedIprArrays
					)
				)
			)
			return
		}

		dispatch(
			refreshFilterState(
				fieldName,
				value,
				url,
				convertFilterValue(
					publicInfo.filial,
					publicInfo.regions,
					publicInfo.groups,
					publicInfo.company,
					publicInfo.uncThs,
					publicInfo.uncThDescriptions,
					rgStatuses,
					publicInfo.uncMains,
				)
			)
		)
	}

	const showResult = () => {
		history.push({
			pathname: location.pathname,
			search: encode(showFilterState),
		})

		if (Number(countResult)) {
			dispatch(showFilterResultHandler(url, true))
		} else {
			url === 'user-projects'
				? dispatch(setUserProjects([], 0, 0))
				: url === 'user-archive'
				? dispatch(setUserProjectsArchive([], 0, 0))
				: url === `common-projects`
			dispatch(setCommonProjects([]))
		}
	}

	const reset = async () => {
		const matchedIprArrays = await getMatchedIprArrays(publicInfo, [companyUser.name])

		dispatch(getInitList!())
		dispatch(resetFilterState(url))
		dispatch(filtersRequest(url))
		dispatch(setVersionIprName(''))
		if (companyUser && matchedIprArrays) {
			dispatch(
				refreshFilterState(
					'companyId',
					companyUser?.name,
					url,
					convertFilterValue(
						publicInfo.filial,
						publicInfo.regions,
						publicInfo.groups,
						publicInfo.company,
						publicInfo.uncThs,
						publicInfo.uncThDescriptions,
						rgStatuses,
						publicInfo.uncMains,
						matchedIprArrays
					)
				)
			)
		}
		history.push({
			pathname: location.pathname,
			search: '',
		})
	}

	return (
		<div className='aside-filter-container'>
			<LoaderContainer isLoading={!getInitList}>
				{getInitList && (
					<Filter
						showFilterResult={showResult}
						refreshFilterState={refresh}
						resetFilter={reset}
						//@ts-ignore
						filterCountResult={countResult}
						filterState={resultFilterState}
						purchasesType={purchasesType}
						uncInfo={uncInfoCommonProjects}
						{...publicInfo}
						rgStatuses={rgStatuses}
					/>
				)}
			</LoaderContainer>
			<div className='aside-filter-copyright'>
				{`Copyright ${new Date().getFullYear()} ©`}
				<br />
				ООО «Мастерская цифровых решений»
			</div>
		</div>
	)
}

export default FilterContainer
