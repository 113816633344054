import React, { FC } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
export interface DeleteProjectProps {
	title?: string;
	handler: () => void;
	setAnchorEl(event: HTMLElement): void;
	anchorEl: (EventTarget & HTMLElement) | null | undefined;
}
const DeleteProjectWindow: FC<DeleteProjectProps> = ({ anchorEl, handler, setAnchorEl }) => {
	const buttons = {
		PrimaryBtn: {
			handler: handler,
			value: 'Удалить',
		},

		SecondaryBtn: {
			handler: () => {
				setAnchorEl!(null!)
			},
			value: 'Отмена',
		},
	}
	const title = [
		{
			classes: 'leftTitle',
			value: 'Удалить проект',
			action: undefined,
		},
	]
	return (
		<Modal buttonOptions={buttons} setAnchorEl={setAnchorEl} anchorEl={anchorEl} title={title}>
			<div>Вы дейвительно хотите удалить данную версию расчета?</div>
		</Modal>
	)
}
export default DeleteProjectWindow
