import { IState } from '@root-gipro/store/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'

const CardCompanyName = ({ companyId }: { companyId: number | null }) => {
	const { company } = useSelector((state: IState) => state.public)

	const getCompanyName = () => {
		if (companyId !== null) {
			return company.map(el => {
				if (el.id === companyId) {
					return el.name
				}
			})
		}
	}

	return (
		<div className='card-container__company'>
			<p className='card-container__company-name'>{getCompanyName()}</p>
		</div>
	)
}

export default CardCompanyName
