import { MaketType, MaketTypes } from '../actions/maket'
import { IMaketState } from '@root-gipro/store/interfaces/state'
import produce, { Draft } from 'immer'

const initialState: IMaketState = {
	activePannel: 'projects',
	isFilterOpen: false,
}

const maket = (draft: Draft<IMaketState>, action: MaketTypes) => {
	switch (action.type) {
		case MaketType.SET_FILTER_OPEN:
			draft.isFilterOpen = action.isFilterOpen
			break

		default:
			return draft
	}
}
export const maketreducer = produce(maket, initialState)

