export enum MaketType {
	SET_FILTER_OPEN = 'IS_FILTER_OPEN',
}

export interface SetFilterOpen {
	type: MaketType.SET_FILTER_OPEN;
	isFilterOpen: boolean;
}

export const setFilterOpen = (isFilterOpen: boolean) => ({
	type: MaketType.SET_FILTER_OPEN,
	isFilterOpen,
})

export type MaketTypes = SetFilterOpen
