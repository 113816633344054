import React, { ChangeEvent, useEffect, useState } from 'react'
import { IconButton } from '@material-ui/core'
import { FilterItemInputProps } from '@root-gipro/shared/Filter/models/filter-item-input.props'
import { Input } from '@root-gipro/shared/FormElements'

const FilterItemInput: React.FC<FilterItemInputProps> = ({
	value,
	setValue,
	inputName,
	labelName,
	iconName,
	disabled,
	handleBlur,
	clearState,
}) => {
	const [inputValue, setInputValue] = useState(value || '')

	useEffect(() => {
		if (value !== inputValue) {
			setInputValue(value || '')
		}
	}, [value])

	const resetInput = () => {
		if (clearState) clearState()
		setValue(inputName, '')
	}

	const onBlur = () => {
		if (handleBlur) {
			setValue(inputName, inputValue.trim())
			handleBlur()
		}
	}

	const handleInput = (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)

	return (
		<div className='aside-filter__input'>
			<Input
				labelName={labelName}
				inputName={inputName}
				handleInput={handleInput}
				handleBlur={onBlur}
				inputValue={inputValue}
				type='text'
				disabled={disabled}
				classes='full-width'
			>
				{value && (
					<IconButton type='button' onClick={resetInput} className='reset-icon'>
						<span className='close-icon' />
					</IconButton>
				)}
				{iconName && !value && (
					<IconButton type='button' className='props-icon' disabled>
						<span className={`${iconName}-icon`} />
					</IconButton>
				)}
			</Input>
		</div>
	)
}

export default FilterItemInput
