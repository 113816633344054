import { FavoritesType, FavoritesTypes } from '@root-gipro/modules/favorites/models/interfaces/favorites.actions'
import produce, { Draft } from 'immer'
import { ProjectType } from '../models/enums/projects.enum'
import { ISortItem } from '@root-gipro/store/interfaces'

export interface IFavorites {
	favoritesList: [];
	favoritesSort: ISortItem;
	favoritesSortParams: {};
	favoritesProject: {
		valuationOfferForecastSum: number;
		valuationPlanForecastSum: number;
		projectsCount: number;
	};
	favoritesPurchases: {
		maximumPriceSum: number;
		projectsCount: number;
	};
	toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES;
	loading: boolean;
	resultsCnt: number | null;
	priceSum: number | null;
}
const initialState = {
	favoritesList: [],
	favoritesSort: {
		name: 'Наименование ДЗО',
		sortName: 'group.name',
		order: true,
	},
	//  объединить остальное в одном объекте
	favoritesProject: {
		valuationOfferForecastSum: 0,
		valuationPlanForecastSum: 0,
		projectsCount: 0,
	},
	favoritesPurchases: {
		maximumPriceSum: 0,
		projectsCount: 0,
	},
	favoritesSortParams: {},
	toggleType: ProjectType.PROJECTS,
	loading: false,
	resultsCnt: 0,
	priceSum: 0,
}

const favorites = (draft: Draft<IFavorites>, action: FavoritesTypes) => {
	switch (action.type) {
		case FavoritesType.SET_FAVORITES_PROJECTS:
			return { ...draft, resultsCnt: action.resultsCnt, priceSum: action.priceSum, favoritesList: [...action.projects] }
		case FavoritesType.GET_FAVORITES_PROJECTS_SET_PURCHASES:
			return { ...draft, toggleType: ProjectType.PROJECTS }
		case FavoritesType.EDIT_TOGGLE_TYPE_FAVORITES:
			return { ...draft, toggleType: action.toggleType }
		case FavoritesType.SET_LOADING_FAVORITES:
			return { ...draft, loading: action.loading }
		case FavoritesType.SORT_FAVORITES_PROJECTS:
			return {
				...draft,
				favoritesSort: { name: action.name, sortName: action.sortName, order: action.order },
			}
		case FavoritesType.SORT_FAVORITES_PURCHASES:
			return {
				...draft,
				favoritesSortParams: { name: action.name, sortName: action.sortName, order: action.order },
			}
		case FavoritesType.SET_FAVORITES_PROJECTS_SUM:
			return {
				...draft,
				favoritesProject: {
					...draft.favoritesProject,
					valuationOfferForecastSum: action.valuationOfferForecastSum,
					valuationPlanForecastSum: action.valuationPlanForecastSum,
				},
			}

		case FavoritesType.SET_FAVORITES_PROJECTS_COUNT:
			return {
				...draft,
				favoritesProject: {
					...draft.favoritesProject,
					projectsCount: action.projectsCount,
				},
			}
		case FavoritesType.SET_FAVORITES_PURCHSES_SUM:
			return {
				...draft,
				favoritesPurchases: {
					...draft.favoritesPurchases,
					maximumPriceSum: action.maximumPriceSum,
				},
			}

		case FavoritesType.SET_FAVORITES_PURCHSES_COUNT:
			return {
				...draft,
				favoritesPurchases: {
					...draft.favoritesPurchases,
					projectsCount: action.projectsCount,
				},
			}
		default:
			return draft
	}
}
export const favoritesReducer = produce(favorites, initialState)
