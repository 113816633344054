export const intFormatPercent = (integer: string, code: string) => {
	let int
	if (code === 'offer') {
		int = integer.toString().match(/\./)
			? integer.toString().replace(/[^\d.%]/g, '')
			: integer.toString().replace(/[^\d,.%]/g, '')
	} else {
		int = integer.toString().match(/\./)
			? integer.toString().replace(/[^\d.]/g, '')
			: integer.toString().replace(/[^\d,.]/g, '')
	}

	if ((int && int.match(/\./)) || (int && int.match(/\,/))) {
		const arrStr = int.match(/\./) ? int.split('.') : int.split(',')

		if (arrStr[0].length === 0 || (arrStr[0].length && +arrStr[0] === 0)) {
			arrStr[0] = '0'
		} else {
			arrStr[0] = arrStr[0].replace(/^0+/g, '')
		}

		const newArrStr = arrStr.slice(1)

		if (newArrStr.toString().match(/\%/)) {
			const dividePercent = newArrStr[0].split('%')
			dividePercent[1] = ''
			newArrStr[0] = dividePercent[0] + '%'
		}
		arrStr[1] = newArrStr.reduce((a, b) => a + b)
		int = arrStr[0] + '.' + arrStr[1]
		int = int.substr(0, int.indexOf('.') + 13)
	} else {
		const regWithSpaces = code === 'offer' ? /(\d)(?=(\d{3})+([^\d.]|\%$))/g : /(\d)(?=(\d{3})+([^\d.]|$))/g
		int = int && int.replace(regWithSpaces, '$1 ')
		if (int.match(/\%/)) {
			const dividePercent = int.split('%')
			int = dividePercent[0] + '%'
		}
	}
	return int
}
