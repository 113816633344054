import React, { useCallback, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import FilterHeader from '@root-gipro/shared/FilterHeader/FilterHeader'
import { CommonProjectsList } from '@root-gipro/modules/commonProjects/containers'
import UserInfoDiv from '@root-gipro/modules/commonProjects/components/UserInfoDiv'
import {
	editToggleType,
	getCommonProjects,
	sortCommonProjects,
	selectCommonProject,
	getAllFavoriteCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { setFilterOpen } from '@root-gipro/store/actions/maket'
import { IState } from '@root-gipro/store/interfaces/state'
import { commonProjectsApi } from '@root-gipro/store/api'
import { setCallbacks, setFilterSortParams, filtersRequest } from '@root-gipro/modules/filter/store/actions'
import { loadingCommonProjects, setCommonProjects } from '@root-gipro/modules/commonProjects/store/actions'
import FilterContainer from '@root-gipro/modules/filter/FilterContainer'
import sortList from '@root-gipro/modules/commonProjects/models/const/sort-list.const'
import { toggleFilter } from '@root-gipro/modules/filter/utils/toggle-filter'
import { types } from '@root-gipro/modules/commonProjects/models/const/tabs-params.const'
import { ModalContext } from '@root-gipro/context/modal-context'

const CommonProjectsContainer = () => {
	const { projects, toggleType, projectsCount, valuationPlanForecastSum, valuationOfferForecastSum } = useSelector(
		(state: IState) => state.commonProjects
	)
	const { groups } = useSelector((state: IState) => state.public)
	const { isFilterOpen } = useSelector((state: IState) => state.maket)
	const { isFavorites, IDs } = useSelector((state: IState) => state.commonProjects)
	const loadingFavorites = useSelector((state: IState) => state.favorites.loading)
	const userInfo = useSelector((state: IState) => state.commonProjects.userInfo)

	const { setAnchorEl, anchorEl } = useContext(ModalContext)
	const dispatch = useDispatch()
	const routeMatch = useRouteMatch()
	const url: string = routeMatch.path.slice(1)

	useEffect(() => {
		dispatch(selectCommonProject(null))
	}, [])

	useEffect(() => {
		const params = {
			limit: 5,
			with: [
				'resultsCnt',
				'valuationOfferForecastSum',
				'valuationPlanForecastSum',
				'projects',
				'uncInfo',
				'uncTechnicalSpecifications',
			],
		}
		dispatch(setCallbacks(getCommonProjects, commonProjectsApi, setCommonProjects, loadingCommonProjects))
		dispatch(setFilterSortParams(params, url))
		dispatch(filtersRequest(url))
	}, [url])

	useEffect(() => {
		setAnchorEl && !loadingFavorites && setAnchorEl(null)
	}, [loadingFavorites])

	const sort = (name: string, sortName: string, order: boolean): void => {
		dispatch(sortCommonProjects(name, sortName, order))
	}

	const isShowFilter = useCallback(() => {
		dispatch(setFilterOpen(!isFilterOpen))
		toggleFilter(!isFilterOpen)
	}, [isFilterOpen])

	const handleFavorites = () => {
		dispatch(getAllFavoriteCommonProjects())
	}

	return (
		<div className='projects-container'>
			<FilterContainer />

			<div data-wrapper-animate className='projects-wrapper'>
				<FilterHeader
					sort={sort}
					sortList={sortList}
					handleFavorites={handleFavorites}
					toggleType={toggleType}
					editToggleType={(toggleType: string) => dispatch(editToggleType(toggleType))}
					projectsCount={projectsCount}
					offerSum={valuationOfferForecastSum}
					planSum={valuationPlanForecastSum}
					types={types}
					isShowFilter={isShowFilter}
					isFavorite={isFavorites}
				/>

				{userInfo
					? userInfo && <UserInfoDiv data={userInfo} />
					: projects && groups && <CommonProjectsList toggleType={toggleType} projects={projects} />}
			</div>
		</div>
	)
}

export default CommonProjectsContainer
