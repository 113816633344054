import React, { MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import ScrollArea from 'react-scrollbar'

import { ClickAwayListener } from '@material-ui/core'
import { IFilial, IRegion } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'

interface RegionIds extends IRegion {
	okato: string;
}
interface ISelectProps {
	defaultVal?: any;
	list?: any;
	keyName?: string;
	placeholder?: string;
	clickHandler?: (value: any) => void;
	tooltip: string;
	multy?: boolean;
	changeHandler?: (value: any) => void;
	wrapClass?: string;
	getCostTable?(): void;
	getTitulTable?(): void;
	clearSearchVal?(): void;
	disabled?: boolean;
	subject?: boolean;
}

export const Select: React.FC<ISelectProps> = ({
	defaultVal,
	list = [],
	keyName = 'name',
	placeholder,
	clickHandler,
	tooltip,
	multy,
	changeHandler,
	wrapClass,
	clearSearchVal,
	getTitulTable,
	getCostTable,
	disabled,
	subject,
}) => {
	const [opened, setOpened] = useState(false)
	const { activePannel } = useSelector((state: IState) => state.maket)
	const clickElemHandlerMulty = (value: RegionIds | string | IFilial) => {
		const clone = defaultVal.slice()
		if (clone.indexOf(value) != -1) {
			clone.splice(clone.indexOf(value), 1)
		} else {
			clone.push(value)
		}
		if (changeHandler) changeHandler(clone)
		if (activePannel === 'projects' && getTitulTable) {
			getTitulTable()
		} else if (getCostTable) {
			getCostTable()
		}
	}

	const clickElemHandlerNoMulty = (value: RegionIds | string | IFilial) => {
		if (changeHandler) changeHandler(value)
		if (activePannel === 'projects' && getTitulTable) {
			getTitulTable()
		} else if (getCostTable) {
			getCostTable()
		}
		setOpened(false)
	}
	const handleClickAway = () => {
		setOpened(false)
	}

	const handlerClick = (elem: RegionIds | string | IFilial, event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation()
		if (clearSearchVal) clearSearchVal()
		if (multy) {
			clickElemHandlerMulty(elem)
		} else {
			clickElemHandlerNoMulty(elem)
		}
		if (clickHandler) clickHandler(elem)
	}

	const [filterText, setFilterText] = useState('')

	const handleInputChange = (event: any) => {
		setFilterText(event.target.value)
	}

	const [isActive, setIsActive] = useState(false)

	const handleBlur = () => {
		setIsActive(false)
	}

	const inputPlaceholder = isActive
		? ''
		: defaultVal || (defaultVal && defaultVal.length > 0)
		? multy
			? defaultVal.length > 1
				? `Несколько (${defaultVal.length})`
				: defaultVal.length === 1
				? defaultVal[0][keyName] || defaultVal[0]
				: placeholder
			: defaultVal[keyName] || defaultVal || placeholder
		: placeholder

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div
				onClick={
					!disabled
						? e => setOpened(true)
						: e => {
								setOpened(false)
						  }
				}
				className={`select ${multy && (defaultVal && defaultVal.length) > 0 && 'with_data'} ${wrapClass}`}
			>
				<div className='arrow'></div>
				<div className={disabled ? 'selected_value disabled' : 'selected_value'}>
					{subject ? (
						<input
							className='region_input_filter'
							type='text'
							placeholder={inputPlaceholder}
							value={filterText}
							onChange={handleInputChange}
							onBlur={handleBlur}
							onFocus={() => {
								setIsActive(true)
								setFilterText('')
							}}
						/>
					) : defaultVal || (defaultVal && defaultVal.length > 0) ? (
						multy ? (
							defaultVal.length > 1 ? (
								`Несколько (${defaultVal.length})`
							) : defaultVal.length == 1 ? (
								defaultVal[0][keyName] || defaultVal[0]
							) : (
								placeholder
							)
						) : (
							defaultVal[keyName] || defaultVal || placeholder
						)
					) : (
						placeholder
					)}
				</div>
				<div className={`select_list ${opened && 'opened_select'} ${multy && 'multy'}`}>
					<ScrollArea speed={0.8} smoothScrolling={true} style={{ maxHeight: '350px', paddingRight: '8px' }}>
						{list
							.filter((elem: any) => elem.name.toLowerCase().includes(filterText.toLowerCase()))
							.map((elem: RegionIds, ind: number) => (
								<div
									className={`select_list_elem ${multy && defaultVal.includes(elem) ? 'checked' : ''}`}
									onClick={(event: MouseEvent<HTMLDivElement>) => {
										handlerClick(elem, event)
										setFilterText('')
									}}
									key={`${elem}_${ind}`}
								>
									{elem.name || elem}
								</div>
							))}
						{list.length > 0 &&
							list.filter((elem: any) => elem.name.toLowerCase().includes(filterText.toLowerCase())).length === 0 && (
								<div className='no_results_message_filter'>Нет значений</div>
							)}
					</ScrollArea>
				</div>
			</div>
		</ClickAwayListener>
	)
}

// actions которые не обрабатывются.

// getCostTable: () => dispatch({ type: 'FETCH_COST_TABLE' }),
// 	getTitulTable: () => dispatch({ type: 'FETCH_TITUL_TABLE' }),
// 	clearSearchVal: () => {
// 		dispatch({ type: 'SET_TITUL_SEARCH_VAL', searchVal: '' })
// 		dispatch({ type: 'SET_COST_SEARCH_VAL', searchVal: '' })
// 	},
