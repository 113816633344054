export type BodyType = {
	title: string;
	tooltip: string;
	textAlign: string;
}

export const gridTemplateColumns = '1fr 2fr 6fr  2fr 2fr 3fr 2fr 3fr 4fr 4fr'
export const theadData = [
	{
		title: '№',
		tooltip: 'Номер',
		textAlign: 'textAlignRight',
		accessor: 'number',
	},
	{
		title: 'Расценка',
		tooltip: 'Расценка',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Техническое решение',
		tooltip: 'Техническое решение',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Кол-во',
		tooltip: 'Кол-во (введите диапазон через дефис)',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Ед.изм.',
		tooltip: 'Ед.измерения',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Параметры',
		tooltip: 'Параметры',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Напр., кВ',
		tooltip: 'Напр., кВ',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Укр. норматив цены',
		tooltip: 'Укр. норматив цены (введите диапазон через дефис)',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Региональный коэф.',
		tooltip: 'Региональный коэф.',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Величина затрат',
		tooltip: 'Величина затрат тыс ₽ (введите диапазон через дефис)',
		textAlign: 'textAlignLeft',
	},
]
export const gridTemplateColumnsAreaCalculation = '1fr 2fr 6fr 1fr 2fr 2fr 3fr 3fr 3fr 4fr 4fr'
export const theadCAreaCalculation = [
	{
		title: '№',
		tooltip: 'Номер',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Расценка',
		tooltip: 'Расценка',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Технические решение',
		tooltip: 'Технические решение',
		textAlign: 'textAlignLeft',
	},
	{
		title: '',
		tooltip: 'Примечание',
		textAlign: 'textAlignCenter',
	},
	{
		title: 'Кол-во',
		tooltip: 'Кол-во',
		textAlign: 'textAlignLeft',
	},

	{
		title: 'Ед.изм.',
		tooltip: 'Ед.изм.',
		textAlign: 'textAlignRight',
	},
	{
		title: 'Параметры',
		tooltip: 'Параметры',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Напр., кВ',
		tooltip: 'Напр., кВ',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Площадь, м²',
		tooltip: 'Площадь, м²',
		textAlign: 'textAlignLeft',
	},
	{
		title: 'Общая площадь, м²',
		tooltip: 'Общая площадь, м²',
		textAlign: 'textAlignLeft',
	},
]
