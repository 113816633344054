import { filterState } from '@root-gipro/modules/filter/models/consts/filter-state.const'
import { IFilterState } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { FilterActions, FilterTypes } from '@root-gipro/modules/filter/models/interfaces/filter.actions'
import produce, { Draft } from 'immer'

const filter = (draft: Draft<IFilterState>, action: FilterTypes) => {
	switch (action.type) {
		case FilterActions.SET_FILTER_STATE:
			draft = action.state // в сагах этот action закомментирован
			break

		case FilterActions.SET_CALLBACKS:
			draft.getInitList = action.getInitList
			draft.showFilterResult = action.showFilterResult
			draft.refreshList = action.refreshList
			draft.toggleLoadingList = action.toggleLoadingList
			break
		case FilterActions.SET_FILTER_SORT_PARAMS:
			const filterParam =
				action.url === 'common-projects'
					? 'commonProjects'
					: action.url === 'favorites-common-projects'
					? 'commonProjectsFavorite'
					: action.url === 'user-projects'
					? 'userProjects'
					: action.url === 'user-archive'
					? 'userArchive'
					: 'userProjects'

			draft[filterParam].sortState = action.params
			break

		case FilterActions.SET_FILTER_RESULT_COUNT:
			const filterResCnt =
				action.url === 'common-projects'
					? 'commonProjects'
					: action.url === 'favorites-common-projects'
					? 'commonProjectsFavorite'
					: action.url === 'user-projects'
					? 'userProjects'
					: action.url === 'user-archive'
					? 'userArchive'
					: 'userProjects'

			draft[filterResCnt].countResult = action.count
			break
		case FilterActions.REFRESH_FILTER_STATE:
			const convertedValue = action.convertValue(action.fieldName, action.value)
			const currentFilter =
				action.url === 'common-projects'
					? 'commonProjects'
					: action.url === 'favorites-common-projects'
					? 'commonProjectsFavorite'
					: action.url === 'user-projects'
					? 'userProjects'
					: action.url === 'user-archive'
					? 'userArchive'
					: 'userProjects'
			if (!action.value || JSON.stringify(action.value) === '[]' || JSON.stringify(action.value) === '{}') {
				delete draft[currentFilter].showFilterState[action.fieldName]
				delete draft[currentFilter].resultFilterState[action.fieldName]
				return draft
			}
			draft[currentFilter].resultFilterState[action.fieldName] = action.value
			draft[currentFilter].showFilterState[convertedValue.fieldName] = convertedValue.value
			break
		case FilterActions.RESET_FILTER_STATE:
			const current =
				action.url === 'common-projects'
					? 'commonProjects'
					: action.url === 'favorites-common-projects'
					? 'commonProjectsFavorite'
					: action.url === 'user-projects'
					? 'userProjects'
					: action.url === 'user-archive'
					? 'userArchive'
					: 'userProjects'

			draft[current].resultFilterState = {}
			draft[current].showFilterState = {}
			draft[current].countResult = 0
			draft.appliedFilters = {}
			break
		case FilterActions.SET_FILTER_COMMON_PROJECTS_RATES:
			draft.commonProjectsRates = { ...action.data }
			break

		case FilterActions.RESET_FILTER_COMMON_PROJECTS_RATES:
			draft.commonProjectsRates = {
				countStart: '',
				countEnd: '',
				name: [],
				params: [],
				voltage: [],
				table: [],
				row: [],
				column: [],
			}
			break

		case FilterActions.SHOW_BTN_COPY_PROJECTS_2_CALC:
			draft.commonProjects.showBtnСopyProjects2Calc = action.showBtnCopyProjects2Calc
			break

		case FilterActions.APPLIED_FILTERS:
			draft.appliedFilters = action.params
			break

		default:
			return draft
	}
}
export const filterReducer = produce(filter, filterState)
