import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import React, { FC } from 'react'
import styled from 'styled-components'

interface IDeleteUser {
	anchorEl: (EventTarget & HTMLElement) | null | undefined
	setAnchorEl(event: HTMLElement): void
	user?: IUser
	handleDelete(): void
}
const TitleModal = styled.h2`
    display: flex;
    justify-content: center;
    margin: 0 0 20px 0;
`
const ContainerModal = styled.div``

const ModalDeleteUser: FC<IDeleteUser> = ({ anchorEl, setAnchorEl, handleDelete, user }) => {
	const handleClose = () => {
		setAnchorEl(null!)
	}

	const buttons = {
		PrimaryBtn: {
			handler: () => {
				handleDelete()
				setAnchorEl(null!)
			},
			value: 'Удалить',
		},

		SecondaryBtn: {
			handler: handleClose,
			value: 'Отмена',
		},
	}
	return (
		<Modal setAnchorEl={setAnchorEl} anchorEl={anchorEl} buttonOptions={buttons}>
			<TitleModal>Удаление пользователя</TitleModal>
			<ContainerModal>
				Подтвердить удаление юзера: {user?.lastName} {user?.firstname}
			</ContainerModal>
		</Modal>
	)
}

export default ModalDeleteUser
