import React, { FC } from 'react'
import { ICompany } from '@root-gipro/store/interfaces'
import {
	Paper,
	TableCell,
	TableContainer,
	TableHead,
	makeStyles,
	createStyles,
	Table,
	TableBody,
	TableRow,
} from '@material-ui/core'
import AdminPanelTableCompanyRow from './AdminPanelTableCompanyRow'
import { ROLE_DEVELOPER, ROLE_OWNER } from '@root-gipro/modules/AdminPanel/store/helpers'

interface IAdminCompaniesTable {
	companies: ICompany[]
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiTableCell-root': {
				padding: '6px 12px',
			},
		},
	})
)

const AdminCompaniesTable: FC<IAdminCompaniesTable> = ({ companies }) => {
	const roleId = localStorage.getItem('roleId')
	const companyId = localStorage.getItem('companyId')
	const classes = useStyles()

	return (
		<TableContainer component={Paper}>
			<div style={{ width: '100%', overflowX: 'scroll' }}>
				<Table classes={classes} style={{ minWidth: 1800 }} size='small' stickyHeader aria-label='sticky table dense'>
					<TableHead>
						<TableRow>
							{roleId && Number(roleId) <= ROLE_OWNER ? (
								<TableCell style={{ width: 100 }} align='center'>
									Действия
								</TableCell>
							) : (
								''
							)}
							<TableCell style={{ width: 70 }}>№</TableCell>
							<TableCell>Название</TableCell>
							<TableCell>Версии ИПР</TableCell>
							<TableCell>Полное название</TableCell>
							<TableCell style={{ width: 100 }}>Точность</TableCell>
							<TableCell>Период ИПР</TableCell>
							<TableCell style={{ width: 180 }}>ИНН</TableCell>
							<TableCell style={{ minWidth: 180 }}>КПП</TableCell>
							<TableCell>ОГРН</TableCell>
							<TableCell>ОКАТО</TableCell>
							<TableCell>Регионы</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{companies.length
							? companies
									?.filter(item => {
										if (roleId && Number(roleId) > ROLE_DEVELOPER) {
											return item.id == companyId
										}
										return item
									})
									.sort((a, b) => {
										// Сортировка по id чтобы при получении данных, компании стояли на месте не прыгая в зависимости от полученных данных
										if (a.id < b.id) return -1
										if (a.id > b.id) return 1
										return 0
									})
									.map((companies, index) => (
										<AdminPanelTableCompanyRow company={companies} index={index} key={companies.id} />
									))
							: null}
					</TableBody>
				</Table>
			</div>
		</TableContainer>
	)
}

export default AdminCompaniesTable
