import React, { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UncSearch } from '@root-gipro/modules/userProjects/components/Project/UncAdd/UncSearch'
import { ToggleTabs } from '@root-gipro/modules/userProjects/components/Project/UncAdd/ToggleTabs'
import { PointElem } from '@root-gipro/modules/userProjects/components/Project/UncAdd/PointElem'
import ScrollArea from 'react-scrollbar'
import { IUncTableElem, IUncSection } from '@root-gipro/store/interfaces'
import { selectTableElem, fetchUncTableData } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import { itemsTab } from '@root-gipro/modules/userProjects/models/consts/unc-add-tabs.cons'

export const UncList: React.FC = () => {
	const [activeList, setActiveList] = useState<string>(itemsTab[0].value)
	const { uncMains, uncSections } = useSelector((state: IState) => state.public)
	const { uncFilter } = useSelector((state: IState) => state.userProjects)
	const { unc_sort } = useSelector((state: IState) => state.userProjects.projectObj)
	const dispatch = useDispatch()

	const sortedUncElems = useMemo(() => {
		const newArr = uncMains.slice()
		return newArr.sort((a: IUncTableElem, b: IUncTableElem) => {
			if (a.code > b.code) return 1
			else return -1
		})
	}, [uncMains])

	return (
		<div className='uncs_list'>
			<div className='uncs_list__head flex space-between'>
				<h1>Таблицы расценок УНЦ</h1>
				<UncSearch setActiveList={setActiveList} />
			</div>
			<div className='options'>
				<ToggleTabs items={itemsTab} clickHandler={item => setActiveList(item.value)} />
			</div>
			<ScrollArea speed={0.8} smoothScrolling={true} style={{ height: 557 }}>
				{activeList === itemsTab[0].value ? (
					<>
						{uncSections
							.filter(
								(section: IUncSection) =>
									section.name.toLowerCase().match(uncFilter) || section.num.toString().toLowerCase().match(uncFilter)
							)
							.map((section: IUncSection) => (
								<PointElem key={section.num} name={section.name} num={section.num} uncMainIds={section.uncMainIds} />
							))}
					</>
				) : (
					<>
						{uncMains.length > 0 &&
							sortedUncElems
								.filter(
									(uncElem: IUncTableElem) =>
										uncElem.name.toLowerCase().match(uncFilter) || uncElem.code.toLowerCase().match(uncFilter)
								)
								.map((uncElem: IUncTableElem) => (
									<div
										onClick={() => {
											dispatch(selectTableElem(uncElem))
											unc_sort && dispatch(fetchUncTableData(uncElem.id, unc_sort))
										}}
										key={uncElem.id}
										className='point'
									>
										<span className='table_num'>{uncElem.code}. </span>
										{uncElem.name}
									</div>
								))}
					</>
				)}
			</ScrollArea>
		</div>
	)
}
