import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { closeNotify } from '@root-gipro/modules/notify/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'

export const Notify = () => {
	const { notifications } = useSelector((state: IState) => state.notify)
	const dispatch = useDispatch()

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return
		}
		dispatch(closeNotify())
	}

	return (
		<>
			{notifications.map((notification, index) => (
				<Snackbar
					key={notification.id}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={true}
					autoHideDuration={index === 0 && notifications.length >= 3 ? 0 : 5000}
					onClose={handleClose}
					style={{ marginTop: index === notifications.length - 1 ? 0 : 60 }}
				>
					<Alert
						variant='filled'
						onClose={handleClose}
						severity={notification.type as 'success' | 'info' | 'warning' | 'error'}
					>
						{notification.message}
					</Alert>
				</Snackbar>
			))}
		</>
	)
}
