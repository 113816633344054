import React, { useContext, MouseEvent, MutableRefObject } from 'react'
import { useDispatch } from 'react-redux'
import { selectVoltage } from '@root-gipro/modules/userProjects/store/actions'
import { ModalContext } from '@root-gipro/context/modal-context'
import styles from '@root-gipro/modules/userProjects/styles/Voltage.module.scss'
import classnames from 'classnames'
import { IUnc } from '@root-gipro/store/interfaces'

export interface VoltageProps {
	unc: IUnc;
	dataAttr: string;
	voltageRef: MutableRefObject<undefined | string>;
	isArchived?: boolean;
}

const Voltage: React.FC<VoltageProps> = ({ voltageRef, dataAttr, unc, isArchived }) => {
	const { setAnchorEl, accessoryRef } = useContext(ModalContext)

	const dispatch = useDispatch()

	const handle = (e: MouseEvent<HTMLElement>) => {
		if (!isArchived) {
			dispatch(selectVoltage(unc.uncCellId, unc.id))
			setAnchorEl!(e.currentTarget)
			if (accessoryRef) accessoryRef.current = unc.id
			voltageRef.current = unc.voltage
		}
	}

	return (
		<>
			<div
				key={unc.uncCellId}
				onClick={handle}
				className={styles.wrapper}
				data-uncid={unc.id}
				data-popup={
					unc.voltage ||
					(unc.targetVoltageId === unc.id &&
						!!unc.targetVoltageValues &&
						Object.values(unc.targetVoltageValues).length > 0)
						? dataAttr
						: null
				}
			>
				<div
					key={unc.id}
					className={classnames(styles.voltage, {
						[styles.voltageSelect]:
							unc.voltage ||
							(unc.targetVoltageId === unc.id &&
								!!unc.targetVoltageValues &&
								Object.values(unc.targetVoltageValues).length > 0),
					})}
				>
					{unc.voltage}
				</div>
			</div>
		</>
	)
}

export default Voltage
