import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useDispatch, useSelector } from 'react-redux'
import { getAnalogUnc, replaceUnc } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces'
import { Box } from '@material-ui/core'
import styles from '@root-gipro/shared/Table/styles/ModalAnalogUnc.module.scss'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { IUncCell } from '@root-gipro/modules/userArchive/models/interfaces/user-projects.model'
import { prettyCost } from '@root-gipro/utils/helpers/common'

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
	},
})((props: MenuProps) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
))

const StyledMenuItem = withStyles(theme => ({
	root: {
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: theme.palette.common.white,
			},
		},
	},
}))(MenuItem)

export default function UncReplaceModal({
	anchorEl,
	uncId,
	uncCellId,
	userProjectId,
	handleClose,
}: {
	anchorEl: null | HTMLElement
	uncId: string
	uncCellId: string
	userProjectId: string | undefined
	handleClose: () => void
}) {
	const dispatch = useDispatch()

	const { uncsAnalog } = useSelector((state: IState) => state.userProjects)
	const [activeUnc, setActiveUnc] = useState<null | IUncCell>(null)

	const handleCloseModal = () => {
		handleClose()
	}
	const handleClick = async () => {
		if (activeUnc && userProjectId) {
			dispatch(replaceUnc(uncId, activeUnc.id))
		}
		handleCloseModal()
	}

	useEffect(() => {
		dispatch(getAnalogUnc(uncCellId))
	}, [])

	return (
		<div>
			<StyledMenu
				id='customized-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleCloseModal}
			>
				<Box className={styles.modalWrap}>
					<span className={styles.modalTitle}>Подобрать замену</span>
					<Box className={styles.modalList}>
						{uncsAnalog.map(el => (
							<StyledMenuItem key={el.id} onClick={() => setActiveUnc(el)}>
								<ListItemText
									primary={
										<div>
											{el.code + ' | ' + prettyCost(el.cost)} <span className='currency'> тыс&nbsp;₽</span>
										</div>
									}
								/>
							</StyledMenuItem>
						))}
					</Box>

					<Box className={styles.modalFooter}>
						<PrimaryBtn disabled={!activeUnc} onClick={handleClick}>
							Применить
						</PrimaryBtn>
						<SecondaryBtn onClick={handleCloseModal}>Отмена</SecondaryBtn>
					</Box>
				</Box>
			</StyledMenu>
		</div>
	)
}
