import React, { FC, MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'

interface CheckProjectProps {
	title?: string;
	handler: () => void;
	accessoryRef: MutableRefObject<string | undefined> | undefined;
	accessory: string | undefined;
	setAccessory?(string: string): void;
	setAnchorEl(event: HTMLElement): void;
	anchorEl: (EventTarget & HTMLElement) | null | undefined;
	isNewVersion: boolean;
}
const Instructions: FC<CheckProjectProps> = ({
	handler,
	setAnchorEl,
	accessoryRef,
	accessory,
	setAccessory,
	anchorEl,
	isNewVersion
}) => {
	const unNormalizeInstructions = useSelector((state: IState) => state.userProjects.nonStandardizedCosts)

	return (
		<div style={{ maxHeight: '650px', overflowY: 'auto' }}>
			<div className='instructions-wrap'>
				<p className='instructions-text'>
					<b>Столбцы 1-3</b> заполняются в соответствии с правилами заполнения форм инвестиционных программ субъектов
					электроэнергетики, утвержденные приказом Минэнерго России от 05.05.2016 № 380 (либо от 14.06.2016 № 533).
				</p>
				<p className='instructions-text'>
					<b>Столбец 4</b> заполняется в отношении мероприятий инвестиционного проекта, которые не нормируются УНЦ в
					соответствии с приказом Минэнерго России {!isNewVersion&&'от 17.01.2019 № 10'}, соответствуют проектной документацией и относятся к соответствующей
					группе ненормируемых затрат:
					<ul className='instructions-list'>
						{isNewVersion?unNormalizeInstructions.map(item => {
							return (
								<li key={item.id} className='instructions-item'>
									«{item.group}» – «{item.name}»
								</li>
							)
						}):(
							<>
							<li className='instructions-item'>
							«1_НЗ» – «Затраты, связанные с платой за использование земельного участка для строительства объектов
							электросетевого хозяйства (аренда, сервитут)»;
						</li>
						<li className='instructions-item'>
							«2_НЗ» – «Компенсационные затраты (компенсация ущерба), связанные с возмещением убытков, причиненных
							землепользователям, землевладельцам, арендаторам земельных участков, используемых для строительства
							объектов электросетевого хозяйства (сооружений, коммуникаций транспортной, газовой и инженерной
							инфраструктуры при пересечении последних объектами электросетевого хозяйства), а также убытки, которые они
							несут в связи с досрочным прекращением своих обязательств перед третьими лицами, в том числе упущенная
							выгода, а также расходы, связанные с временным занятием земельных участков»;
						</li>
						<li className='instructions-item'>
							«3_НЗ» – «Затраты на мероприятия по усилению конструкции дорог с тем, чтобы они обеспечивали движение
							строительной техники и перевозку максимальных по массе и габаритам строительных грузов»;
						</li>
						<li className='instructions-item'>
							«4_НЗ» – «Расходы по содержанию и восстановлению дорог после окончания строительства, за исключением
							восстановления дорожного покрытия при прокладке кабельной линии»;
						</li>
						<li className='instructions-item'>
							«5_НЗ» – «Плата за проведение компенсационного озеленения при уничтожении зеленых насаждений
							(древесно-кустарниковой и травянистой растительности естественного и искусственного происхождения,
							выполняющих средообразующие, рекреационные, санитарно-гигиенические, экологические и эстетические
							функции)»;
						</li>
						<li className='instructions-item'>
							«6_НЗ» – «Затраты на создание защитных минерализованных полос противопожарных барьеров в местах вырубки
							(расширения, расчистки) просеки линии электропередачи»;
						</li>
						<li className='instructions-item'>
							«7_НЗ» – «Затраты на приобретение (выкуп) земельного участка под строительство объектов электросетевого
							хозяйства, включая изъятие земельного участка для государственных нужд»»;
						</li>
						<li className='instructions-item'>
							«8_НЗ» – «Затраты на внеплощадочные сети ПС водоснабжения (водоотведения, теплоснабжения)»;
						</li>
						<li className='instructions-item'>
							«9_НЗ» – «Затраты на стравливание газа из перекрываемого участка газопровода»;
						</li>
						<li className='instructions-item'>«10_НЗ» – « Затраты на врезку в газопровод под давлением»;</li>
						<li className='instructions-item'>
							«11_НЗ» – « Затраты на организацию работ в котловане в сильнообводненных грунтах с использованием
							шпунтового ограждения и организации отведения грунтовых вод из котлована при переустройстве газопроводов
							(нефтепроводов)»;
						</li>
						<li className='instructions-item'>
							«12_НЗ» – « Затраты в отношении сечений коммерческого учета оптового рынка электроэнергии и мощности на
							проведение испытаний средств измерений в целях утверждения типа автоматизированной
							информационно-измерительной системы коммерческого учета электроэнергии (далее – АИИС КУЭ), оформление
							свидетельства об утверждении типа АИИС КУЭ (в том числе разработка программы испытаний, разработка проекта
							методики поверки, разработка проекта описания типа, оформление акта и протокола испытания средства
							измерений), проведение поверки АИИС КУЭ и оформление свидетельства о поверке АИИС КУЭ, разработку и
							аттестацию методики измерений АИИС КУЭ (в том числе внесение методики измерений в Федеральный
							информационный фонд по обеспечению единства измерений), установление соответствия техническим требованиям
							оптового рынка электрической энергии и мощности с целью получения паспорта соответствия АИИС КУЭ»;
						</li>
						<li className='instructions-item'>
							«13_НЗ» – « Затраты на привлечение заемного финансирования для реализации инвестиционного проекта».
						</li>
							
							</>
						)}
					</ul>
				</p>
				<p className='instructions-text'>
					<b>В столбце 5 </b>указывается наименование затрат (расчета локального сметного расчета) утвержденной
					проектной документации.
				</p>
				<p className='instructions-text'>
					<b>В столбце 6</b> в отношении одного технологического решения (мероприятия) указывается наименование одного
					объекта электроэнергетики (электрической (трансформаторной, распределительной) подстанции (далее – ПС, ТП,
					РТП), воздушной линии электропередачи (далее – ВЛ), участка ВЛ (при необходимости), кабельной линии
					электропередачи (далее – КЛ), линейно-кабельных сооружений волоконно-оптической линии связи (далее – ВОЛС),
					пункта переходного (секционирования, коммерческого учета, соединительного (далее – СП), распределительного
					(далее – РП), перехода ВЛ, плавки гололеда), административного (производственного) здания, магистрального
					газопровода, магистрального нефтепровода), наименование районных электрических сетей для организации
					коммерческого учета электрической энергии, наименование объекта соответствующей инфраструктуры, в том числе на
					стороне которого реализуются технические (технологические) решения в соответствии с реализуемыми техническими
					условиями).
				</p>
				<p className='instructions-text'>
					<b>В столбце 7</b> указывается: номер этапа строительства в соответствии с Градостроительным кодексом
					Российской Федерации в случае если проектной документацией предусмотрено выделение этапов в составе проектной
					документации; либо при отсутствии – «нд».
				</p>
				<p className='instructions-text'>
					<b>В столбце 8</b> указывается наименование собственника объектов (землепользователей, землевладельцев,
					арендаторов) (далее – организации, лица) в отношении которых производится компенсация (переустройство, плата)
					по конкретному виду затрат, указанным в столбце 5.
				</p>
				<p className='instructions-text'>
					<b>В столбце 9</b> указывается количественная характеристика (количество) в отношении конкретного вида затрат
					(расчету, локальному сметному расчету), данные о котором указаны в столбце 11.
				</p>
				<p className='instructions-text'>
					<b>В столбце 10</b> указывается измеритель количества, указанного в столбце 10 - единица измерения, сводный
					измеритель в отношении конкретного вида затрат, характеризующее мероприятие в целом (то есть шт., га, м2, м3,
					км, м, тн) или другого показателя из сметного расчета.
				</p>
				<p className='instructions-text'>
					<b>В столбце 11</b> указывается номер сметного расчета утвержденной проектной документации (реквизиты
					документа, согласно которому утверждены указанные затраты).
				</p>
				<p className='instructions-text'>
					<b>В столбце 12</b> указывается величина затрат в ценах, сложившихся ко времени составления сметной
					документации в тыс. рублей с учетом налогов и сборов, согласно данным о стоимости, указанным в целом по
					расчету (сметному расчету) с учетом начисленных по расчету прочих затрат (временные здания и сооружения,
					производство затрат в зимнее время, содержание службы заказчика, строительный контроль, непредвиденные
					затраты).
				</p>
				<p className='instructions-text'>
					<b>В столбце 13</b> указывается расчетная величина удельного показателя ненормируемых затрат как отношение
					затрат, указанных в столбце 12 на количественный показатель столбца 9. По итогам определения затрат,
					необходимых для строительства объектов электроэнергетики, выполненных в соответствии с укрупненными
					нормативами цены типовых технологических решений капитального строительства объектов электроэнергетики в части
					мероприятий, ненормируемых УНЦ, определяется объем финансовых потребностей по инвестиционному проекту как
					сумма мероприятий ненормируемых затрат УНЦ, который указывается в столбце 12 в строке «итого объем финансовых
					потребностей по инвестиционному проекту, тыс. рублей» и учитывается при определении итогового объема
					финансовых потребностей в соответствии с УНЦ.
				</p>
				<p className='instructions-text'>
					<b>В столбце 14</b> указывается краткое обоснование корректировки утвержденных показателей с описанием причин
					– в соответствии с изменениями вносимыми в наименования данных о сметном расчете, которые указываются в
					столбцах 5, 9, 11, 12; «нд» – при отсутствии изменений в мероприятия относительно утвержденной инвестиционной
					программы или при включении мероприятий нового инвестиционного проекта, указанным в столбце 5.
				</p>
			</div>
		</div>
	)
}
export default Instructions
