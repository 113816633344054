import { TextField } from '@material-ui/core'
import { formatValue } from '@root-gipro/modules/userProjects/utils/formatValue'
import { formatedDeflatorValue } from '@root-gipro/utils/helpers/common'
import React, { FC, useEffect, useState } from 'react'

interface DeflatorInputProps {
	year: number;
	precision: number | null;
	initValue: string;
	value: string | undefined;
	onChangeValue: ({ year, value }: { year: number; value: string }) => void;
}

const DeflatorInput: FC<DeflatorInputProps> = ({ year, precision, initValue, value, onChangeValue }) => {
	const [deflatorValue, setDeflatorValue] = useState<string>('')
	const precisionLocal = precision ? precision : 12

	useEffect(() => {
		setDeflatorValue(formatedDeflatorValue(initValue, precisionLocal) as string)
	}, [initValue])

	const handleChange = (value: string) => {
		// Заменяем запятые на точки
		const replaceValue = value.replace(',', '.');
		const cleanedValue = replaceValue.replace(/[^0-9.]/g, '');
		const dotCount = cleanedValue.split('.').length - 1;
	
		if (dotCount > 1) {
			// Если точек больше одной, игнорируем ввод
			return;
		}
		setDeflatorValue(cleanedValue);
	}
	const handleBlur = () => {
		const newVal = formatedDeflatorValue(deflatorValue, precisionLocal)
		onChangeValue({ year, value: newVal as string })
		setDeflatorValue(newVal as string)
	}
	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: 8, maxWidth: 500 }}>
			<label style={{ display: 'block' }}>{year}</label>

			<TextField
				id='outlined-password-input'
				type='text'
				autoComplete='current-password'
				variant='outlined'
				value={formatValue(deflatorValue, precisionLocal + 1)}
				onBlur={() => handleBlur()}
				onChange={e => handleChange(e.target.value)}
			/>
		</div>
	)
}

export default DeflatorInput
