import { IState } from '@root-gipro/store/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'

const CardRegions = ({ regionIds }: { regionIds: string[] }) => {
	const { regions } = useSelector((state: IState) => state.public)

	const getRegionName = (id: string) =>
		regions?.map(region => {
			if (region.id === id) {
				return region.name
			}
		})

	return (
		<div className='card-container__regions'>
			{regionIds.map((regionId: string) => (
				<div className='card-container__region' key={regionId}>
					{getRegionName(regionId)}
				</div>
			))}
		</div>
	)
}
export default CardRegions
