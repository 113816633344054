import { Modal } from '@root-gipro/shared/Modal/Modal'
import React, { FC } from 'react'
import InterPol from '@root-gipro/components/DeprecatedComponents/InterPol/InterPol'
import { SelectsTableData } from '@root-gipro/store/interfaces'
import InterPolL7 from '@root-gipro/components/DeprecatedComponents/InterPol/InterPolL7'
interface UncAddR1WindowProps {
	anchorEl: (EventTarget & HTMLElement) | null
	setAnchorEl(event: HTMLElement): void
	data: SelectsTableData[]
	tableId: string
	handler(): void
}
const UncAddR1Window: FC<UncAddR1WindowProps> = ({ data, anchorEl, tableId, setAnchorEl, handler }) => {
	const buttons = {
		PrimaryBtn: {
			handler: () => {
				handler()
				setAnchorEl(null!)
			},
			value: 'Готово',
		},

		SecondaryBtn: {
			handler: () => {
				setAnchorEl(null!)
			},
			value: 'Отмена',
		},
	}
	const title = [
		{
			classes: 'leftTitle',
			value: 'Укажите значения',
			action: undefined,
		},
	]
	return (
		<Modal buttonOptions={buttons} setAnchorEl={setAnchorEl} anchorEl={anchorEl} title={title}>
			{Number(tableId) === 293 ? <InterPolL7 data={data} /> : <InterPol data={data} />}
		</Modal>
	)
}

export default UncAddR1Window
