import React from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'

interface JustificationProps {
	anchorEl: (EventTarget & HTMLElement) | null;
	setAnchorEl(event: HTMLElement): void;
	offerRationale: string;
}

const Justification: React.FC<JustificationProps> = ({ anchorEl, setAnchorEl, offerRationale }) => {
	return (
		<DropDown
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			style={{ width: 360 }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
			classes='info__drop-down'
		>
			<p className='drop-down__info-txt'>{offerRationale ?? 'Нет данных'}</p>
		</DropDown>
	)
}
export default Justification
