import React, { useEffect, useRef, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

export const CardDescriptionUserProjects = ({ name }: { name: string }) => {
	const [lineClam, setLineClam] = useState(25)
	const [showBtn, setShowBtn] = useState(true)
	const [isExpanded, setIsExpanded] = useState(false)

	const ref = useRef<any>(null)

	useEffect(() => {
		if (ref.current.clientHeight >= 63) {
			setLineClam(3)
			setShowBtn(true)
		} else {
			setShowBtn(false)
		}
	}, [])

	const handleChange = () => {
		if (lineClam === 3) {
			setLineClam(25)
			setIsExpanded(true)
		} else {
			setLineClam(3)
			setIsExpanded(false)
		}
	}

	return (
		<div className='card-container__description'>
			<p
				className='card-container__description-text'
				ref={ref}
				style={{ lineClamp: lineClam, WebkitLineClamp: lineClam, overflow: lineClam === 3 ? 'hidden' : 'auto' }}
			>
				{name}
			</p>
			{showBtn ? (
				<button type='reset' className='card-container__description-btn link-blue' onClick={handleChange}>
					<span className='card-container__description-btnText'>Показать описание полностью</span>
					{!isExpanded ? (
						<ExpandMoreIcon color='primary' fontSize='small' />
					) : (
						<ExpandLessIcon color='primary' fontSize='small' />
					)}
				</button>
			) : null}
		</div>
	)
}
