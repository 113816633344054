import React, { ReactText } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { Tooltip, InputAdornment } from '@material-ui/core/'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DatePickerView } from '@material-ui/pickers'

interface ICalendarIntervalProps {
	wrapClass?: string;
	placeholder?: string;
	defaultVal?: (number | null)[] | undefined;
	changeHandler: (value: number[]) => void;
	placeholderClass?: string;
	tooltip: string;
	views?: DatePickerView[] | undefined;
	format: string;
	dateCorrectLength: number;
	activePannel?: string;
	min?: ReactText | undefined;
	max?: ReactText | undefined;
	isDisabled: boolean;
}

const CalendarInterval: React.FC<ICalendarIntervalProps> = ({
	wrapClass,
	placeholder,
	defaultVal,
	changeHandler,
	placeholderClass,
	tooltip,
	views,
	format,
	dateCorrectLength,
	min,
	max,
	isDisabled,
}) => {
	const onChangeHandler = (value: MaterialUiPickersDate, index: number) => {
		if (value && defaultVal) {
			if (index === 0) changeHandler([value.getFullYear(), defaultVal[1] as number])
			if (index === 1) changeHandler([defaultVal[0] as number, value.getFullYear()])
		}
	}

	return (
		<div className={`check-plate__calendar flex ${wrapClass}`}>
			{defaultVal![0] || defaultVal![1] ? (
				<>
					<DatePicker
						className='calendar-start'
						variant='inline'
						views={views}
						placeholder={'с'}
						format={format}
						invalidDateMessage=''
						minDate={'2008'}
						maxDate={max!.toString()}
						invalidLabel=''
						maxDateMessage=''
						minDateMessage=''
						disabled={isDisabled}
						value={defaultVal![0] ? defaultVal![0].toString().split('.').reverse().join('-') : null}
						onChange={data => onChangeHandler(data, 0)}
						InputProps={{
							startAdornment: <InputAdornment position='start'>c</InputAdornment>,
						}}
					/>

					<DatePicker
						className='calendar-end'
						variant='inline'
						views={views}
						placeholder={'по'}
						format={format}
						invalidDateMessage=''
						invalidLabel=''
						maxDateMessage=''
						minDateMessage=''
						disabled={isDisabled}
						minDate={min!.toString()}
						maxDate={'2050'}
						value={defaultVal![1] ? defaultVal![1].toString().split('.').reverse().join('-') : null}
						onChange={data => onChangeHandler(data, 1)}
						InputProps={{
							startAdornment: <InputAdornment position='start'>по</InputAdornment>,
						}}
					/>
				</>
			) : (
				<Tooltip title={tooltip} enterDelay={1000} enterNextDelay={2000} placement='top'>
					<div className={`placeholder ${placeholderClass}`}>{placeholder}</div>
				</Tooltip>
			)}
		</div>
	)
}

// actions которые не обрабатывются.

// if (activePannel === 'projects' && getTitulTable) {
// 	getTitulTable()
// } else if (getCostTable) {
// 	getCostTable()
// }
// getCostTable: () => dispatch({ type: 'FETCH_COST_TABLE' }),
// 	getTitulTable: () => dispatch({ type: 'FETCH_TITUL_TABLE' }),
// 	clearSearchVal: () => {
// 		dispatch({ type: 'SET_TITUL_SEARCH_VAL', searchVal: '' })
// 		dispatch({ type: 'SET_COST_SEARCH_VAL', searchVal: '' })
// 	}

export { CalendarInterval }
