import React, { useEffect, useState } from 'react'
import { Checkbox, InputAdornment, ListItemText } from '@material-ui/core'
import { isEqual } from 'lodash'
import { DatePicker } from '@material-ui/pickers'
import { FilterItemDateRangeProps } from '@root-gipro/shared/Filter/models/filter-item-date-range.props'
import '@root-gipro/modules/commonProjects/styles/DurationDate.scss'

const FilterItemDateRange: React.FC<FilterItemDateRangeProps> = ({
	name,
	startName = 'с',
	endName = 'по',
	withCheckbox,
	setEnd,
	setStart,
	end,
	start,
	disabledAll,
	format = 'MM.yyyy',
	openTo = 'date',
	views = ['year', 'date', 'month'],
}) => {
	const [startDate, setStartDate] = useState(start || null)
	const [endDate, setEndDate] = useState(end || null)

	useEffect(() => {
		if (!isEqual(start, startDate) && start! > end!) {
			setStart(end || null)
		}
		if (!isEqual(end, endDate) && end! < start!) {
			setEnd(start || null)
		}
		if (!isEqual(start, startDate)) {
			setStartDate(start || null)
		}
		if (!isEqual(end, endDate)) {
			setEndDate(end || null)
		}
	}, [start, end])

	const resetFields = () => {
		setStart(null)
		setEnd(null)
	}

	return (
		<div className='duration-date'>
			<div className='duration-date__head'>
				<h5 className='duration-date__head-title'>{name}</h5>
				<span className='link link-grey' onClick={resetFields}>
					Сбросить
				</span>
			</div>
			<div className='duration-date__pickers'>
				<DatePicker
					variant='inline'
					inputVariant='outlined'
					disabled={disabledAll || withCheckbox?.checked}
					value={startDate}
					onChange={date => setStart(date || null)}
					onBlur={() => setStart(startDate || null)}
					format={format}
					openTo={openTo}
					views={views}
					InputProps={{
						startAdornment: <InputAdornment position='start'>{startName}</InputAdornment>,
					}}
					className='duration-date__pickers-item'
				/>
				<DatePicker
					variant='inline'
					inputVariant='outlined'
					disabled={disabledAll || withCheckbox?.checked}
					value={endDate}
					onChange={date => setEnd(date)}
					onBlur={() => setEnd(endDate)}
					format={format}
					openTo={openTo}
					views={views}
					maxDate={new Date('2100-06-01')}
					InputProps={{
						startAdornment: <InputAdornment position='start'>{endName}</InputAdornment>,
					}}
					className='duration-date__pickers-item'
				/>
			</div>
			{withCheckbox && (
				<div className='aside-filter__checkbox'>
					<Checkbox
						disabled={withCheckbox.disabled}
						checked={withCheckbox.checked}
						onChange={() => {
							if (!withCheckbox) {
								return
							}
							if (withCheckbox.checkboxChange) withCheckbox.checkboxChange()
							withCheckbox.setChecked(!withCheckbox.checked)
							// if (!withCheckbox.checked) resetFields() // приводит к сбрасыванию дынных
						}}
					/>
					<ListItemText primary={withCheckbox.label} />
				</div>
			)}
		</div>
	)
}

export default FilterItemDateRange
