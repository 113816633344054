import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Icon,
	makeStyles,
	Select,
} from '@material-ui/core'
import { IState } from '@root-gipro/store/interfaces'
import { IconButton, MenuItem } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

const useStylesFormControl = makeStyles({
	root: {
		'& .MuiSelect-icon': {
			padding: 0,
			top: 'calc(50% - 12px)',
			color: 'rgba(0, 0, 0, 0.54)',
			right: 4,
			position: 'absolute',
			pointerEvents: 'none',
		},
	},
})

interface IAdminPanelCreateUserCompany {
	updateUserIpr: (iprIds: number[]) => void
	defaultIpr: number[]
}

const AdminPanelSelectIpr: FC<IAdminPanelCreateUserCompany> = ({ updateUserIpr, defaultIpr }) => {
	const [value, setValue] = useState<number[]>(Array.isArray(defaultIpr) ? defaultIpr : defaultIpr ? [defaultIpr] : [])
	const [open, setOpen] = useState(false)

	let { company } = useSelector((state: IState) => state.public)

	company = [...company].sort((a, b) => {
		if (a.name < b.name) return -1
		if (a.name > b.name) return 1
		return 0
	})

	const handleChange = (iprId: number) => {
		if (value.includes(iprId)) {
			setValue(value.filter(i => i !== iprId))
		} else {
			setValue(prev => [...prev, iprId])
		}
	}

	const handleClickOpen = () => {
		setOpen(true)
	}
	const handleSubmit = () => {
		updateUserIpr(value)
	}

	const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
		if (reason !== 'backdropClick') {
			setOpen(false)
		}
	}

	return (
		<div>
			<IconButton type='button' onClick={handleClickOpen}>
				<LinkIcon />
			</IconButton>
			<Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
				<DialogTitle>Выберите компанию</DialogTitle>
				<DialogContent>
					<Box component='form'>
						{company.map((company, index) => (
							<MenuItem
								style={{ background: value?.includes(Number(company.id)) ? '#c3c3c3' : 'white' }}
								value={company.id}
								key={company.id}
								onClick={() => {
									handleChange(Number(company.id))
								}}
							>
								<span>{`${index + 1}.`}&nbsp; </span>
								{`${company.name} ${company.inn ? `, ИНН: ${company.inn}` : ''}`}
							</MenuItem>
						))}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Назад</Button>
					<Button onClick={handleSubmit}>Принять</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default AdminPanelSelectIpr
