import { adminPanelReducer } from '@root-gipro/modules/AdminPanel/store/reducer'
import { commonProjectsReducer } from '@root-gipro/modules/commonProjects/store/reducer'
import { favoritesReducer } from '@root-gipro/modules/favorites/store/reducer'
import { filterReducer } from '@root-gipro/modules/filter/store/reducer'
import { notifyReducer } from '@root-gipro/modules/notify/store/reducer'
import { purchaseReducer } from '@root-gipro/modules/purchases/store/reducer'
import { userArchivesReducer } from '@root-gipro/modules/userArchive/store/reducer'
import { userProjectsReducer } from '@root-gipro/modules/userProjects/store/reducer'
import { IState } from '@root-gipro/store/interfaces/state'
import { combineReducers } from 'redux'
import { authReducer } from './authReducer'
import { maketreducer } from './maketreducer'
import { publicReducer } from './publicReducer'

const rootReducer = combineReducers<IState>({
	auth: authReducer,
	public: publicReducer,
	userProjects: userProjectsReducer,
	userArchives: userArchivesReducer,
	commonProjects: commonProjectsReducer,
	maket: maketreducer,
	purchases: purchaseReducer,
	filter: filterReducer,
	favorites: favoritesReducer,
	notify: notifyReducer,
	adminPanel: adminPanelReducer,
})

export default rootReducer
