export const theadUnNormalizedForm20 = [
	{ name: '№ п/п', width: 40 },
	{ name: 'Группа ненормируемых затрат', width: 100 },
	{ name: 'Наименование', width: 200 },
	{ name: '	Наименование одного объекта, где реализуется технологическое решение (мероприятие)', width: 100 },
	{ name: 'Номер очереди (этапа) строительства (реализации проекта)', width: 75 },
	{ name: 'Наименование организации (лица) в отношении которого производится компенсация, переустройство', width: 270 },
	{ name: 'Количество', width: 'auto' },
	{ name: 'Измеритель (единица измерения)', width: 'auto' },
	{ name: 'Номер сметного расчета', width: 'auto' },
	{
		name:
			'Величина затрат в ценах, сложившихся ко времени составления сметной документации, тыс рублей (с учетом налогов и сборов)',
		width: 100,
	},
	{ name: 'Удельный показатель ненормируемых затрат, тыс рублей (ст.13=ст.12/ст.9)', width: 75 },
	{ name: 'Краткое обоснование корректировки утвержденного план', width: 75 },
]

export const underThead = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15]

export const tbodyGroupItems = [
	{
		value: '1_НЗ',
		title:
			'Затраты, связанные с платой за использование земельного участка для строительства объектов электросетевого хозяйства (аренда, сервитут)»;',
	},
	{
		value: '2_НЗ',
		title:
			'Компенсационные затраты (компенсация ущерба), связанные с возмещением убытков, причиненных землепользователям, землевладельцам, арендаторам земельных участков, используемых для строительства объектов электросетевого хозяйства (сооружений, коммуникаций транспортной, газовой и инженерной инфраструктуры при пересечении последних объектами электросетевого хозяйства), а также убытки, которые они несут в связи с досрочным прекращением своих обязательств перед третьими лицами, в том числе упущенная выгода, а также расходы, связанные с временным занятием земельных участков»;',
	},
	{
		value: '3_НЗ',
		title:
			'Затраты на мероприятия по усилению конструкции дорог с тем, чтобы они обеспечивали движение строительной техники и перевозку максимальных по массе и габаритам строительных грузов»;',
	},
	{
		value: '4_НЗ',
		title:
			'Расходы по содержанию и восстановлению дорог после окончания строительства, за исключением восстановления дорожного покрытия при прокладке кабельной линии»; ',
	},
	{
		value: '5_НЗ',
		title:
			'Плата за проведение компенсационного озеленения при уничтожении зеленых насаждений (древесно-кустарниковой и травянистой растительности естественного и искусственного происхождения, выполняющих средообразующие, рекреационные, санитарно-гигиенические, экологические и эстетические функции)»; ',
	},
	{
		value: '6_НЗ',
		title:
			'Затраты на создание защитных минерализованных полос противопожарных барьеров в местах вырубки (расширения, расчистки) просеки линии электропередачи»; ',
	},
	{
		value: '7_НЗ',
		title:
			'Затраты на приобретение (выкуп) земельного участка под строительство объектов электросетевого хозяйства, включая изъятие земельного участка для государственных нужд»»; ',
	},
	{ value: '8_НЗ', title: 'Затраты на внеплощадочные сети ПС водоснабжения (водоотведения, теплоснабжения)»; ' },
	{ value: '9_НЗ', title: 'Затраты на стравливание газа из перекрываемого участка газопровода»; ' },
	{ value: '10_НЗ', title: ' Затраты на врезку в газопровод под давлением»;' },
	{
		value: '11_НЗ',
		title:
			' Затраты на организацию работ в котловане в сильнообводненных грунтах с использованием шпунтового ограждения и организации отведения грунтовых вод из котлована при переустройстве газопроводов (нефтепроводов)»; ',
	},
	{
		value: '12_НЗ',
		title:
			' Затраты в отношении сечений коммерческого учета оптового рынка электроэнергии и мощности на проведение испытаний средств измерений в целях утверждения типа автоматизированной информационно-измерительной системы коммерческого учета электроэнергии (далее – АИИС КУЭ), оформление свидетельства об утверждении типа АИИС КУЭ (в том числе разработка программы испытаний, разработка проекта методики поверки, разработка проекта описания типа, оформление акта и протокола испытания средства измерений), проведение поверки АИИС КУЭ и оформление свидетельства о поверке АИИС КУЭ, разработку и аттестацию методики измерений АИИС КУЭ (в том числе внесение методики измерений в Федеральный информационный фонд по обеспечению единства измерений), установление соответствия техническим требованиям оптового рынка электрической энергии и мощности с целью получения паспорта соответствия АИИС КУЭ»; ',
	},
	{
		value: '13_НЗ',
		title: ' Затраты на привлечение заемного финансирования для реализации инвестиционного проекта». ',
	},
]

export const tbodyUnitItems = [
	{ value: 'шт' },
	{ value: 'га' },
	{ value: 'м2' },
	{ value: 'м3' },
	{ value: 'км' },
	{ value: 'м' },
	{ value: 'тн' },
]

export const tbodyObjectNameItems = [
	{ value: 'ПС' },
	{ value: 'ТП' },
	{ value: 'РТП' },
	{ value: 'ВЛ' },
	{ value: 'КЛ' },
	{ value: 'ВОЛС' },
	{ value: 'СП' },
	{ value: 'РП' },
	{ value: 'РП' },
]
