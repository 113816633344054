import { Tooltip } from '@material-ui/core'
import React from 'react'

interface ILabelInputProps {
	tooltip: string | NonNullable<React.ReactNode>;
	title: string;
}

const LabelInput: React.FC<ILabelInputProps> = ({ tooltip, title }) => {
	return (
		<Tooltip title={tooltip} enterDelay={500} enterNextDelay={500} placement='top-start'>
			<div className='cell-label' style={{ cursor: 'default' }}>
				{title}
			</div>
		</Tooltip>
	)
}
export default LabelInput
