import { FormControl, IconButton, makeStyles, MenuItem, Select, TableCell, TableRow, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { roles } from '@root-gipro/modules/admin/models/roles'
import { IUser, TUserAccess } from '@root-gipro/modules/AdminPanel/interfaces/user'
import {
	checkUserInfo,
	deleteUserFetch,
	setUserRoleFetch,
	updateUserIpr,
} from '@root-gipro/modules/AdminPanel/store/actions'
import { ICompany, IFilial, IState } from '@root-gipro/store/interfaces'
import React, { FC, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdminPanelUpadteUser from './AdminUpdateUser/AdminPanelUpadteUser'
import { ModalContext } from '@root-gipro/context/modal-context'
import AdminPanelSelectIpr from '@root-gipro/modules/AdminPanel/components/AdminPanel/components/AdminPanelSelectIpr'

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'left',
		justifyContent: 'left',
		border: '1px solid #E0E0E0',
		height: '32 !important',
		borderRadius: '4px !important',
		color: 'white',
		padding: '6px 16px 8px  !important',
	},
})

const useStylesFormControl = makeStyles({
	root: {
		'& .MuiSelect-icon': {
			padding: 0,
			top: 'calc(50% - 12px)',
			color: 'rgba(0, 0, 0, 0.54)',
			right: 4,
			position: 'absolute',
			pointerEvents: 'none',
		},
	},
})

interface IAdmiinPanelTableUserRow {
	user: IUser
	index: number
	setUserInfo(mode: IUser): void
}

const getFilialName = (filialId: string | number, filials: IFilial[]) => {
	const filialName = filials.filter(el => el.id == filialId)
	if (filialName.length >= 1) {
		return filialName[0].name
	} else {
		return '—'
	}
}
const getCompanyName = (companyId: string | number, company: ICompany[]): string => {
	const companyName = company.filter(el => el.id == companyId)
	if (companyName.length >= 1) {
		return companyName[0].name
	} else {
		return '—'
	}
}
const formatedDate = (timestamp: number) => {
	const date = new Date(timestamp * 1000)

	const yyyy = date.getFullYear()
	let mm: number | string = date.getMonth() + 1
	let dd: number | string = date.getDate()

	if (dd < 10) dd = '0' + dd
	if (mm < 10) mm = '0' + mm

	return dd + '.' + mm + '.' + yyyy
}

const getDataAccess = (access: TUserAccess) => {
	const start = formatedDate(access.date_start)
	const end = formatedDate(access.date_end)

	return `${start} — ${end}`
}

interface IRole {
	id: number
	title: string
}

const AdminPanelTableUserRow: FC<IAdmiinPanelTableUserRow> = ({ user, index, setUserInfo }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const classesFromControl = useStylesFormControl()
	const { setAnchorEl } = useContext(ModalContext)

	const [open, setOpen] = React.useState(false)

	const { company, filial } = useSelector((state: IState) => state.public)

	const handleClose = () => {
		setOpen(false)
	}

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		dispatch(setUserRoleFetch(user.id, event.target.value as number))
	}

	const updateUserData = () => {
		dispatch(checkUserInfo(user.id))
		setOpen(true)
	}

	const updateUserInfoIpr = (iprIds: number[]) => {
		const companyIpr = Array.isArray(user.company_ipr) ? [...user.company_ipr, ...iprIds] : iprIds
		dispatch(updateUserIpr(user.id, user, companyIpr))
	}

	const authUserRole = Number(localStorage.getItem('roleId'))
	const availableRoles: IRole[] = roles.filter(role => role.id > authUserRole)

	const licenseAccess = (access: any, dateEnd: any) => {
		if (access && dateEnd) {
			const timestampInMilliseconds = dateEnd * 1000

			const inputDate = new Date(timestampInMilliseconds)
			const currentDate = new Date()

			inputDate.setHours(0, 0, 0, 0)
			currentDate.setHours(0, 0, 0, 0)
			return inputDate < currentDate
		} else if (!access) {
			return true
		}
		return false
	}

	return (
		<>
			<TableRow className={`adminPanel__tableRow`}>
				<TableCell className='adminPanelCell ' align='center'>
					<IconButton
						size='small'
						type='button'
						data-popup='delete_user'
						onClick={e => {
							setAnchorEl!(e.currentTarget)
							setUserInfo(user)
						}}
						aria-label='Удалить'
					>
						<DeleteIcon fontSize='small' />
					</IconButton>
					<IconButton size='small' type='button' onClick={updateUserData} aria-label='Изменить данные'>
						<EditIcon fontSize='small' />
					</IconButton>
				</TableCell>
				<TableCell className='adminPanelCell'>{user.id}</TableCell>
				<TableCell className='adminPanelCell'>{user.lastname + ' ' + user.firstname}</TableCell>
				<TableCell
					className={`adminPanelCell${licenseAccess(user?.access, user?.access?.date_end) ? '__disabled' : ''}`}
				>
					{user.access ? getDataAccess(user.access) : '—'}
				</TableCell>
				<TableCell className='adminPanelCell'>
					{authUserRole < user.roleId ? (
						<FormControl fullWidth classes={classesFromControl}>
							<Select value={user.roleId} onChange={handleChange} classes={classes} autoWidth>
								{availableRoles &&
									availableRoles.map(role => (
										<MenuItem value={role.id} key={role.id}>
											{role.title}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					) : (
						roles.filter(role => role.id === user.roleId)[0].title
					)}
				</TableCell>
				<Tooltip title={getCompanyName(user.companyId, company)}>
					<TableCell className='adminPanelCell' align={'left'}>
						<span>{getCompanyName(user.companyId, company)}</span>
					</TableCell>
				</Tooltip>
				<TableCell className='adminPanelCell' align={'center'}>
					<AdminPanelSelectIpr updateUserIpr={updateUserInfoIpr} defaultIpr={JSON.parse(user.company_ipr)} />
				</TableCell>
				<Tooltip title={getFilialName(user.filialId, filial)}>
					<TableCell className='adminPanelCell' align={'left'}>
						<span>{getFilialName(user.filialId, filial)}</span>
					</TableCell>
				</Tooltip>
				<Tooltip title={user.email}>
					<TableCell className='adminPanelCell'>{user.email}</TableCell>
				</Tooltip>
				<TableCell className='adminPanelCell' align='left'>
					{user?.data?.phone ? user.data.phone : '—'}
				</TableCell>
			</TableRow>
			<AdminPanelUpadteUser open={open} handleClose={handleClose} user={user} />
		</>
	)
}

export default AdminPanelTableUserRow
