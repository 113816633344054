import { Checkbox } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { TUserAccess } from '@root-gipro/modules/AdminPanel/interfaces/user'
import React, { FC, useEffect, useState } from 'react'

interface IAdminPanelUpdateAccess {
	userAccess: TUserAccess | undefined;
	getValue: (name: string, value: boolean | number) => void;
}

const AdminPanelUpadteUserAccess: FC<IAdminPanelUpdateAccess> = ({ userAccess, getValue }) => {
	const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
		userAccess ? new Date(userAccess.date_start * 1000) : new Date()
	)
	const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
		userAccess ? new Date(userAccess.date_end * 1000) : new Date()
	)
	const [access, setAccess] = useState<boolean>(Boolean(userAccess ? userAccess.access : false))
	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date)
		getValue('date_start', date!.getTime() / 1000)
	}

	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date)
		getValue('date_end', date!.getTime() / 1000)
	}

	const handleChangeAccess = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAccess(e.target.checked)
		getValue('access', e.target.checked)
	}

	useEffect(() => {
		if (userAccess) {
			setSelectedStartDate(new Date(userAccess!.date_start * 1000))
			setSelectedEndDate(new Date(userAccess!.date_end * 1000))
		}
	}, [userAccess])

	return (
		<div>
			<label className='adminUpdate__accessCheckbox'>
				<Checkbox name='access' checked={access} onChange={handleChangeAccess} color='primary' />
				<span>Доступ</span>
			</label>
			<div className='adminUpdate__datepicker'>
				<KeyboardDatePicker
					disableToolbar
					variant='inline'
					name='date_start'
					format='dd.MM.yyyy'
					margin='normal'
					id='date_start'
					label='Доступно с'
					value={selectedStartDate}
					onChange={handleStartDateChange}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
				/>{' '}
				<KeyboardDatePicker
					disableToolbar
					variant='inline'
					format='dd.MM.yyyy'
					margin='normal'
					name='date_end'
					id='date_start'
					label='по'
					value={selectedEndDate}
					onChange={handleEndDateChange}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
				/>
			</div>
		</div>
	)
}

export default AdminPanelUpadteUserAccess
