import adminPanel from '@root-gipro/modules/AdminPanel/store/saga'
import watchFilter from '@root-gipro/modules/filter/store/saga'
import { all } from 'redux-saga/effects'
import commonProjectsSaga from '../../modules/commonProjects/store/saga'
import userArchivesSaga from '../../modules/userArchive/store/saga'
import userProjectsSaga from '../../modules/userProjects/store/saga'
import authSaga from './auth'
import publicSaga from './public'

export default function* rootSaga() {
	yield all([
		authSaga(),
		publicSaga(),
		userProjectsSaga(),
		userArchivesSaga(),
		commonProjectsSaga(),
		watchFilter(),
		adminPanel(),
	])
}
