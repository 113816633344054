import cn from 'classnames';
import React, { FC } from 'react';

import styles from '@root-gipro/shared/Table/styles/Table.module.scss';

import TheadCell from './TheadCell';

export type Item = {
	title: string;
	textAlign: string;
}

export interface TheadProps {
	theadData: Item[];
	gridTemplateColumns: string;
	updateHandleSorting?: (sortField: string, setOrder: string) => void;
	updateHandleChange?: (inputFiled: string, value: string) => void;
}

const Thead: FC<TheadProps> = ({ gridTemplateColumns, theadData, updateHandleSorting, updateHandleChange }) => {
	const handleSorting = (sortField: string, sortOrder: string) => {
		if (updateHandleSorting) {
			updateHandleSorting(sortField, sortOrder)
		}
	}
	const handleChange = (inputField: string, value: string) => {
		if (updateHandleChange) {
			updateHandleChange(inputField, value)
		}
	}

	return (
		<div style={{ gridTemplateColumns: gridTemplateColumns, gap: '8px' }} className={cn(styles.thead, styles.grid)}>
			{theadData.map(item => (
				<TheadCell item={item} key={item.title} handleSorting={handleSorting} handleChange={handleChange} />
			))}
		</div>
	)
}

export { Thead }
