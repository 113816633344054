import { ErrorsList } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/ErrorsList'
import { setSelectedSectionIds } from '@root-gipro/modules/userProjects/store/actions'
import { filterWidthWindow } from '@root-gipro/modules/userProjects/utils/helpers'
import Loader from '@root-gipro/shared/Loader/Loader'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { ISectionIds } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { FC, MutableRefObject, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
interface CheckProjectProps {
	title?: string;
	handler: () => void;
	handlerAutoAddUnc: () => void;
	sectionIds: ISectionIds[];
	sectionIdsArr: number[];
	accessoryRef: MutableRefObject<string | undefined> | undefined;
	accessory: string | undefined;
	setAccessory?(string: string): void;
	setAnchorEl(event: HTMLElement): void;
	anchorEl: (EventTarget & HTMLElement) | null | undefined;
}
const CheckProjectWindow: FC<CheckProjectProps> = ({
	handler,
	handlerAutoAddUnc,
	setAnchorEl,
	sectionIds,
	sectionIdsArr,
	accessoryRef,
	accessory,
	setAccessory,
	anchorEl,
}) => {
	const dispatch = useDispatch()
	const { projectErrors, selectedSectionIds } = useSelector((state: IState) => state.userProjects)
	const [isExpand, setIsExpand] = useState(false)
	const [indexPanel, setIndex] = useState<number | undefined>()
	const [loader, setLoader] = useState(false)

	const handleClick = useCallback(
		(panel: number) => {
			setIndex(panel)
			setIsExpand(panel === indexPanel ? !isExpand : true)
		},
		[indexPanel, isExpand]
	)

	const filterButtonOptions = (step: string) => {
		switch (step) {
			case 'check-list':
				return {
					PrimaryBtn: {
						handler: handler,
						value: 'Проверить',
					},

					SecondaryBtn: {
						handler: () => {
							setAnchorEl!(null!)
						},
						value: 'Отмена',
					},
				}
			case 'error-list':
				return {
					PrimaryBtn: {
						handler: () => {
							handlerAutoAddUnc()
							setAnchorEl!(null!)
						},
						disabled: !selectedSectionIds.includes(7),
						value: 'Применить авторасстановку',
					},
					SecondaryBtn: {
						handler: () => accessoryRef?.current && setAccessory!(accessoryRef.current),
						value: 'Отмена',
					},
				}

			default:
				break
		}
	}
	const filterTitle = (step: string | undefined) => {
		let title
		switch (step) {
			case 'check-list':
				title = [
					{
						classes: 'leftTitle',
						value: 'Проверка',
						action: undefined,
					},
				]
				return title
			case 'error-list':
				title = undefined
				return title

			default:
				title = undefined
				return title
		}
	}
	useEffect(() => {
		if (accessory === 'error-list') {
			setTimeout(() => {
				setLoader(true)
			}, 500)
		}
	}, [accessory])

	return (
		<Modal
			keyBtn={accessory}
			buttonOptions={accessory ? filterButtonOptions(accessory) : null}
			setAnchorEl={setAnchorEl}
			anchorEl={anchorEl}
			title={filterTitle(accessory)}
			widthWindow={filterWidthWindow(accessory)}
		>
			{accessory === 'check-list' && (
				<>
					<div className='check-plate__shrt-txt mb-8'>Выберите категории</div>
					{sectionIds.map((elem: ISectionIds) => {
						return (
							<div key={elem.id} className='check-plate__checkbox'>
								{elem.id === '8' && <div className='check-plate__shrt-txt mb-8'>Дополнительно</div>}
								<input
									id={elem.id}
									type='checkbox'
									defaultChecked={selectedSectionIds.includes(Number(elem.id))}
									onChange={e => {
										const checked = e.target.checked

										if (!checked) {
											dispatch(setSelectedSectionIds(selectedSectionIds.filter((i: number) => i !== Number(elem.id))))
										} else {
											dispatch(setSelectedSectionIds([...selectedSectionIds, Number(elem.id)]))
										}
									}}
								/>
								<label htmlFor={elem.id}>{elem.name}</label>
							</div>
						)
					})}
				</>
			)}
			{accessory === 'error-list' && !loader && <Loader />}
			{accessory === 'error-list' && loader && (
				<ErrorsList
					handleClick={handleClick}
					projectErrors={projectErrors}
					indexPanel={indexPanel}
					sectionIds={sectionIds}
					isExpand={isExpand}
				/>
			)}
		</Modal>
	)
}
export default CheckProjectWindow
