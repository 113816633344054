import { setVersionIprName } from '@root-gipro/modules/AdminPanel/store/actions'
import { FilterProps } from '@root-gipro/modules/filter/models/props/filter.model'
import { FilterItemGroup, Filter as FilterWrapper } from '@root-gipro/shared/Filter'
import FilterItem from '@root-gipro/shared/Filter/FilterItem/FilterItem'
import { ICompany, IFilial, IGroup, IRegion, IState } from '@root-gipro/store/interfaces'
import { formatDate } from '@root-gipro/utils/helpers/common'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const Filter: React.FC<FilterProps> = ({
	resetFilter,
	refreshFilterState,
	showFilterResult,
	filterCountResult,
	filterState,
	purchasesType,
	uncInfo,
	purchasesMethods,
	purchasesStages,
	purchasesOkpd,
	purchasesOkved,
	filial,
	regions,
	groups,
	company,
	rgStatuses,
	publicationDates,
	purchasePublishDates,
	uncThs,
	uncThDescriptions,
	uncMains,
	policesRGCO,
	uncTechnicalSpecifications,
}) => {
	const [currentFilter, setCurrentFilter] = useState<boolean>(false)
	const [filials, setFilials] = useState<IFilial[]>([])
	const [companyID, setCompanyId] = useState<any>()
	const [companyIPRS, setCompanyIPRS] = useState<any>([])
	const [companyFullIpr, setCompanyFullIpr] = useState<any>([])

	//@ts-ignore
	const company_id = filterState.companyId

	const dispatch = useDispatch()
	const [selectVersionIpr, setSelectVersionIpr] = useState<any>()

	const { versionIpr } = useSelector((state: IState) => state.adminPanel)

	const location = useLocation()
	const url: string = location.pathname.slice(1)

	useEffect(() => {
		setCurrentFilter(false)
	}, [url])
	const companyIdUser = Number(localStorage.getItem('companyId'))
	const companyUser = company.find(item => item.id === companyIdUser)

	const refreshFilterValue = (fieldName: string, value: string[] | string | undefined | boolean) => {
		if ((value !== undefined || value !== '') && !isEqual(value, (filterState as Record<string, string>)[fieldName])) {
			if (fieldName === 'uncTechnicalSpecification.name') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
			}
			if (fieldName === 'uncTechnicalSpecification.paramName') {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
			}
			if (filterState['uncTechnicalSpecification.name'] && filterState['uncTechnicalSpecification.name']?.length > 1) {
				refreshFilterState!('uncTechnicalSpecification.param', [], url)
				refreshFilterState!('uncTechnicalSpecification.paramName', [], url)
			}
			if (fieldName === 'companyId') {
				setCompanyId(value)
				const companyId = company.filter(c => c.name == value)[0]?.id
				setFilials(filial?.filter(f => f.companyId === companyId))
			} else if (fieldName !== 'filialId') {
				refreshFilterState!('filialId', [], url)
			}
			if (fieldName === 'ipr_id') {
				setSelectVersionIpr(value)
				dispatch(setVersionIprName(value as string))
			}
			refreshFilterState!(fieldName, value, url)
		}
	}

	const fetchDataVersionIpr = async () => {
		if (company_id) {
			const matchedIprArrays = await company
				.filter(company => company_id == company.name)
				.map(company => company?.ipr)
				.flat()
				.sort((a, b) => a.created_at - b.created_at)

			if (!versionIpr) {
				const ipr = await matchedIprArrays.find((item: any) => item.is_active)
				refreshFilterValue('ipr_id', ipr?.version_name)

				setCompanyIPRS(() => matchedIprArrays.map((item: any) => item.version_name))
				setCompanyFullIpr(() => matchedIprArrays)
			} else {
				let ipr: any = {}
				//@ts-ignore
				if (filterState.ipr_id) {
					//@ts-ignore
					ipr = await matchedIprArrays.find((item: any) => item.version_name == filterState?.ipr_id)
				} else {
					ipr = await matchedIprArrays.find((item: any) => item.version_name == versionIpr)
				}
				refreshFilterValue('ipr_id', ipr?.version_name)
				setCompanyIPRS(() => matchedIprArrays.map((item: any) => item.version_name))
				setCompanyFullIpr(() => matchedIprArrays)
			}
		}
	}

	useEffect(() => {
		fetchDataVersionIpr()
	}, [company_id, versionIpr])

	useEffect(() => {
		if (!company_id && (url == 'user-projects' || url == 'user-archive')) {
			refreshFilterValue('companyId', companyUser?.name)
		}
	}, [companyUser])

	return (
		<FilterWrapper resultCnt={filterCountResult} resetFilterHandler={resetFilter} showFilterResult={showFilterResult}>
			<FilterItemGroup tagsFilter={['user-projects', 'user-archive']} name='Проект'>
				<FilterItem
					name='Идентификатор'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.code' : 'code'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Наименование'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.name' : 'name'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Дата'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='created_at.min'
					fieldNameEnd='created_at.max'
					setValue={refreshFilterValue}
					dateFormat='dd.MM.yyyy'
					dateOpenTo='date'
					dateViews={['date', 'month', 'year']}
				/>
				<FilterItem
					name='Версия'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='archived_at.min'
					fieldNameEnd='archived_at.max'
					setValue={refreshFilterValue}
					dateFormat='dd.MM.yyyy'
					dateOpenTo='date'
					dateViews={['date', 'month', 'year']}
				/>
				<FilterItem
					name='Отсутствует расчет по УНЦ'
					filterState={filterState}
					mode='checkbox'
					fieldName='unc_calculation'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Есть ограничение по УНЦ'
					filterState={filterState}
					mode='checkbox'
					fieldName='unc_limitation'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Только ПИР'
					filterState={filterState}
					mode='checkbox'
					fieldName='onlyPIR'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Тех.прис.'
					filterState={filterState}
					mode='checkbox'
					fieldName='techConnect'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>

			<FilterItemGroup tagsFilter={['user-projects', 'user-archive']} name='Организация'>
				<FilterItem
					placeholder='Компания'
					filterState={filterState}
					mode='select'
					fieldName={'companyId'}
					setValue={refreshFilterValue}
					items={company?.map((company: ICompany) => company.name).sort() || []}
				/>
				<FilterItem
					isMultiple
					placeholder='Филиал'
					filterState={filterState}
					mode='select'
					fieldName={'filialId'}
					setValue={refreshFilterValue}
					items={filials?.map((el: IFilial) => el.name)}
				/>
			</FilterItemGroup>

			{/* фильтр версий ипр */}
			{
				//@ts-ignore
				filterState['companyId'] && (
					<FilterItemGroup tagsFilter={['user-projects', 'user-archive']} name='Версия ИПР'>
						<FilterItem
							placeholder='Версия ИПР'
							filterState={filterState}
							mode='select'
							fieldName={'ipr_id'}
							setValue={refreshFilterValue}
							items={companyIPRS || []}
						/>
					</FilterItemGroup>
				)
			}

			{/* фильтр версий ипр */}
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Проект'>
				<FilterItem
					name='Идентификатор'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.code' : 'code'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Наименование'
					filterState={filterState}
					mode='input'
					fieldName={currentFilter ? 'project.name' : 'name'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Проектно-изыскательские работы'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsPIR'
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Только имеющие расценки по УНЦ'
					filterState={filterState}
					mode='checkbox'
					fieldName='existsUNC'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects']} name='Организация'>
				<FilterItem
					isMultiple
					placeholder='Наименование'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? 'company_id' : 'groupId'}
					setValue={refreshFilterValue}
					items={
						currentFilter
							? company?.map((company: ICompany) => company.name).sort() || []
							: groups?.map((group: IGroup) => group.name).sort() || []
					}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Регион'>
				<FilterItem
					isMultiple
					placeholder='Регион'
					filterState={filterState}
					mode='select'
					fieldName={currentFilter ? `region_code` : 'regionId'}
					setValue={refreshFilterValue}
					items={regions?.map((region: IRegion) => region.name) || []}
				/>
			</FilterItemGroup>
			<FilterItemGroup tagsFilter={['common-projects', 'favorites-common-projects']} name='Период'>
				<FilterItem
					isMultiple
					placeholder='Публикация в открытых источниках'
					filterState={filterState}
					mode='select'
					fieldName='publicationDate'
					setValue={refreshFilterValue}
					items={publicationDates.map((date: string) => formatDate(date, !currentFilter)!) || []}
				/>
				<FilterItem
					name='Период реализации'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='yearBegin.min'
					fieldNameEnd='yearEndOffer.max'
					setValue={refreshFilterValue}
					dateFormat='yyyy'
					dateOpenTo='year'
					dateViews={['year']}
				/>
				<FilterItem
					name='Добавление карточки проекта'
					filterState={filterState}
					mode='dates-range'
					fieldNameStart='cardCreated.min'
					fieldNameEnd='cardCreated.max'
					setValue={refreshFilterValue}
					dateFormat='dd.MM.yyyy'
					dateOpenTo='year'
					dateViews={['year', 'month', 'date']}
				/>
			</FilterItemGroup>
			<FilterItemGroup
				tagsFilter={['common-projects', 'favorites-common-projects']}
				name='Оценка полной стоимости, млн руб. с НДС'
			>
				<FilterItem
					name='По УНЦ'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.valuationUncOfferForecast.min' : 'valuationUncOfferForecast.min'}
					fieldNameEnd={currentFilter ? 'project.valuationUncOfferForecast.max' : 'valuationUncOfferForecast.max'}
					setValue={refreshFilterValue}
					isDisable={
						currentFilter
							? Boolean(filterState['project.valuationUncOfferForecast'])
							: Boolean(filterState['valuationUncOfferForecast'])
					}
					withCheckbox={{
						checked: currentFilter
							? Boolean(filterState['project.valuationUncOfferForecast']) || false
							: Boolean(filterState['valuationUncOfferForecast']) || false,
						setChecked: (value: boolean) =>
							currentFilter
								? refreshFilterValue('project.valuationUncOfferForecast', value)
								: refreshFilterValue('valuationUncOfferForecast', value),
						label: 'Без расчёта по УНЦ',
						checkboxChange: () =>
							currentFilter
								? refreshFilterValue(
										'project.valuationUncOfferForecast',
										filterState['project.valuationUncOfferForecast']
								  )
								: refreshFilterValue('valuationUncOfferForecast', filterState['valuationUncOfferForecast']),
					}}
				/>
				<br />
				<br />
				<FilterItem
					name='В прогнозных ценах'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.valuationOfferForecast.min' : 'valuationOfferForecast.min'}
					fieldNameEnd={currentFilter ? 'project.valuationOfferForecast.max' : 'valuationOfferForecast.max'}
					setValue={refreshFilterValue}
				/>
				<FilterItem
					name='Остаток финансирования в прогнозных ценах'
					filterState={filterState}
					mode='range-numbers'
					fieldNameStart={currentFilter ? 'project.balanceOffer.min' : 'balanceOffer.min'}
					fieldNameEnd={currentFilter ? 'project.balanceOffer.max' : 'balanceOffer.max'}
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
			<FilterItemGroup
				tagsFilter={['common-projects', 'favorites-common-projects']}
				name='Полная сметная стоимость согласно ПСД (с НДС)'
			>
				{!filterState['estimatedOfferYM'] && (
					<FilterItem
						name='Стоимость'
						filterState={filterState}
						mode='range-numbers'
						fieldNameStart={currentFilter ? 'project.estimatedOfferCurrent.min' : 'estimatedOfferCurrent.min'}
						fieldNameEnd={currentFilter ? 'project.estimatedOfferCurrent.max' : 'estimatedOfferCurrent.max'}
						setValue={refreshFilterValue}
						isDisable={
							currentFilter
								? Boolean(filterState['project.estimatedOfferYM'])
								: Boolean(filterState['estimatedOfferYM'])
						}
					/>
				)}

				{!filterState['estimatedOfferYM'] && (
					<FilterItem
						name='Дата составления'
						filterState={filterState}
						mode='dates-range'
						fieldNameStart={currentFilter ? 'project.estimatedOfferYM.min' : 'estimatedOfferYM.min'}
						fieldNameEnd={currentFilter ? 'project.estimatedOfferYM.max' : 'estimatedOfferYM.max'}
						setValue={refreshFilterValue}
						dateOpenTo='year'
						dateViews={['year', 'month']}
					/>
				)}

				<FilterItem
					name='Без ПСД'
					filterState={filterState}
					mode='checkbox'
					fieldName='estimatedOfferYM'
					setValue={refreshFilterValue}
				/>
			</FilterItemGroup>
		</FilterWrapper>
	)
}
export default Filter
