import React, { useEffect, useState } from 'react'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import { changeCfArchitecture } from '@root-gipro/modules/userProjects/store/actions'
import { useDispatch } from 'react-redux'
import './Comment.scss'

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: '6px 0',
		minWidth: 120,
		width: '100%',
	},
	select: {
		border: '1px solid #e0e0e0',
		borderRadius: '4px',
		width: '100%',
		height: '32px',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '16px',
		outline: 'none',
		padding: '0 10px',
		'&:focus': {
			borderRadius: '4px',
			backgroundColor: 'unset',
			border: '1px solid #e0e0e0',
			boxShadow: 'none',
		},
		'&:hover': {
			backgroundColor: 'unset !important',
		},
		'&::before': {
			borderBottom: 'none !important',
		},
		'&::after': {
			borderBottom: 'none !important',
		},
	},
	icon: {
		fill: 'gray',
	},
	checkbox: {
		color: 'gray',
		'&$checked': {
			color: '#1baaf0',
		},
	},
	checked: {},
}))

const theme = createMuiTheme({
	overrides: {
		MuiSelect: {
			select: {
				'&:focus': {
					backgroundColor: 'unset !important',
					boxShadow: 'none',
				},
				'&:hover': {
					backgroundColor: 'unset !important',
					borderBottom: 'none !important',
				},
				'&::before': {
					borderBottom: 'none !important',
				},
				'&::after': {
					borderBottom: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			notchedOutline: {
				border: 'none',
			},
		},
	},
})

type MenuItemOption = {
	id: number
	value: string
}

type CoefficentSelectProps = {
	selectsUncComplicatingType: boolean
	handler: () => void
	setComplicatingParams: (mode: any) => void
	complicatingParams: any
	id: any
	selectsUncComplicating: any
	coefficentUpdate: (complicatingParams: any) => void
}

const CoefficentSelect: React.FC<CoefficentSelectProps> = ({
	selectsUncComplicatingType,
	handler,
	setComplicatingParams,
	complicatingParams,
	id,
	selectsUncComplicating,
	coefficentUpdate
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [selectedValues, setSelectedValues] = useState<string[] | number[]>([])
	const [focus, setFocus] = useState<boolean>(false)
	const menuItems: MenuItemOption[] = selectsUncComplicating?.selects
	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedValues(event.target.value as string[] | number[])
	}

	useEffect(() => {
		if (complicatingParams) {
			if (selectsUncComplicatingType && !Array.isArray(complicatingParams)) {
				if (complicatingParams.length >= 2) {
					setSelectedValues(complicatingParams.split(',').map((item: any) => +item))
				} else {
					setSelectedValues([+complicatingParams])
				}
			} else {
				setSelectedValues(() => complicatingParams)
			}
		}
	}, [complicatingParams])

	const handleUpdateCf = () => {
		if (selectedValues === complicatingParams) {
			setFocus(false)
			return
		}
		setComplicatingParams(() => selectedValues)

		if (selectsUncComplicatingType && Array.isArray(selectedValues)) {
			const checkComplicating = selectedValues.join(',') == complicatingParams
			if (checkComplicating) {
				setFocus(false)
				return
			}
			coefficentUpdate(selectedValues.sort())
			setFocus(false)
			return
		}
		coefficentUpdate([+selectedValues])

		setFocus(false)
	}

	const renderValue = (selected: any) => {
		if (Array.isArray(selected)) {
			return selected
				.map((id: number) => {
					const item = menuItems.find(menuItem => menuItem.id === id)
					return item ? item.value : ''
				})
				.join(', ')
		}
		const item = menuItems.find(menuItem => menuItem.id === selected)
		return item ? item.value : ''
	}

	return (
		<ThemeProvider theme={theme}>
			<FormControl className={`${classes.formControl} ${focus && 'selectFocus'}`}>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					multiple={selectsUncComplicatingType}
					onBlur={handleUpdateCf}
					onFocus={() => setFocus(true)}
					value={selectedValues}
					onChange={handleChange}
					className={classes.select}
					inputProps={{
						classes: {
							icon: classes.icon,
						},
					}}
					renderValue={renderValue}
				>
					{menuItems.map(item => (
						<MenuItem key={item.id} value={item.id}>
							{selectsUncComplicatingType === true ? (
								<>
									<Checkbox
										checked={(selectedValues as number[])?.indexOf(item.id) > -1}
										classes={{ root: classes.checkbox, checked: classes.checked }}
									/>
									<ListItemText primary={item.value} />
								</>
							) : (
								item.value
							)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</ThemeProvider>
	)
}

export default CoefficentSelect
