import { Fade, Modal, Theme, createStyles, makeStyles } from '@material-ui/core'
import { updateUserInfo } from '@root-gipro/modules/AdminPanel/store/actions'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AdminPanelCreateUserCompany from '../AdminCreateUser/AdminPanelCreateUserCompany'
import AdminPanelCreateUserFilial from '../AdminCreateUser/AdminPanelCreateUserFilial'
import AdminPanelUpdateUserInput from './AdminPanelUpdateUserInput'
import AdminPanelUpdateAccess from './AdminPanelUpdateAccess'
import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { disabledAdd, validateEmail, validatePassword } from '@root-gipro/modules/AdminPanel/store/helpers'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			overflow: 'auto',
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	})
)

interface IAdminPanelCreateUser {
	open: boolean
	handleClose: () => void
	user: IUser
}

const AdminPanelUpadteUser: FC<IAdminPanelCreateUser> = ({ open, handleClose, user }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [userObj, setUserObj] = useState<IUser>(user)
	const [userAccess, setUserAccess] = useState({
		date_start: user.access ? user.access?.date_start : new Date().getTime(),
		date_end: user.access ? user.access?.date_end : new Date().getTime(),
		access: user.access ? Boolean(user.access?.access) : false,
	})
	const [selectedCompany, setSelectedCompany] = useState<number>(Number(user.companyId))
	const [errorData, setErrorData] = useState<{
		mail: { error: boolean; message: string }
		password: { error: boolean; message: string }
	}>({
		mail: { error: false, message: '' },
		password: { error: false, message: '' },
	})

	const addUser = () => {
		if (
			typeof userObj['firstname'] !== 'undefined' &&
			typeof userObj['lastname'] !== 'undefined' &&
			typeof userObj['email'] !== 'undefined' &&
			typeof userObj['data'] !== 'undefined'
		) {
			const restUser: Record<string, any> = { ...userObj }
			delete restUser['id']
			delete restUser['verifiedEmail']
			delete restUser['deviceLimit']
			delete restUser['roleId']

			if (typeof restUser['password'] == 'undefined') {
				restUser.password = null
			}
			dispatch(
				updateUserInfo(user.id, restUser as IUser, userAccess.date_start, userAccess.date_end, userAccess.access)
			)

			handleClose()
		} else {
			dispatch(
				showNotify({
					type: 'error',
					message: `Ошибка`,
				})
			)
		}
	}

	const getValue = (name: string, value: string) => {
		console.log(name)
		console.log(value)
		if (name === 'phone') {
			setUserObj(prev => ({ ...prev, data: { ...prev.data, [name]: value } }))
			setUserObj(prev => ({ ...prev, [name]: value }))
		} else if (
			name === 'firstname' ||
			name === 'lastname' ||
			name === 'email' ||
			name === 'password' ||
			name === 'phone'
		) {
			if (name === 'email') {
				const emailError = validateEmail(value)
				setErrorData(prev => ({ ...prev, mail: emailError }))
			} else if (name === 'password') {
				const passwordError = validatePassword(value)
				setErrorData(prev => ({ ...prev, password: passwordError }))
			}
			setUserObj(prev => ({ ...prev, [name]: value }))
		} else if (name === 'companyId') {
			setSelectedCompany(Number(value))
			setUserObj(prev => ({ ...prev, data: { ...prev.data, company: value } }))
			setUserObj(prev => ({ ...prev, [name]: value }))
		} else if (name === 'filialId') {
			setUserObj(prev => ({ ...prev, data: { ...prev.data, filial: value } }))
			setUserObj(prev => ({ ...prev, [name]: value }))
		}
	}

	const getValueAccess = (name: string, value: number | boolean) => {
		if (name === 'date_start' && typeof value === 'number') {
			setUserAccess(prevState => ({ ...prevState, date_start: value }))
		} else if (name === 'date_end' && typeof value === 'number') {
			setUserAccess(prevState => ({ ...prevState, date_end: value }))
		} else if (name === 'access' && typeof value === 'boolean') {
			setUserAccess(prevState => ({ ...prevState, access: value }))
		}
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			className={classes.modal}
			closeAfterTransition
		>
			<Fade in={open}>
				<form
					noValidate
					autoComplete='off'
					style={{ background: 'white', padding: '32px', maxWidth: 550, width: '100%' }}
				>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: 16 }}>
						<AdminPanelCreateUserCompany
							getValue={getValue}
							defaultValue={user.companyId}
							type='company'
							name='companyId'
							label='Компания'
						/>
						<AdminPanelCreateUserFilial
							getValue={getValue}
							defaultValue={user.data.filial}
							type='filial'
							name='filialId'
							label='Филиал'
							selectedCompany={selectedCompany}
						/>
						<AdminPanelUpdateUserInput
							getValue={getValue}
							defaultValue={user.lastname}
							type='text'
							name='lastname'
							label='Фамилия'
						/>
						<AdminPanelUpdateUserInput
							getValue={getValue}
							defaultValue={user.firstname}
							type='text'
							name='firstname'
							label='Имя'
						/>
						<AdminPanelUpdateUserInput
							getValue={getValue}
							defaultValue={user.email}
							type='email'
							name='email'
							label='Почта'
							error={errorData.mail.error}
							dateError={errorData.mail.message}
						/>

						<AdminPanelUpdateUserInput
							getValue={getValue}
							defaultValue={user?.data?.phone ? user.data.phone : ''}
							type='tel'
							name='phone'
							label='Номер телефона'
						/>
						<AdminPanelUpdateUserInput
							getValue={getValue}
							defaultValue={''}
							type='text'
							name='password'
							label='Изменить пароль'
							error={errorData.password?.error}
							dateError={errorData.password.message}
						/>
						<AdminPanelUpdateAccess userAccess={user.access} getValue={getValueAccess} />
					</div>
					<PrimaryBtn
						onClick={addUser}
						style={{ width: '100%' }}
						disabled={disabledAdd(userObj, errorData, 'changeUser')}
					>
						Изменить
					</PrimaryBtn>
				</form>
			</Fade>
		</Modal>
	)
}

export default AdminPanelUpadteUser
