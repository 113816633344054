import { IUnc, IUncAddBuffer, IUncTableRequest, IUserProject } from '@root-gipro/store/interfaces'
import { ReactText } from 'react'

export enum UserArchivesType {
	CREATE_PROJECT_TEMPLATE = 'ARCHIVE/SET_CREATE_PROJECT_TEMPLATE',
	FETCH_USER_PROJECT = 'ARCHIVE/FETCH_USER_PROJECT',
	SAVE_USER_PROJECT = 'ARCHIVE/SAVE_USER_PROJECT',
	CHOOSE_PROJECT = 'ARCHIVE/CHOOSE_PROJECT',
	SET_ORIGINAL_OBJ = 'ARCHIVE/SET_ORIGINAL_OBJ',
	DELETE_USER_PROJECT = 'ARCHIVE/DELETE_PROJECT',
	SET_TOGGLE_TABS = 'ARCHIVE/SET_TOGGLE_TABS',
	CHANGE_UNC_CHECKED_TABLE = 'ARCHIVE/CHANGE_UNC_CHECKED_TABLE',
	FETCH_REQUEST = 'ARCHIVE/FETCH_REQUEST',
	UNC_POSITION_EDIT = 'ARCHIVE/UNC_POSITION_EDIT',
	SET_T7_EDIT_MODE = 'ARCHIVE/SET_T7_EDIT_MODE',
	ADD_UNC = 'ARCHIVE/ADD_UNC',

	CREATE_UNC_B1 = 'ARCHIVE/CREATE_UNC_B1',
	CREATE_SPECIAL_UNC = 'ARCHIVE/CREATE_SPECIAL_UNC',
	FETCH_MINI_TABLE = 'ARCHIVE/FETCH_MINI_TABLE',
	DELETE_UNC = 'ARCHIVE/DELETE_UNC',
	CHANGE_REGION_FACTOR = 'ARCHIVE/CHANGE_REGION_FACTOR',
	CHANGE_FORM_TWENTY_NAME = 'ARCHIVE/CHANGE_FORM_TWENTY_NAME',
	FETCH_COST_TABLE = 'ARCHIVE/FETCH_COST_TABLE',
	APPEND_UNC_SEARCH = 'ARCHIVE/APPEND_UNC_SEARCH',
	GET_USER_PROJECTS = 'ARCHIVE/GET_USER_PROJECTS',
	FETCH_UNCS = 'ARCHIVE/FETCH_UNCS',
	GET_T7_DATA = 'ARCHIVE/GET_T7_DATA',
	FETCH_UNC_TABLE_DATA = 'ARCHIVE/FETCH_UNC_TABLE_DATA',
	CHANGE_UNC_COMMENT = 'ARCHIVE/CHANGE_UNC_COMMENT',
	CHANGE_UNC_COUNTER = 'ARCHIVE/CHANGE_UNC_COUNTER',
	CHANGE_UNC_REGION = 'ARCHIVE/CHANGE_UNC_REGION',
	CHANGE_UNC_VOLTAGE = 'ARCHIVE/CHANGE_UNC_VOLTAGE',
	SELECT_VOLTAGE = 'ARCHIVE/SELECT_VOLTAGE',
	UPDATE_DEFLATORS = 'ARCHIVE/UPDATE_DEFLATORS',
	UPDATE_DEFLATORS_VALUES = 'ARCHIVE/UPDATE_DEFLATORS_VALUES',
	GET_CHECKED_ERRORS = 'ARCHIVE/GET_CHECKED_ERRORS',
	DOWNLOAD_RULES_CHECK = 'ARCHIVE/DOWNLOAD_RULES_CHECK',
	UPLOADER_RULES_CHECK = 'ARCHIVE/UPLOADER_RULES_CHECK',
	DOWNLOAD_PROJECT = 'ARCHIVE/DOWNLOAD_PROJECT',
	GET_TEMPLATE_UNNORMOLIZED_COSTS = 'ARCHIVE/GET_TEMPLATE_UNNORMOLIZED_COSTS',
	COPY_PROJECT = 'ARCHIVE/COPY_PROJECT',
	GET_UNNORMOLIZED_COSTS = 'ARCHIVE/GET_UNNORMOLIZED_COSTS',
	UPLOAD_UNNORMOLIZED_COSTS = 'ARCHIVE/UPLOAD_UNNORMOLIZED_COSTS',
	DELETE_UNNORMOLIZED_COSTS = 'ARCHIVE/DELETE_UNNORMOLIZED_COSTS',
	ADD_PRIVATE_PROJECT = 'ARCHIVE/ADD_PRIVATE_PROJECT',
	SET_VOLTAGE = 'ARCHIVE/SET_VOLTAGE',
	SELECT_VOLTAGE_ID = 'ARCHIVE/SELECT_VOLTAGE_ID',
	SHOW_PROJECT_ERRORS = 'ARCHIVE/SHOW_PROJECT_ERRORS',
	SET_PROJECT_ERRORS = 'ARCHIVE/SET_PROJECT_ERRORS',
	SET_PROJECT_FILTER = 'ARCHIVE/SET_PROJECT_FILTER',
	SET_INTERPOLATION = 'ARCHIVE/SET_INTERPOLATION',
	SET_PROJECT_YEARS = 'ARCHIVE/SET_PROJECT_YEARS',
	SET_UNC_FILTER = 'ARCHIVE/SET_UNC_FILTER',
	SET_UNC_ADD_BUFFER = 'ARCHIVE/SET_UNC_ADD_BUFFER',
	SELECT_TABLE_ELEM = 'ARCHIVE/SELECT_TABLE_ELEM',
	SET_ACTUAL_FUNDING = 'ARCHIVE/SET_ACTUAL_FUNDING',
	SET_OFFER_RATIONALE = 'ARCHIVE/SET_OFFER_RATIONALE',
	SET_ADDITIONAL_FUNDING_OFFER = 'ARCHIVE/SET_ADDITIONAL_FUNDING_OFFER',
	SET_VALUATION_OFFER_FORECAST = 'ARCHIVE/SET_VALUATION_OFFER_FORECAST',
	SET_YEARS = 'ARCHIVE/SET_YEARS',
	SET_PROJECT_NAME = 'ARCHIVE/SET_PROJECT_NAME',
	SET_PROJECT_СODE = 'ARCHIVE/SET_PROJECT_СODE',
	SET_PROJECT_UNCS = 'ARCHIVE/SET_PROJECT_UNCS',
	SET_IS_NEW_PROJECT = 'ARCHIVE/SET_IS_NEW_PROJECT',
	SET_NDS = 'ARCHIVE/SET_NDS',
	SET_ACTIVE_UNCS = 'ARCHIVE/SET_ACTIVE_UNCS',
	SET_UNC_EDIT_MODE = 'ARCHIVE/SET_UNC_EDIT_MODE',
	SET_USER_PROJECTS = 'ARCHIVE/SET_USER_PROJECTS',
	APPEND_USER_PROJECTS = 'ARCHIVE/APPEND_USER_PROJECTS',
	ADD_USER_PROJECTS = 'ARCHIVE/ADD_USER_PROJECTS',
	SET_ALLOW_MORE_USER_PROJECTS = 'ARCHIVE/SET_ALLOW_MORE_USER_PROJECTS',
	SET_USER_PROJECTS_COUNT = 'ARCHIVE/SET_USER_PROJECTS_COUNT',
	LOADING_USER_PROJECTS = 'ARCHIVE/LOADING_USER_PROJECTS',
	LOADING_T7DATA = 'ARCHIVE/LOADING_T7DATA',
	SET_REGION_IDS = 'ARCHIVE/SET_REGION_IDS',
	SET_FILIAL_ID = 'ARCHIVE/SET_FILIAL_ID',
	SET_UNC_TABLE_DATA = 'ARCHIVE/SET_UNC_TABLE_DATA',
	SET_VOLTAGE_ID = 'ARCHIVE/SET_VOLTAGE_ID',
	SORT_USER_PROJECTS = 'ARCHIVE/SORT_USER_PROJECTS',
	SET_GROUP_TYPE = 'SET_GROUP_TYPE',
	SET_PHASE = 'SET_PHASE',
	SET_DECREE_NAME = 'SET_DECREE_NAME',
	SET_DECREE_NUM = 'SET_DECREE_NUM',
	SET_NOT_EXCEED = 'SET_NOT_EXCEED',
}

interface FetchRequest {
	type: UserArchivesType.FETCH_REQUEST;
}
interface SetUncEditMode {
	type: UserArchivesType.SET_UNC_EDIT_MODE;
	mode: boolean;
}
interface SetUserProjects {
	type: UserArchivesType.SET_USER_PROJECTS;
	projects: IUserProject[];
	totalInvestmentUncOfferSum: number | string;
	userProjectsCnt: number;
}

interface AppendUserProjects {
	type: UserArchivesType.APPEND_USER_PROJECTS;
	projectsCount: number;
}

interface AddUserProjects {
	type: UserArchivesType.ADD_USER_PROJECTS;
	projects: IUserProject[];
}
interface SetAllowMoreUserProjects {
	type: UserArchivesType.SET_ALLOW_MORE_USER_PROJECTS;
	allowMoreUserProjects: boolean;
}

interface SetUserProjectsPaginationCount {
	type: UserArchivesType.SET_USER_PROJECTS_COUNT;
	paginationCounter: number;
}

interface ChangeUncCheckedTable {
	type: UserArchivesType.CHANGE_UNC_CHECKED_TABLE;
	uncCheckedTable: number;
}
interface LoadingUserProjects {
	type: UserArchivesType.LOADING_USER_PROJECTS;
	loading: boolean;
}

interface LoadingT7Data {
	type: UserArchivesType.LOADING_T7DATA;
	loading: boolean;
}

interface ShowProjectErrors {
	type: UserArchivesType.SHOW_PROJECT_ERRORS;
	show: boolean;
}

interface CreateProjectTemplate {
	type: UserArchivesType.CREATE_PROJECT_TEMPLATE;
}
interface ChooseProject {
	type: UserArchivesType.CHOOSE_PROJECT;
	project: IUserProject;
}
interface SetOriginal {
	type: UserArchivesType.SET_ORIGINAL_OBJ;
	project: IUserProject;
}
interface SetActiveUncs {
	type: UserArchivesType.SET_ACTIVE_UNCS;
	uncs: IUnc[];
}

interface SetProjectUncs {
	type: UserArchivesType.SET_PROJECT_UNCS;
	uncs: IUnc[];
}
interface SetIsNewProject {
	type: UserArchivesType.SET_IS_NEW_PROJECT;
	isNew: boolean;
}

interface SetProjectName {
	type: UserArchivesType.SET_PROJECT_NAME;
	name: string;
}

interface SetProjectYears {
	type: UserArchivesType.SET_YEARS;
	begin: number | null;
	end: number | null;
}

interface SetProjectValuation {
	type: UserArchivesType.SET_VALUATION_OFFER_FORECAST;
	valuation: number;
}
interface SetProjectAdditional {
	type: UserArchivesType.SET_ADDITIONAL_FUNDING_OFFER;
	offer: number;
}
interface SetProjectRationale {
	type: UserArchivesType.SET_OFFER_RATIONALE;
	offerRationale: string;
}
interface SetProjectActual {
	type: UserArchivesType.SET_ACTUAL_FUNDING;
	actualFunding: number;
}
interface SetProjectNds {
	type: UserArchivesType.SET_NDS;
	nds: number;
}
interface SetProjectGroupNum {
	type: UserArchivesType.SET_GROUP_TYPE;
	groupNum: string;
}
interface SetProjectPhase {
	type: UserArchivesType.SET_PHASE;
	phase: string;
}
interface SetProjectDecreeName {
	type: UserArchivesType.SET_DECREE_NAME;
	decreeName: string;
}
interface SetProjectDecreeNumber {
	type: UserArchivesType.SET_DECREE_NUM;
	decreeNumber: string;
}
interface SetProjectNotExceeding {
	type: UserArchivesType.SET_NOT_EXCEED;
	notExceeding: number | null;
}
interface SelectTableElem {
	type: UserArchivesType.SELECT_TABLE_ELEM;
	selectedElem: Omit<IUncTableRequest, 'uncTable'> | null;
}
interface ClearBuffer {
	type: UserArchivesType.SET_UNC_ADD_BUFFER;
	buffer: IUncAddBuffer[];
}
interface SetYears {
	type: UserArchivesType.SET_PROJECT_YEARS;
	years: { [key: string]: { offer: number | null; deflator: number | null } };
}
interface SetInterpolation {
	type: UserArchivesType.SET_INTERPOLATION;
	obj: { [key: string]: string };
}

interface SetProjectFilter {
	type: UserArchivesType.SET_PROJECT_FILTER;
	filter: string;
}

interface SetT7EditMode {
	type: UserArchivesType.SET_T7_EDIT_MODE;
	mode: boolean;
}
interface SetProjectErrors {
	type: UserArchivesType.SET_PROJECT_ERRORS;
	errors: { [key: string]: string[] } | null;
}

interface SetVoltage {
	type: UserArchivesType.SET_VOLTAGE;
	targetVoltageValues: { [key: string]: string };
	uncId: ReactText;
}

interface SetProjectCode {
	type: UserArchivesType.SET_PROJECT_СODE;
	code: string;
}

interface SetRegionIds {
	type: UserArchivesType.SET_REGION_IDS;
	regionIds: string[];
}
interface SetFilialIds {
	type: UserArchivesType.SET_FILIAL_ID;
	filialId: string;
}

interface SetUncTableData {
	type: UserArchivesType.SET_UNC_TABLE_DATA;
	uncTableData: any;
}

interface SetUncFilter {
	type: UserArchivesType.SET_UNC_FILTER;
	filter: string;
}

interface SetUncFilter {
	type: UserArchivesType.SET_UNC_FILTER;
	filter: string;
}

interface SetVoltageId {
	type: UserArchivesType.SET_VOLTAGE_ID;
	targetVoltageId: string;
	uncId: string;
}
interface SortUserProjects {
	type: UserArchivesType.SORT_USER_PROJECTS;
	name: string;
	sortName: string;
	order: boolean;
}
export type UserArchivesActionsTypes =
	| SetVoltageId
	| SetUncFilter
	| SetUncTableData
	| SetRegionIds
	| SetFilialIds
	| SetProjectCode
	| SetUserProjects
	| AppendUserProjects
	| AddUserProjects
	| SetAllowMoreUserProjects
	| SetUserProjectsPaginationCount
	| LoadingUserProjects
	| SetVoltage
	| SetProjectErrors
	| SetProjectFilter
	| SetInterpolation
	| SetYears
	| ClearBuffer
	| SelectTableElem
	| SetProjectNds
	| SetProjectGroupNum
	| SetProjectPhase
	| SetProjectDecreeName
	| SetProjectDecreeNumber
	| SetProjectNotExceeding
	| SetProjectActual
	| SetProjectRationale
	| SetProjectAdditional
	| SetProjectValuation
	| SetProjectYears
	| SetProjectName
	| SetIsNewProject
	| SetProjectUncs
	| SetActiveUncs
	| SetOriginal
	| ChooseProject
	| FetchRequest
	| CreateProjectTemplate
	| SetUncEditMode
	| ShowProjectErrors
	| SortUserProjects
	| SetT7EditMode
	| LoadingT7Data
	| ChangeUncCheckedTable
