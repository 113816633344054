import { notifyActions, NotifyTypes } from '@root-gipro/modules/notify/store/actions'
import { INotify, INotifyState } from '@root-gipro/store/interfaces/state'
import produce, { Draft } from 'immer'

  
  const initialState: INotifyState = {
	notifications: [],
  };
  
export const notifyReducer = produce((draft: Draft<INotifyState>, action: NotifyTypes) => {
  switch (action.type) {
    case notifyActions.SHOW_NOTIFY:
      const { message, type, duration } = action.payload;
      const newNotification: INotify = {
        id: new Date().getTime(),
        message,
        type,
        duration: duration ? duration : 6000,
      };
      draft.notifications.push(newNotification);
      break;
    case notifyActions.CLOSE_NOTIFY:
        draft.notifications.shift();
      break;
    default:
      return draft;
  }
}, initialState);
