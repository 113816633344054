import { AdminPanelType, AdminPanelTypes } from '@root-gipro/modules/AdminPanel/interfaces/admin-panel.actions'
import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { ICompany, IOpenModalCreateCompany } from '@root-gipro/store/interfaces'
import produce, { Draft } from 'immer'

export interface IAdminPanel {
	users: IUser[]
	usersLoading: boolean
	settingsLoading: boolean
	selectedUser: unknown
	companies: any[]
	openModalCompany: IOpenModalCreateCompany
	company: ICompany | null
	versionIpr: string
}

const initialState: IAdminPanel = {
	users: [],
	usersLoading: false,
	settingsLoading: false,
	selectedUser: null,
	companies: [],
	openModalCompany: {
		open: false,
		mode: '',
	},
	company: null,
	versionIpr: '',
}

const adminPanel = (draft: Draft<IAdminPanel>, action: AdminPanelTypes) => {
	switch (action.type) {
		case AdminPanelType.SET_USER:
			draft.selectedUser = action.user
			break
		case AdminPanelType.SET_USERS:
			draft.users = action.users
			break
		case AdminPanelType.SET_COMPANY:
			draft.companies = action.companies
			break
		case AdminPanelType.LOADING_USERS:
			draft.usersLoading = action.loading
			break
		case AdminPanelType.LOADING_SETTINGS:
			draft.settingsLoading = action.loading
			break
		case AdminPanelType.SET_OPEN_CREATE_COMPANY:
			draft.openModalCompany = action.modal
			break
		case AdminPanelType.SET_COMPANY_STATE:
			draft.company = action.company
			break
		case AdminPanelType.SET_VERSION_IPR_NAME:
			draft.versionIpr = action.name
			break
		default:
			return draft
	}
}
export const adminPanelReducer = produce(adminPanel, initialState)
