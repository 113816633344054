import { FormControl, makeStyles } from '@material-ui/core'
import { Select } from '@root-gipro/modules/userProjects/components/Project/CheckPlate/Select'
import { IRegion } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

const useStylesFormControl = makeStyles({
	root: {
		'& .MuiSelect-icon': {
			padding: 0,
			top: 'calc(50% - 12px)',
			color: 'rgba(0, 0, 0, 0.54)',
			right: 4,
			position: 'absolute',
			pointerEvents: 'none',
		},
	},
})

interface IAdminCompanySelect {
	label: string;
	setCompanyObj: (mode: any) => void;
	companyObj: any;
}

const AdminCompanySelect: FC<IAdminCompanySelect> = ({ label, setCompanyObj, companyObj }) => {
	const classesFromControl = useStylesFormControl()
	const [defaultRegions, setDefaultRegions] = useState<IRegion[]>([])
	const { regions } = useSelector((state: IState) => state.public)

	const getCurrentRegions = useMemo(() => {
		try {
			return regions?.filter((region: IRegion) => companyObj.regionIds.indexOf(region.id) !== -1)
		} catch (error) {
			console.log(error)
		}
	}, [companyObj])

	useEffect(() => {
		if (getCurrentRegions) setDefaultRegions(getCurrentRegions)
	}, [regions, companyObj?.regionIds])

	const addRegionId = (reg: any) => {
		setCompanyObj((prevState: any) => ({
			...prevState,
			regionIds: reg,
		}))
	}

	return (
		<FormControl classes={classesFromControl}>
			<label style={{ marginBottom: 8, display: 'block' }}>{label}</label>
			<Select
				subject={true}
				list={regions}
				placeholder='Выберете'
				tooltip='Субъект РФ'
				multy={true}
				changeHandler={val => {
					const regionIds = val.map((region: IRegion) => region.id)
					addRegionId(regionIds)
				}}
				defaultVal={defaultRegions}
			/>
		</FormControl>
	)
}

export default AdminCompanySelect
