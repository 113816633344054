import React, { FC, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import {
	getProjectsArchive,
	setIsNewProjectArchive,
	setProjectUncsArchive,
} from '@root-gipro/modules/userArchive/store/actions'
import SortItems from '@root-gipro/shared/FilterHeader/components/sort-items'
import store from '@root-gipro/store'
import { ISortItem } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import { prettyCost } from '@root-gipro/utils/helpers/common'

const useStyles = makeStyles({
	projectsHeadSortText: {
		color: 'rgba(0, 0, 0, 0.56)',
		fontSize: '14px',
	},
})

const classesIconButtonStyle = makeStyles(theme => ({
	root: {
		padding: '6px',
		borderRadius: 0,
	},
}))

export interface UserArchiveNav {
	sort?(name: string, sortName: string, order: boolean): void;
	sortList: ISortItem[];
}

const UserArchiveNav: FC<UserArchiveNav> = memo(({ sort, sortList }) => {
	const { totalInvestmentUncOfferSum, userProjectsCnt, uncs } = useSelector((state: IState) => state.userArchives)
	const dispatch = useDispatch()
	const history = useHistory()
	const classes = useStyles()
	const classesIconButton = classesIconButtonStyle()
	// const { roleId } = store.getState().auth.user
	const roleId = store.getState().auth.user.roleId
	// console.log(roleId);

	useEffect(() => {
		dispatch(getProjectsArchive())
		dispatch(setIsNewProjectArchive(false))
		if (uncs.length > 0) {
			dispatch(setProjectUncsArchive([]))
		}
	}, [])
	return (
		<div className='user-projects__nav'>
			<div style={{ width: '100%' }}>
				<div className='user-projects__options'>
					<div className='user-projects__options-item'>
						<span className='user-projects__options-shrt-txt'>Найдено: </span>
						<span className='user-projects__options-count'>{userProjectsCnt}</span>
					</div>
					{/* <div className='user-projects__options-item'>
						<span className='user-projects__options-shrt-txt'>на сумму </span>
						<span
							className='user-projects__options-sum'
							style={{ color: totalInvestmentUncOfferSum < 0 ? '#d33131' : '#000' }}
						>
							{prettyCost(totalInvestmentUncOfferSum / 10 ** 6)} млн.р
						</span>
					</div> */}
				</div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 8 }}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 8 }}>
						{sort && <span className={classes.projectsHeadSortText}>Сортировка по:</span>}
						{sort && <SortItems sortItems={sort} sortList={sortList} />}
					</div>
				</div>
			</div>
		</div>
	)
})

export default UserArchiveNav
