import { Tooltip } from '@material-ui/core'
import { IT7RowForm20 } from '@root-gipro/store/interfaces'
import { intFormat } from '@root-gipro/utils/helpers/common'
import React, { useEffect, useState } from 'react'

interface TableInputProps {
	value: any;
	t7row: IT7RowForm20;
	handler: any;
	disabled: boolean;
	name: string;
	inputType?: string;
}

const TableInput: React.FC<TableInputProps> = ({ value, t7row, handler, disabled, name, inputType }) => {
	const [localValue, setLocalValue] = useState<string | number | null>(value ? value : '')

	useEffect(() => {
		setLocalValue(value)

		if (name === 'unn_costs' || name === 'unn_k') {
			if (value === null) {
				value = 0

				const int = value
				const val = intFormat(int)
				setLocalValue(val)
			}
		}
	}, [value])

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setLocalValue(event.target.value as string)
		handler(event, t7row)
	}

	return (
		<Tooltip title={localValue === null ? '' : localValue}>
			<input
				type={inputType ? inputType : 'text'}
				disabled={disabled}
				className='unormcsts_input-form20'
				name={name}
				value={localValue === null ? '' : localValue}
				onChange={e => handleChange(e)}
			/>
		</Tooltip>
	)
}

export default TableInput
