import {
	createStyles,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import React, { FC, useContext, useEffect, useState } from 'react'
import AdminPanelTableUserRow from './AdminPanelTableUserRow'
import { SwapVertOutlined } from '@material-ui/icons'
import { ModalContext } from '@root-gipro/context/modal-context'
import ModalDeleteUser from './ModalsAdminPanel/ModalDeleteUser'
import { useDispatch } from 'react-redux'
import { deleteUserFetch } from '@root-gipro/modules/AdminPanel/store/actions'

interface IAdminPanelTable {
	users: IUser[]
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiTableCell-root': {
				padding: '6px 12px',
			},
		},
	})
)

const sortUsers = (key: string, ascending: boolean) => (x: IUser, y: IUser) => {
	let result
	if (key === 'id') {
		result = x.id - y.id
	} else {
		const xKey = x[key].toLowerCase()
		const yKey = y[key].toLowerCase()
		if (xKey < yKey) result = -1
		else if (xKey > yKey) result = 1
		else result = 0
	}
	return ascending ? result : -result
}

const AdminPanelTable: FC<IAdminPanelTable> = ({ users }) => {
	const classes = useStyles()
	const [sortedUsers, setSortedUsers] = useState<IUser[]>(users)
	const [sortKey, setSortKey] = useState<string>('lastname')
	const [ascending, setAscending] = useState<boolean>(true)
	const { setAnchorEl, anchorEl } = useContext(ModalContext)
	const [userInfo, setUserInfo] = useState<IUser>()
	const dispatch = useDispatch()

	useEffect(() => {
		setSortedUsers([...users].sort(sortUsers(sortKey, ascending)))
	}, [users, sortKey, ascending])

	const handleSortChange = (key: string) => {
		if (sortKey === key) {
			setAscending(!ascending)
		} else {
			setSortKey(key)
			setAscending(true)
		}
	}
	const deleteUser = () => {
		userInfo && dispatch(deleteUserFetch(userInfo.id))
	}

	return (
		<TableContainer component={Paper}>
			<div style={{ width: '100%', overflowX: 'auto' }}>
				<Table classes={classes} style={{ minWidth: 1800 }} size='small' stickyHeader aria-label='sticky table dense'>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: 100 }} align='center'>
								Действия
							</TableCell>
							<TableCell style={{ width: 70, cursor: 'pointer' }} onClick={() => handleSortChange('id')}>
								ID <SwapVertOutlined style={{ width: '13px', verticalAlign: 'bottom' }} />
							</TableCell>
							<TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortChange('lastname')}>
								ФИО <SwapVertOutlined style={{ width: '13px', verticalAlign: 'bottom' }} />
							</TableCell>
							<TableCell style={{ width: 215 }}>Дата лицензии</TableCell>
							<TableCell style={{ minWidth: 185 }}>Роль</TableCell>
							<TableCell>Компания</TableCell>
							<TableCell style={{ width: 150 }} align='center'>
								Связанные ИПР
							</TableCell>
							<TableCell>Филиал</TableCell>

							<TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortChange('email')}>
								Почта <SwapVertOutlined style={{ width: '13px', verticalAlign: 'bottom' }} />
							</TableCell>
							<TableCell style={{ minWidth: 170 }}>Телефон</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedUsers.length
							? sortedUsers.map((user, index) => (
									<AdminPanelTableUserRow setUserInfo={setUserInfo} user={user} index={index} key={user.id} />
							  ))
							: null}
					</TableBody>
				</Table>
			</div>
			{anchorEl?.dataset.popup === 'delete_user' && (
				<ModalDeleteUser anchorEl={anchorEl} user={userInfo} setAnchorEl={setAnchorEl!} handleDelete={deleteUser} />
			)}
		</TableContainer>
	)
}

export default AdminPanelTable
