import React, { useState, useEffect } from 'react'
import './ProgressBar.scss'

interface ProgressBarProps {
	percent: string | number
	bigForm?: boolean
	style?: any
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percent, bigForm,style }) => {
	const [displayPercent, setDisplayPercent] = useState(0)

	useEffect(() => {
		if (Number(percent) - displayPercent > 50 || percent === 100) {
			setDisplayPercent(Number(percent))
			return
		}
		const interval = setInterval(() => {
			if (displayPercent < Number(percent)) {
				setDisplayPercent(prev => Math.min(prev + 1, Number(percent)))
			} else {
				clearInterval(interval)
			}
		}, 20)

		return () => clearInterval(interval)
	}, [percent, displayPercent])

	const circleRadius = 45
	const circleCircumference = 2 * Math.PI * circleRadius
	const strokeDashoffset = circleCircumference - (displayPercent / 100) * circleCircumference

	return (
		<div className='progressContainer'>
			<svg className='progressCircle' viewBox='0 0 100 100' style={style}>
				<circle className='progressCircle-bg' cx='50' cy='50' r={circleRadius} strokeWidth='10' />
				<circle
					className='progressCircle-fg'
					cx='50'
					cy='50'
					r={circleRadius}
					strokeWidth='10'
					strokeDasharray={circleCircumference}
					strokeDashoffset={strokeDashoffset}
				/>
			</svg>
			<div className={`progressPercentage ${bigForm ? `big-form_load` : ''}`}>
				{displayPercent}
				{bigForm && '%'}
			</div>
		</div>
	)
}

export default ProgressBar
