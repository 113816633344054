import React, { ReactText, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { IRegion, IState } from '@root-gipro/store/interfaces'
import { useSelector } from 'react-redux'
import "./Region.scss"

interface RegionProps {
	anchorEl: (EventTarget & HTMLElement) | null;
	setAnchorEl(event: HTMLElement): void;
	handler(): void;
	regionList: IRegion[];
	checkedRegionId: ReactText | null;
	setCheckedRegion: (id: ReactText | null) => void;
	checkedZoneId: string | null;
	setCheckedZoneId: (id: string | null) => void;
}

const Region: React.FC<RegionProps> = ({
	anchorEl,
	setAnchorEl,
	handler,
	regionList,
	setCheckedRegion,
	checkedRegionId,
	checkedZoneId,
	setCheckedZoneId,
}) => {
	const [selectedRegion, setSelectedRegion] = useState<IRegion | null>(null)
	const { activeUncs, uncs } = useSelector((state: IState) => state.userProjects)

	const handleZoneClick = (zoneId: string) => {
		if (selectedRegion?.region_zones.length === 1) {
			return
		}
		setCheckedZoneId(zoneId)
	}

	const handleRegClick = (regId: string) => {
		const selected = regionList.find(elem => elem.id === regId)
		setSelectedRegion(selected || null)
		setCheckedZoneId(null)

		if (selected) {
			setSelectedRegion(selected)

			if (selected?.region_zones.length > 0) {
				const firstZoneId = selected.region_zones[0].id
				setCheckedZoneId(firstZoneId)
			} else {
				setCheckedZoneId(null)
			}

			if (checkedRegionId === regId) {
				setCheckedRegion(null)
			} else {
				setCheckedRegion(regId)
			}
		}

		uncs.map(unc => {
			if (activeUncs[0].id === unc.id && unc.regionId === regId && unc.region_zone_id && selected?.region_zones) {
				setCheckedZoneId(unc.region_zone_id)
			}
		})
	}

	const handleClose = () => {
		setAnchorEl!(null!)
		setSelectedRegion(null)
	}

	const handleConfirm = () => {
		if (selectedRegion?.region_zones.length === 1 && checkedZoneId !== selectedRegion.region_zones[0].id) {
			setCheckedZoneId(selectedRegion.region_zones[0].id)
		}
		handler()
		handleClose()
	}

	return (
		<>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{regionList.map(elem => (
					<MenuItem key={elem.id} onClick={() => handleRegClick(elem.id)}>
						{elem.name}
					</MenuItem>
				))}
			</Menu>

			<Dialog PaperProps={{ style: { minWidth: '350px' } }} open={Boolean(selectedRegion)} onClose={handleClose}>
				{selectedRegion && (
					<>
						<DialogTitle style={{ paddingBottom: 0 }}>{selectedRegion.name}</DialogTitle>
						<DialogContent>
							{selectedRegion.region_zones.length > 0 && (
								<>
									<div className='title_zone'>Экономическая зона</div>
									<div className='region_zones'>
										{selectedRegion.region_zones?.map((zone: any) => (
											<div key={zone.id}>
												<input
													type='radio'
													hidden
													value={zone.id}
													id={`zone_${zone.id}`}
													checked={checkedZoneId === zone.id}
													readOnly
												/>
												<label className='drop-down_label' onClick={() => handleZoneClick(zone.id)}>
													{zone.name}
												</label>{' '}
											</div>
										))}
									</div>
								</>
							)}
						</DialogContent>
						<DialogActions>
							<PrimaryBtn onClick={handleConfirm}>Выбрать</PrimaryBtn>
							<SecondaryBtn onClick={handleClose}>Отменить</SecondaryBtn>
						</DialogActions>
					</>
				)}
			</Dialog>
		</>
	)
}

export default Region
