import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { UserArchivesType } from '@root-gipro/modules/userArchive/models/interfaces/user-project.actions'
import {
	IAllRequestProject,
	ICheckedErrors,
	IRequestProject,
	IT7Data,
	IUnc,
	IUncAddBuffer,
	IUncCell,
	IUncTableRequest,
	IUserArchive,
} from '@root-gipro/modules/userArchive/models/interfaces/user-projects.model'
import { setActiveUncsArchive, setUserProjectsArchive } from '@root-gipro/modules/userArchive/store/actions'
import { createProjectTemplate } from '@root-gipro/modules/userProjects/store/actions'
import store from '@root-gipro/store'
import { fetchData, fetchHeaders, userArchivesApi } from '@root-gipro/store/api'
import { getCurrentPositionUnc } from '@root-gipro/utils/helpers/common'
import produce from 'immer'
import { ReactText } from 'react'
import { push } from 'react-router-redux'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	addUserProjectsArchive,
	loadingUserProjectsArchive,
	setAllowMoreUserProjectsArchive,
	setUserProjectsPaginationCountArchive,
} from './actions'

const limit = 5

//Сага загрузки/обновления проекта
function* fetchUserProject(action: { type: typeof UserArchivesType.FETCH_USER_PROJECT; id: string }) {
	const project: IUserArchive = yield call(fetchData, `/user-projects/${action.id}`, (res: any) => res.userProject)

	if (project) {
		yield put({ type: UserArchivesType.CHOOSE_PROJECT, project })
		yield put({ type: UserArchivesType.SET_ORIGINAL_OBJ, project })
	}
}

//Сага сохраненеия проекта
function* saveUserProject({
	body,
	isNew,
	id,
}: {
	type: typeof UserArchivesType.SAVE_USER_PROJECT;
	body: IUserArchive;
	isNew: boolean;
	id: ReactText;
}) {
	const copy: IUserArchive = Object.assign({}, body)

	delete copy.sumUncCost
	delete copy.userId
	delete copy.sumUncCostWithNds
	delete copy.totalInvestmentUncOffer
	delete copy.t7Id
	delete copy.versions

	let project: IRequestProject

	if (!(copy.code && copy.name && copy.years)) {
		yield put(
			showNotify({
				type: 'warning',
				message: 'Не заполнены обязательные поля',
			})
		)
		return
	}

	try {
		if (isNew) {
			project = yield call(fetchData, '/user-projects', (res: any) => res, 'POST', JSON.stringify(copy))
		} else {
			project = yield call(fetchData, `/user-projects/${id}`, (res: any) => res, 'PATCH', JSON.stringify(copy))
		}

		if (project && project.userProject) {
			const userProject: IUserArchive = project.userProject

			yield put({ type: UserArchivesType.CHOOSE_PROJECT, project: userProject })
			yield put({ type: UserArchivesType.SET_ORIGINAL_OBJ, project: userProject })

			if (isNew) {
				yield put(
					showNotify({
						type: 'success',
						message: `Создан новый проект ${userProject.code}`,
					})
				)

				yield put(push(`/user-projects/project/${userProject.id}/parameters`))
			} else {
				yield put(
					showNotify({
						type: 'success',
						message: `Проект ${userProject.code} успешно сохранен`,
					})
				)
			}
		}
	} catch (error) {
		console.log(error)
	}
}

function* deleteUserProject({
	id,
	name,
}: {
	type: typeof UserArchivesType.DELETE_USER_PROJECT;
	name: string;
	id: string;
}) {
	const res: Omit<IRequestProject, 'userProject'> = yield call(
		fetchData,
		`/user-projects/${id}`,
		(res: any) => res,
		'DELETE'
	)

	if (res.status === 'success') {
		yield put(
			showNotify({
				type: 'success',
				message: `Версия расчета ${name} успешно удалена!`,
			})
		)
		yield put(createProjectTemplate())
		yield put(push(`/user-projects`))
	}
}
function* uncEdit(params: { type: typeof UserArchivesType.UNC_POSITION_EDIT; params: any }) {
	// DroppableProps
	const { uncs } = store.getState().userProjects
	const userProjectId = store.getState().userProjects.projectObj.id
	const position = getCurrentPositionUnc(uncs, params)
	const table = params.params.destination.droppableId
	const id = params.params.draggableId
	try {
		yield call(fetchData, `/user-uncs/${id}`, (res: any) => res.userUnc, 'PATCH', JSON.stringify({ position, table }))

		yield put({ type: 'FETCH_UNCS', id: userProjectId })
	} catch (error) {
		console.log(error)
	}
}

function* addUnc({
	tableNum,
	uncCellId,
	count,
}: {
	type: typeof UserArchivesType.ADD_UNC;
	tableNum: ReactText;
	uncCellId: ReactText;
	count: number;
	addBuffer: boolean;
}) {
	try {
		const project = store.getState().userProjects.projectObj

		if (project.code === '') throw new Error('Project not defined')

		const selectedElem = store.getState().userProjects.selectedElem
		const body: any = {
			tableNum,
			uncCellId,
			count,
		}
		if (project.regionIds && project.regionIds.length > 0) body.regionId = project.regionIds[0]
		if (selectedElem && selectedElem.code.match('С')) body.tableNum = 8
		const data: IUserArchive = yield call(
			fetchData,
			`/user-projects/${project.id}/user-uncs`,
			(res: any) => res.userUnc,
			'POST',
			JSON.stringify(body)
		)

		if (data) {
			const newElem: unknown = yield call(
				fetchData,
				`/user-uncs/${data.id}?with=uncMain,uncCell,regionFactor`,
				(res: any) => res.userUnc
			)

			const userProjects = store.getState().userProjects
			const buffer = produce(userProjects, draft => {
				draft.uncAddBuffer.push(newElem as IUncAddBuffer)
			})

			yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: buffer.uncAddBuffer })
		}
	} catch (error) {
		console.log(error)
		if (error.message === 'Project not defined')
			yield put(
				showNotify({
					type: 'error',
					message: `Проект необходимо сохранить перед добавлением расценок!`,
				})
			)
	}
}

function* createUncB1({
	uncMainId,
	regionId,
	interpolated,
	tableNum,
	count,
}: {
	type: typeof UserArchivesType.CREATE_UNC_B1;
	uncMainId: number;
	regionId: ReactText;
	interpolated: number;
	tableNum: ReactText;
	count: number;
}) {
	try {
		const project = store.getState().userProjects.projectObj

		if (project.code === '') throw new Error('Project not defined')

		const body = {
			tableNum,
			uncMainId,
			regionId,
			interpolated,
			uncCellId: null,
		}

		const data: IUnc = yield call(
			fetchData,
			`/user-projects/${project.id}/user-uncs`,
			(res: any) => res.userUnc,
			'POST',
			JSON.stringify(body)
		)

		if (data) {
			const uncElem: IUnc = yield call(
				fetchData,
				`/user-uncs/${data.id}`,
				(res: any) => res.userUnc,
				'PATCH',
				JSON.stringify({ count })
			)

			const newElem: IUnc = yield call(
				fetchData,
				`/user-uncs/${data.id}?with=uncMain,uncCell,regionFactor`,
				(res: any) => res.userUnc
			)

			if (uncElem && newElem) {
				const clone = store.getState().userProjects.uncs.slice()
				clone.push(newElem)
				clone.sort((a, b) => a.tableNum - b.tableNum)
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone })
				yield put({ type: 'FETCH_USER_PROJECT', id: uncElem.userProjectId })
			}
		}
	} catch (error) {
		console.log(error)
		if (error.message === 'Project not defined')
			yield put(
				showNotify({
					type: 'error',
					message: `Проект необходимо сохранить перед добавлением расценок!`,
				})
			)
	}
}

function* createSpecialUnc({
	uncMainId,
	regionId,
	interpolated,
	tableNum,
}: {
	type: typeof UserArchivesType.CREATE_SPECIAL_UNC;
	uncMainId: ReactText;
	regionId: ReactText;
	interpolated: { [key: string]: ReactText };
	tableNum: ReactText;
}) {
	try {
		const project = store.getState().userProjects.projectObj

		if (project.code === '') throw new Error('Project not defined')

		const body: any = {
			tableNum,
			uncMainId,
			regionId,
			interpolated,
		}
		body.uncCellId = null
		const data: IUnc = yield call(
			fetchData,
			`/user-projects/${project.id}/user-uncs`,
			(res: any) => res.userUnc,
			'POST',
			JSON.stringify(body)
		)

		if (data) {
			const newElem: IUnc = yield call(
				fetchData,
				`/user-uncs/${data.id}?with=uncMain,uncCell,regionFactor`,
				(res: any) => res.userUnc
			)
			const userProjects = store.getState().userProjects
			const buffer = produce(userProjects, draft => {
				draft.uncAddBuffer.push(newElem as IUncAddBuffer)
			})
			yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: buffer.uncAddBuffer })
		}
	} catch (error) {
		console.log(error)
		if (error.message === 'Project not defined')
			yield put(
				showNotify({
					type: 'error',
					message: `Проект необходимо сохранить перед добавлением расценок!`,
				})
			)
	}
}

//Удаление унца
function* deleteUnc({ id, inBuffer }: { type: typeof UserArchivesType.DELETE_UNC; id: ReactText; inBuffer?: boolean }) {
	try {
		yield put({ type: 'FETCH_REQUEST' })
		const res: Omit<IRequestProject, 'userProject'> = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res,
			'DELETE'
		)

		if (res.status === 'success') {
			let projectId
			if (inBuffer) {
				const userProjects = store.getState().userProjects
				const ind = userProjects.uncAddBuffer.findIndex(elem => elem.id === id)
				projectId = userProjects.uncAddBuffer[ind].userProjectId
				const buffer = produce(userProjects, draft => {
					draft.uncAddBuffer.splice(
						draft.uncAddBuffer.findIndex(item => item.id === id),
						1
					)
				})
				yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: buffer.uncAddBuffer })
			} else {
				const userProjects = store.getState().userProjects
				const uncs = store.getState().userProjects.uncs
				const ind = uncs.findIndex((elem: any) => elem.id === id)
				projectId = uncs[ind].userProjectId
				const clone = produce(userProjects, draft => {
					draft.uncs.splice(
						draft.uncs.findIndex(item => item.id === id),
						1
					)
				})
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
			}

			yield put({ type: 'FETCH_USER_PROJECT', id: projectId })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки расценок
// function* fetchCostTable() {
// 	try {
// 		yield put({ type: 'SET_IN_LOADING', inLoading: true })
// 		let url = createUrlForPrice(store)
//
// 		const res = yield call(fetchData, url, res => res)
// 		const investmentPrograms = res.uncs
//
// 		yield put({ type: 'SET_COST_SEARCH', investmentPrograms })
// 		yield put({ type: 'SET_COST_PAGI_COUNTER', pagiCounter: 0 })
// 		yield put({
// 			type: 'SET_COUNTERS',
// 			resultsCnt: res.resultsCnt,
// 			projectsCnt: res.projectsCnt,
// 		})
// 		yield put({ type: 'SET_IN_LOADING', inLoading: false })
// 	} catch (error) {
// 		console.log(error)
// 	}
// }

//Сага добавления расценок в таблицу
// function* appendPricesSearch({ counter }) {
// 	try {
// 		let investmentPrograms = store.getState().costTable.investmentPrograms.slice()
// 		let url = createUrlForPrice(store, counter)
//
// 		const data = yield call(fetchData, url, res => res)
// 		investmentPrograms = investmentPrograms.concat(data.uncs)
//
// 		yield put({ type: 'SET_COST_SEARCH', investmentPrograms })
// 		if (
// 			(data.resultsCnt || data.resultsCnt === 0) &&
// 			(data.projectsCnt || data.projectsCnt === 0)
// 		) {
// 			yield put({
// 				type: 'SET_COUNTERS',
// 				resultsCnt: data.resultsCnt,
// 				projectsCnt: data.projectsCnt,
// 			})
// 		}
// 	} catch (error) {
// 		console.log(error)
// 	}
// }

// //Сага загрузки данных для таблицы в раскравыющейся строке
// function* fetchMiniTable({ id }) {
// 	try {
// 		const uncs = yield call(fetchData, `/projects/${id}/uncs?with=uncCell,uncMain,uncParameter`, res => res.uncs)
// 		if (uncs) {
// 			yield put({ type: 'SET_MINI_TABLE', uncs })
// 		}
// 	} catch (error) {
// 		console.log(error)
// 	}
// }

function* changeRegionFactor({ id, uncTableId, regionId }: any) {
	// try {
	// 	const region = yield call(
	// 		fetchData,
	// 		`/region-factors/search?uncTableId=${uncTableId}&regionId=${regionId}`,
	// 		(res: any) => res.regionFactor
	// 	)
	// 	const res = yield call(fetchData, `/uncs/${id}`, (res: any) => res.unc, 'PATCH', JSON.stringify({ regionId }))
	// 	let uncTable = store.getState().formTwenty.uncTable,
	// 		ind = searchByKey(uncTable, 'id', id)
	// 	let clone = uncTable.slice()
	// 	res.regionFactor = region
	// 	res.uncTable = clone[ind].uncTable
	// 	clone.splice(ind, 1, res)
	// 	yield put({ type: 'UPDATE_UNC_TABLE', uncTable: clone })
	// } catch (error) {
	// 	console.log(error)
	// }
}

function* changeFormTwentyName({ name }: any) {
	// try {
	// 	const id = store.getState().formTwenty.id
	// 	yield call(fetchData, `/projects/${id}`, res => res, 'PATCH', JSON.stringify({ name }))
	// } catch (error) {
	// 	console.log(error)
	// }
}

//Сага загрузки проектов
function* getMyProjects() {
	try {
		const projects: Omit<IAllRequestProject, 'userProject'> = yield call(
			fetchData,
			'/user-projects?archive',
			(res: any) => res
		)

		if (projects) {
			yield put({
				type: UserArchivesType.SET_USER_PROJECTS,
				projects: projects.userProjects,
				userProjectsCnt: projects.userProjectsCnt,
				totalInvestmentUncOfferSum: Number(projects.totalInvestmentUncOfferSum),
			})
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки таблицы унцов проекта
function* fetchUncs({ id }: { type: typeof UserArchivesType.FETCH_UNCS; id: string }) {
	try {
		const uncs: IUnc = yield call(
			fetchData,
			`/user-projects/${id}/user-uncs?with=uncMain,uncCell,regionFactor`,
			(res: any) => res.userUncs
		)
		if (uncs) {
			yield put({ type: 'SET_PROJECT_UNCS', uncs })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки таблицы T7 проекта
function* getT7Data({ id }: { type: typeof UserArchivesType.GET_T7_DATA; id: string }) {
	try {
		const t7Data: IT7Data = yield call(fetchData, `/user-projects/${id}/T7Data`, (res: any) => res.t7)
		if (t7Data) {
			yield put({ type: 'SET_T7_DATA', t7Data })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага загрузки таблицы унцов для добавления в проект
function* fetchUncTableData({
	id,
	unc_sort,
}: {
	type: typeof UserArchivesType.FETCH_UNC_TABLE_DATA;
	id: number;
	unc_sort: string;
}) {
	try {
		const uncTable: IUncTableRequest = yield call(
			fetchData,
			`/unc-mains/${id}?with=uncTable`,
			(res: any) => res.uncMain
		)

		if (uncTable) {
			yield put({ type: 'SET_UNC_TABLE_DATA', uncTableData: uncTable.uncTable })
		}
	} catch (error) {
		console.log(error)
	}
}
// Сага изменения комментария расценки
function* changeUncComment({
	id,
	comment,
}: {
	type: typeof UserArchivesType.CHANGE_UNC_COMMENT;
	id: ReactText;
	comment: string;
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ comment })
		)

		if (uncElem) {
			const userProjects = store.getState().userProjects
			const ind = userProjects.uncs.findIndex(elem => elem.id === id)
			const indActiveUncs = userProjects.activeUncs.findIndex(elem => elem.id === id)
			const cloneUnc = produce(userProjects, draft => {
				draft.uncs[ind].comment = uncElem.comment
			})
			const cloneActiveUncs = produce(userProjects, draft => {
				draft.activeUncs[indActiveUncs].comment = uncElem.comment
			})
			yield put(setActiveUncsArchive([cloneActiveUncs.activeUncs[indActiveUncs]]))
			yield put({ type: 'SET_PROJECT_UNCS', uncs: cloneUnc.uncs })
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага изменения количества единиц расценки
function* changeUncCounter({
	id,
	count,
	inBuffer,
}: {
	type: typeof UserArchivesType.CHANGE_UNC_COUNTER;
	id: ReactText;
	count: string;
	inBuffer?: boolean | undefined;
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ count })
		)

		if (uncElem) {
			const userProjects = store.getState().userProjects

			if (inBuffer) {
				const ind = userProjects.uncAddBuffer.findIndex(elem => elem.id === id)
				const copyBuffer = produce(userProjects, draft => {
					if (draft.uncAddBuffer[ind]) draft.uncAddBuffer[ind] = Object.assign(draft.uncAddBuffer[ind], uncElem)
				})
				yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: copyBuffer.uncAddBuffer })
			} else {
				const ind = userProjects.uncs.findIndex((elem: any) => elem.id === uncElem.id)
				const clone = produce(userProjects, draft => {
					if (ind !== -1) {
						draft.uncs[ind].count = uncElem.count
						draft.uncs[ind].totalCost = uncElem.totalCost
					}
				})
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
				yield put({ type: 'FETCH_USER_PROJECT', id: uncElem.userProjectId })
			}
		}
	} catch (error) {
		console.log(error)
	}
}

//Сага изменения региона расценки
function* changeUncRegion({
	id,
	regionId,
	zoneId
}: {
	type: typeof UserArchivesType.CHANGE_UNC_REGION;
	id: ReactText;
	regionId: ReactText;
	zoneId: ReactText;
}) {	
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}?with=regionFactor`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ regionId, region_zone_id: zoneId })
		)
		
		if (uncElem) {
			const userProjects = store.getState().userProjects
			const ind = userProjects.uncs.findIndex((elem: any) => elem.id === uncElem.id)
			const clone = produce(userProjects, draft => {
				draft.uncs[ind] = Object.assign(draft.uncs[ind], uncElem)
			})
			yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
			yield put({ type: 'FETCH_USER_PROJECT', id: uncElem.userProjectId })
		}
	} catch (error) {
		console.log(error)
	}
}
//Сага изменения напряжения расценки
function* changeUncVoltage({
	id,
	voltage,
	inBuffer,
}: {
	type: typeof UserArchivesType.CHANGE_UNC_VOLTAGE;
	id: string;
	voltage: number;
	inBuffer?: boolean;
}) {
	try {
		const uncElem: IUnc = yield call(
			fetchData,
			`/user-uncs/${id}`,
			(res: any) => res.userUnc,
			'PATCH',
			JSON.stringify({ voltage })
		)
		if (uncElem) {
			const userProjects = store.getState().userProjects

			if (inBuffer) {
				const ind = userProjects.uncAddBuffer.findIndex(elem => elem.id === id)
				const copyBuffer = produce(userProjects, draft => {
					draft.uncAddBuffer[ind] = Object.assign(draft.uncAddBuffer[ind], uncElem)
				})

				yield put({ type: 'SET_UNC_ADD_BUFFER', buffer: copyBuffer.uncAddBuffer })
			} else {
				const ind = userProjects.uncs.findIndex((elem: any) => elem.id === uncElem.id)
				const clone = produce(userProjects, draft => {
					draft.uncs[ind] = Object.assign(draft.uncs[ind], uncElem)
				})
				yield put({ type: 'SET_PROJECT_UNCS', uncs: clone.uncs })
			}
		}
	} catch (error) {
		console.log(error)
	}
}

function* selectVoltage({
	uncCellId,
	uncId,
}: {
	type: typeof UserArchivesType.SELECT_VOLTAGE;
	uncCellId: ReactText;
	uncId: ReactText;
}) {
	try {
		const voltage: IUncCell = yield call(
			fetchData,
			`/uncCells/${uncCellId}?with=voltageValues`,
			(res: any) => res.uncCell
		)

		yield put({
			type: 'SET_VOLTAGE',
			uncId,
			targetVoltageValues: voltage.voltageValues,
		})

		yield put({ type: 'SET_VOLTAGE_ID', uncId, targetVoltageId: uncId })
	} catch (error) {
		console.log(error)
	}
}

function* updateDeflators({
	start,
	end,
}: {
	type: typeof UserArchivesType.UPDATE_DEFLATORS;
	start: ReactText;
	end: ReactText;
}) {
	try {
		const deflators: { [key: string]: string } = yield call(
			fetchData,
			`/info/deflators?year.min=${start}&year.max=${end}`,
			(res: any) => res.deflators
		)

		if (deflators) {
			const originalObj: any = Object.assign({}, store.getState().userProjects.originalObj),
				editObj = Object.assign({}, store.getState().userProjects.projectObj)
			const years: any = {}
			for (let i = +start; i <= +end; i++) {
				years[i] = {
					offer: null,
					deflator: null,
				}
				if (originalObj[i]) {
					years[i].offer = originalObj[i].offer
					years[i].deflator = originalObj[i].deflator
				} else {
					years[i].offer = null
					years[i].deflator = deflators[i]
				}
			}
			editObj.years = years
			yield put({ type: 'CHOOSE_PROJECT', project: editObj })
		}
	} catch (error) {
		console.log(error)
	}
}

function* getCheckedErrors({
	id,
	sectionIdsArr,
}: {
	type: typeof UserArchivesType.GET_CHECKED_ERRORS;
	id: ReactText | undefined;
	sectionIdsArr: number[];
}) {
	try {
		const errors: ICheckedErrors = yield call(
			fetchData,
			`/user-projects/${id}/rule-error?with=messages`,
			(res: any) => res.userRuleError,
			'POST',
			JSON.stringify({ sectionIds: sectionIdsArr })
		)

		if (errors) {
			yield put({
				type: 'SET_PROJECT_ERRORS',
				errors: errors.messages,
			})
		} else {
			yield put({ type: 'SET_PROJECT_ERRORS', errors: '0' })
		}

		yield put({ type: 'SHOW_PROJECT_ERRORS', show: true })
	} catch (error) {
		console.log(error)
	}
}

function* downloadRulesCheck() {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/rules`, {
			method: 'GET',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(res => res.blob())
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = 'Правила проверки.xlsx'
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}

function* uploaderRulesCheck(file: any) {
	try {
		const target = file.file[0]
		const fileTypes = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
		if (!fileTypes.includes(target.type)) {
			yield put(showNotify({ type: 'warning', message: `Неверный формат файла! Допустимый формат - *.xlsx` }))
			return
		}

		const data = new FormData()
		data.append('file', target, target.name)

		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/rules`, {
			method: 'POST',
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(response => response.json())
			.then(success => {
				if (success.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: 'Ошибка загрузки файла, проверьте файл или попробуйте позже.',
						})
					)
				} else {
					store.dispatch(
						showNotify({
							type: 'success',
							message: 'Файл успешно загружен!',
						})
					)
				}
			})
			.catch(error => {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
			})
	} catch (error) {
		yield put(
			showNotify({
				type: 'error',
				message: 'Ошибка загрузки файла, попробуйте позже.',
			})
		)
	}
}

function* downloadProject({
	id,
	name,
	version,
}: {
	type: typeof UserArchivesType.DOWNLOAD_PROJECT;
	id: ReactText;
	name: string;
	version: string;
}) {
	let url = ''
	switch (version) {
		case 'base':
			// Старая Форма20 с одним проектом
			url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/f20`
			break
		case 'new':
			// Новая Форма20 с одним проектом
			url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/newf20`
			break
		case 'all':
			// Новая Форма20 со всеми проектами
			url = `https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/forma20`
			break
		default:
			url = ''
	}

	try {
		yield fetch(url, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => res.blob())
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `${name}.xlsx`
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}

function* copyProject({
	userProjectId,
	code,
}: {
	type: typeof UserArchivesType.COPY_PROJECT;
	userProjectId: ReactText;
	code: ReactText;
}) {
	try {
		const project: IRequestProject = yield call(
			fetchData,
			`/user-projects`,
			(res: any) => res,
			'POST',
			JSON.stringify({ userProjectId, code })
		)

		if (project.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: `Создан новый проект ${code}`,
				})
			)
		}
	} catch (error) {
		console.log(error)
	}
}

function* getTplUnnormalizedСosts({
	id,
}: {
	type: typeof UserArchivesType.GET_TEMPLATE_UNNORMOLIZED_COSTS;
	id: ReactText;
}) {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/t7-tpl`, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => res.blob())
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `Шаблон ненормируемых затрат.xlsx`
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}

function* getUnnormalizedСosts({ id }: { type: typeof UserArchivesType.GET_UNNORMOLIZED_COSTS; id: ReactText }) {
	try {
		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/t7`, {
			method: 'GET',
			headers: fetchHeaders(),
		})
			.then(res => res.blob())
			.then(blob => {
				const url = URL.createObjectURL(blob),
					link = document.createElement('a')
				document.body.appendChild(link)
				link.href = url
				link.download = `Шаблон ненормируемых затрат.xlsx`
				link.click()
				link.remove()
			})
	} catch (error) {
		console.log(error)
	}
}
function* uploaderUnnormalizedСosts({
	file,
	id,
}: {
	type: typeof UserArchivesType.UPLOAD_UNNORMOLIZED_COSTS;
	file: any;
	id: ReactText;
}) {
	try {
		const fileTypes = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
		if (!fileTypes.includes(file[0].type)) {
			yield put(
				showNotify({
					type: 'warning',
					message: 'Неверный формат файла! Допустимый формат - *.xlsx',
				})
			)
			return
		}

		const data = new FormData()
		data.append('file', file[0], file[0].name)

		yield fetch(`https://${process.env.REACT_APP_ENV}/ptk/v2/user-projects/${id}/t7`, {
			method: 'POST',
			body: data,
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${localStorage.getItem('access_token')}`,
			},
		})
			.then(response => response.json())
			.then(success => {
				if (success.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: 'Ошибка загрузки файла, попробуйте позже.',
						})
					)
				} else {
					store.dispatch(
						showNotify({
							type: 'success',
							message: 'Файл успешно загружен!',
						})
					)
				}
			})
			.catch(error => {
				store.dispatch(
					showNotify({
						type: 'error',
						message: 'Ошибка загрузки файла, попробуйте позже.',
					})
				)
			})
	} catch (error) {
		console.log(error)
	}
}

function* deleteUnnormalizedСosts({ id }: { type: typeof UserArchivesType.DELETE_UNNORMOLIZED_COSTS; id: ReactText }) {
	try {
		const res: IRequestProject = yield call(fetchData, `/user-projects/${id}/t7`, (res: any) => res, 'DELETE')

		if (res.status === 'success') {
			yield put(
				showNotify({
					type: 'success',
					message: 'Файл успешно удален!',
				})
			)
		}
	} catch (error) {
		console.log(error)
	}
}
function* sortUserProjects({
	order,
	sortName,
}: {
	type: typeof UserArchivesType.SORT_USER_PROJECTS;
	sortName: string;
	order: boolean;
	archive: any;
}) {
	try {
		yield put(loadingUserProjectsArchive(true))
		yield put(setUserProjectsArchive([], 0, 0))
		let params = { order: (order ? '' : '-') + sortName, archive: true, limit: limit }

		const { userArchive } = store.getState().filter
		const object = excludeData(userArchive.showFilterState)

		const intermediateValue = {
			...object,
		}

		if (userArchive.showFilterState || !!Object.keys(intermediateValue).length) {
			params = {
				...params,
				...intermediateValue,
			}
		}

		const res = yield call(userArchivesApi, params)

		if (res && res.status === 'success') {
			yield put(setUserProjectsArchive(res.userProjects, Number(res.totalInvestmentUncOfferSum), res.userProjectsCnt))
		}
	} catch (error) {
		console.log(error)
	} finally {
		yield put(loadingUserProjectsArchive(false))
	}
}

function* appendUserProjectsArchive({
	projectsCount,
}: {
	type: typeof UserArchivesType.APPEND_USER_PROJECTS;
	projectsCount: number;
}) {
	try {
		yield put(loadingUserProjectsArchive(true))
		const { sort, allowMoreUserProjects } = store.getState().userArchives

		if (!allowMoreUserProjects) return

		let params = { order: (sort.order ? '' : '-') + sort.sortName, limit: limit, offset: projectsCount * limit }

		const { userArchive } = store.getState().filter
		const object = excludeData(userArchive.showFilterState)

		const intermediateValue = {
			...object,
		}

		if (userArchive.showFilterState) {
			params = {
				...params,
				...intermediateValue,
			}
		}

		const res = yield call(userArchivesApi, params)

		if (res && res.status === 'success') {
			const projects = res.userProjects

			if (res.userProjects.length) {
				yield put(addUserProjectsArchive(projects))
				yield put(setUserProjectsPaginationCountArchive(projectsCount))
			} else {
				yield put(setAllowMoreUserProjectsArchive(false))
			}

			if (!(res.userProjects.length || res.userProjects.length > limit)) {
				yield put(setAllowMoreUserProjectsArchive(false))
				yield put(setUserProjectsPaginationCountArchive(0))
			}
		}
	} catch (error) {
		console.log(error)
	} finally {
		yield put(loadingUserProjectsArchive(false))
	}
}

export default function* watchUserProject() {
	yield takeEvery(UserArchivesType.DELETE_UNNORMOLIZED_COSTS, deleteUnnormalizedСosts)
	yield takeEvery(UserArchivesType.UPLOAD_UNNORMOLIZED_COSTS, uploaderUnnormalizedСosts)
	yield takeEvery(UserArchivesType.APPEND_USER_PROJECTS, appendUserProjectsArchive)
	yield takeEvery(UserArchivesType.GET_UNNORMOLIZED_COSTS, getUnnormalizedСosts)
	yield takeEvery(UserArchivesType.SORT_USER_PROJECTS, sortUserProjects)
	yield takeEvery(UserArchivesType.COPY_PROJECT, copyProject)
	yield takeEvery(UserArchivesType.GET_TEMPLATE_UNNORMOLIZED_COSTS, getTplUnnormalizedСosts)
	yield takeEvery(UserArchivesType.DOWNLOAD_PROJECT, downloadProject)
	yield takeEvery(UserArchivesType.UPLOADER_RULES_CHECK, uploaderRulesCheck)
	yield takeEvery(UserArchivesType.DOWNLOAD_RULES_CHECK, downloadRulesCheck)
	yield takeEvery(UserArchivesType.GET_CHECKED_ERRORS, getCheckedErrors)
	yield takeLatest(UserArchivesType.UPDATE_DEFLATORS, updateDeflators)
	yield takeEvery(UserArchivesType.SELECT_VOLTAGE, selectVoltage)
	yield takeEvery(UserArchivesType.CHANGE_UNC_VOLTAGE, changeUncVoltage)
	yield takeEvery(UserArchivesType.CHANGE_UNC_REGION, changeUncRegion)
	yield takeEvery(UserArchivesType.CHANGE_UNC_COUNTER, changeUncCounter)
	yield takeEvery(UserArchivesType.CHANGE_UNC_COMMENT, changeUncComment)
	yield takeLatest(UserArchivesType.FETCH_UNC_TABLE_DATA, fetchUncTableData)
	yield takeLatest(UserArchivesType.FETCH_UNCS, fetchUncs)
	yield takeLatest(UserArchivesType.GET_T7_DATA, getT7Data)
	// yield takeLatest(UserArchivesType.GET_USER_PROJECTS, getMyProjects)
	// 	yield takeEvery(UserArchivesType.APPEND_UNC_SEARCH, appendPricesSearch)
	// 	yield takeLatest(UserArchivesType.FETCH_MINI_TABLE, fetchMiniTable)
	// yield takeLatest(UserArchivesType.FETCH_COST_TABLE, fetchCostTable)
	yield takeLatest(UserArchivesType.CHANGE_FORM_TWENTY_NAME, changeFormTwentyName)
	yield takeLatest(UserArchivesType.CHANGE_REGION_FACTOR, changeRegionFactor)
	yield takeEvery(UserArchivesType.DELETE_UNC, deleteUnc)
	// yield takeLatest(UserArchivesType.FETCH_MINI_TABLE, fetchMiniTable)
	yield takeEvery(UserArchivesType.CREATE_SPECIAL_UNC, createSpecialUnc)
	yield takeEvery(UserArchivesType.CREATE_UNC_B1, createUncB1)
	yield takeLatest(UserArchivesType.ADD_UNC, addUnc)
	yield takeLatest(UserArchivesType.UNC_POSITION_EDIT, uncEdit)
	yield takeLatest(UserArchivesType.SAVE_USER_PROJECT, saveUserProject)
	yield takeLatest(UserArchivesType.FETCH_USER_PROJECT, fetchUserProject)
	yield takeLatest(UserArchivesType.DELETE_USER_PROJECT, deleteUserProject)
}
