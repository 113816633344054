import { IUnc, IUserProject } from '@root-gipro/store/interfaces'

export const createUncArr = (tableNum: number, uncs: IUnc[]) => {
	let arr: IUnc[] = []
	if (uncs && uncs.length > 0) {
		if (tableNum === 6) {
			arr = uncs
		} else {
			arr = uncs.filter((unc: IUnc) => unc.tableNum === tableNum)
		}
	}

	return arr
}

export function addActiveUnc(activeUncs: IUnc[], unc: IUnc) {
	const index = activeUncs.findIndex(active => active.id === unc.id),
		clone = activeUncs.slice()
	if (index === -1) {
		clone.push(unc)
	} else {
		clone.splice(index, 1)
	}
	return { clone, index }
}

export function getTargetVoltageValues(buffer: any, id: any) {
	const index = buffer.findIndex((item: any) => {
		return item.id == id
	})
	if (index >= 0) {
		return buffer[index]['targetVoltageValues']
	}
}
export const filterWidthWindow = (step: string | undefined) => {
	switch (step) {
		case 'error-list':
			return '768px'
		case 'errors-list':
			return '768px'
		case 'check-list':
			return '240px'
		case 'unNormalizeForm20Instructions':
			return '1232px'
		case 'open-table-form20':
			return '100%'
		default:
			break
	}
}

export const ROSSETI_ID = 10

export const importRules = `Правила использования:
Импорт Ф2
Имя файла должно быть: что угодно-F2_20.12.2023.xlsx, где 20.12.2023 - дата публикации на сайте Минэнерго.
Структура xlsx файла должна соответствовать шаблону формы с сайта Минэнерго.
Название компании в Форме должно совпадать с названием компании в БД. Можно проверить в фильтре.
В формулах не должно быть ссылок на связанные источники данных (другие листы или файлы). Если есть - можно скопировать только данные в новый файл.
Импорт Ф20
Имя файла должно соответствовать формату Минэнерго (содержать ОГРН).
Структура xlsx файла должна соответствовать шаблону формы с сайта Минэнерго.
Форма должна содержать корректные наименования и стоимость расценок.
Импорт не проверяет корректность заполнения формы.

После импорта создаются проекты на странице Проекты. Для расчета по УНЦ их необходимо перенести на страницу Расчеты (по одному через меню ... в карточке проекта)`

export const requiredFields: Array<{ field: keyof Omit<IUserProject, 'author'>; message: string }> = [
	{ field: 'code', message: 'поле «Идентификатор»' },
	{ field: 'name', message: 'поле «Наименование инвестиционного объекта»' },
	{ field: 'regionIds', message: 'поле «Субъект РФ»' },
	{ field: 'unc_sort', message: 'режим УНЦ 2019/2024' },
]
