import { Backdrop, Modal } from '@material-ui/core/'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { setT7EditModeArchive } from '@root-gipro/modules/userArchive/store/actions'
import {
	saveT7Data,
	saveUserProject,
	setProjectAdditional,
	setT7EditMode,
} from '@root-gipro/modules/userProjects/store/actions'
import '@root-gipro/modules/userProjects/styles/T7.scss'
import { PrimaryBtn, SecondaryBtn } from '@root-gipro/shared/Button/Button'
import Loader from '@root-gipro/shared/Loader/Loader'
import { IT7Row } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface IUnNormalizedCostsProps {
	anchorEl: (EventTarget & HTMLElement) | null;
	setAnchorEl(event: HTMLElement): void;
	t7EditMode: boolean;
	isArchived: boolean;
	isLocked: boolean;
}

const UnNormalizedCosts: React.FC<IUnNormalizedCostsProps> = ({
	anchorEl,
	setAnchorEl,
	t7EditMode,
	isArchived,
	isLocked,
}) => {
	const { projectObj, t7Data, loadingT7Data, t7Version } = useSelector((state: IState) => state.userProjects)
	const dispatch = useDispatch()

	const [t7Rows, setT7Rows] = useState(t7Data)

	useEffect(() => {
		setT7Rows(t7Data)
	}, [t7Data])

	const cancelHandler = () => {
		dispatch({ type: 'SET_T7_DATA', t7Data: [] })

		isArchived ? dispatch(setT7EditModeArchive(false)) : dispatch(setT7EditMode(false))
	}

	const calcSum = () => {
		let sum = 0
		t7Rows.forEach(function (item: IT7Row) {
			sum += item.costs * 1
		})
		return sum
	}
	const readyHandler = () => {
		// console.log({t7Version})
		if (t7Version !== 2) {
			dispatch(setProjectAdditional(calcSum()))

			const clone = Object.assign({}, projectObj)
			clone.additionalFundingOffer = calcSum()
			delete clone.id
			delete clone.author
			// delete clone.userId
			dispatch(saveUserProject(clone, false, projectObj.id))
		}

		dispatch(saveT7Data(t7Rows, projectObj.id!, 1))
		dispatch(setT7EditMode(false))
	}

	const changeHandle = (event: any, t7row: IT7Row) => {
		const fieldName: string = event.target.getAttribute('name')
		const fieldValue = event.target.value

		const t7Row: IT7Row = {
			group: t7row.group,
			name: t7row.name,
			costs: fieldName === 'costs' ? fieldValue * 10 ** 3 : t7row.costs,
			source: fieldName === 'source' ? fieldValue : t7row.source,
			document: fieldName === 'document' ? fieldValue : t7row.document,
		}

		const clone = t7Rows.slice()
		const newRows = clone.map((item: any) => {
			return item.group === t7row.group ? t7Row : item
		})

		setT7Rows(newRows)
	}

	return (
		<Modal
			disableBackdropClick
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={t7EditMode}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<div className='unc_add_wrapper unnormalizedCostsOld_wrapper'>
				<div className='scroll-wrapper'>
					<div className='unc_add unc_add_container'>
						<div className='scrollarea1' style={{ height: '570px', margin: 'auto' }}>
							<div className='unormcsts_panel'>
								<div style={{ display: 'flex ', justifyContent: 'end', alignItems: 'center' }}>
									<IconButton onClick={cancelHandler}>
										<CloseIcon />
									</IconButton>
								</div>
								<div className='t7title'>
									Таблица 7. Определение объема финансовых потребностей для мероприятий ненормируемых УНЦ согласно
									Постановлению Правительства Российской Федерации от 12.11.2016 № 1157 &ldquo;О внесении изменений в
									некоторые акты Правительства Российской Федерации по вопросам ценообразования в области регулируемых
									цен (тарифов) в электроэнергетике&ldquo;
								</div>
								{loadingT7Data ? (
									<Loader />
								) : t7Rows.length > 0 ? (
									<div className='divTable unnormalizedCostsOld'>
										<div className='divTableBody'>
											<div className='divTableRow'>
												<div className='divTableHead'>№ п/п</div>
												<div className='divTableHead'>Наименование</div>
												<div className='divTableHead'>Величина затрат с учетом налогов и сборов, тыс рублей</div>
												<div className='divTableHead'>Источник ценовой информации</div>
												<div className='divTableHead'>
													Реквизиты документа, согласно которому утверждены указанные затраты
												</div>
											</div>

											{t7Rows.map((t7row: any, index: number) => (
												<div className='divTableRow' key={index}>
													<div className='divTableCellCenter' style={{ width: '20px' }}>
														{t7row.group}
													</div>
													<div className='divTableCell'>{t7row.name}</div>
													<div className='divTableCellCenter'>
														<input
															type='text'
															disabled={isArchived || isLocked}
															className='unormcsts_input'
															name='costs'
															style={{ textAlign: 'center' }}
															value={t7row.costs / 10 ** 3}
															onChange={event => changeHandle(event, t7row)}
														/>
													</div>
													<div className='divTableCellCenter'>
														<input
															type='text'
															name='source'
															disabled={isArchived || isLocked}
															className='unormcsts_input'
															value={t7row.source}
															onChange={event => changeHandle(event, t7row)}
														/>
													</div>
													<div className='divTableCellCenter'>
														<input
															type='text'
															name='document'
															className='unormcsts_input'
															disabled={isArchived || isLocked}
															value={t7row.document}
															onChange={event => changeHandle(event, t7row)}
														/>
													</div>
												</div>
											))}

											<div className='divTableRow'>
												<div className='divTableCellCenter'>15</div>
												<div className='divTableCell'>
													Итого объем финансовых потребностей, тыс. рублей (с налогами и сборами)
												</div>
												<div className='divTableCellCenter'>{calcSum() / 10 ** 3}</div>
												<div className='divTableCellCenter'>-</div>
												<div className='divTableCellCenter'>-</div>
											</div>
										</div>
									</div>
								) : (
									<div style={{ color: 'red', textAlign: 'center' }}>Необходимо загрузить заполненный шаблон!</div>
								)}

								{!isArchived ? (
									<div className='unnormalized__footer'>
										<div className='action_btns mt-8'>
											{t7Rows.length > 0 && (
												<PrimaryBtn variant='contained' onClick={readyHandler}>
													Сохранить
												</PrimaryBtn>
											)}
											<SecondaryBtn variant='contained' onClick={cancelHandler} className='rej_btn'>
												Отмена
											</SecondaryBtn>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default UnNormalizedCosts
