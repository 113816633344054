import { Grid } from '@material-ui/core'
import { roleIdByAlias } from '@root-gipro/modules/admin/models/roles'
import style from '@root-gipro/modules/header/styles/HeaderNav.module.scss'
import '@root-gipro/modules/header/styles/HeaderNavSubmenu.scss'
import { Routes } from '@root-gipro/router'
import React, { memo, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const HeaderNav: React.FC = memo(() => {
	const [roleID, setRoleID] = useState(Number(window.localStorage.getItem('roleId')))
	const [showAdminPanel, setShowAdminPanel] = useState(false)

	setTimeout(() => {
		setRoleID(Number(window.localStorage.getItem('roleId')))
	}, 1000)

	useEffect(() => {
		if (roleID !== null) {
			setShowAdminPanel(roleID == 1 || roleID == 3)
		}
	}, [roleID])

	return (
		<Grid>
			<NavLink to={Routes.COMMON_PROJECTS} activeClassName={style.active} className={style.navLink}>
				Проекты
			</NavLink>
			<NavLink to={Routes.USER_PROJECTS} activeClassName={style.active} className={style.navLink}>
				Расчеты
			</NavLink>
			<NavLink to={Routes.USER_ARCHIVE} activeClassName={style.active} className={style.navLink}>
				Архив
			</NavLink>
			{showAdminPanel ? (
				<NavLink to={Routes.ADMIN} activeClassName={style.active} className={style.navLink}>
					Администрирование
				</NavLink>
			) : null}
		</Grid>
	)
})

export default HeaderNav
