import React, { useEffect, useState } from 'react'

import { Tab, Tabs } from '@material-ui/core'
import { tbodyArr } from '@root-gipro/modules/userProjects/models/consts/unc-tbody.const'
import { IUnc } from '@root-gipro/store/interfaces'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { useDispatch } from 'react-redux'
import { changeUncCheckedTable } from '@root-gipro/modules/userProjects/store/actions'
import { Droppable } from 'react-beautiful-dnd'

export interface TableTabsProps {
	uncs: any

	uncCheckedTable: number
}
type TTotalPrice = {
	[key: string]: number
	tableNum: number
	tableTotal: number
}
type TTableTitles = {
	[key: string]: string | number
	title: string
	tableNum: number
	totalPrice: number
}

const calcSum = (arr: IUnc[]) => {
	return arr.length > 0 ? arr.reduce((accum: number, curr: IUnc) => (accum += curr.totalCost), 0) : 0
}

const TableTabs: React.FC<TableTabsProps> = ({ uncs, uncCheckedTable }) => {
	const dispatch = useDispatch()
	const createTableTitles: TTableTitles[] = tbodyArr
		.filter(item => item.sitePreparation)
		.map(item => ({ title: item.titleShort, tableNum: item.tableNum, totalPrice: 0 }))

	const [tableTitles, setTableTitles] = useState<TTableTitles[]>(createTableTitles)

	const updateTotalPrice = (tableNum: number, totalPrice: number) => {
		setTableTitles((prev: TTableTitles[]) =>
			prev.map((item: TTableTitles) => {
				if (item.tableNum === tableNum) {
					return { ...item, tableNum: item.tableNum, tableTotal: totalPrice }
				} else {
					return item
				}
			})
		)
	}

	useEffect(() => {
		tableTitles.forEach(item => {
			if (item.tableNum !== 6 && item.tableNum !== 8) {
				const filteredUncs = uncs.filter((unc: IUnc) => unc.tableNum === item.tableNum)
				const total = calcSum(filteredUncs)
				updateTotalPrice(item.tableNum, total)
			}

			if (item.tableNum === 8) {
				const allTotal = calcSum(uncs.filter((unc: IUnc) => unc.uncMain.code === 'С1'))
				updateTotalPrice(item.tableNum, allTotal)
			}

			if (item.tableNum === 6) {
				const allTotal = calcSum(uncs.filter((unc: IUnc) => unc.uncMain.code !== 'С1'))
				updateTotalPrice(item.tableNum, allTotal)
			}
		})
	}, [uncs])

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		dispatch(changeUncCheckedTable(newValue))
	}

	const checkDis = (value: number) => {
		if (value === 8) {
			return true
		} else if (value <= 5) {
			return false
		}
		return true
	}

	return (
		<Tabs value={uncCheckedTable} onChange={handleChange} textColor='primary' className='toggler'>
			{tableTitles.map((item: TTableTitles, ind) => (
				<Tab
					label={
						<Droppable
							key={item.tableNum}
							droppableId={`tableTabs ${item.tableNum}`}
							direction='horizontal'
							isDropDisabled={checkDis(item.tableNum)}
						>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									key={item.tableNum}
									{...provided.droppableProps}
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										height: '100%',
										gap: '22px',
										transform: snapshot.isDraggingOver ? 'scale(1.1, 1.1)' : '',
										color: snapshot.isDraggingOver ? 'gray' : '',
									}}
								>
									<span>{item.title}</span>
									<div>
										<span style={{ color: item.tableTotal ? '#000000' : '#c3c3c3' }}>
											{prettyCost((item.tableTotal as number) / 1000)}
										</span>

										{item.tableNum === 8 ? (
											<span className='currency'> м²</span>
										) : (
											<span className='currency'> тыс&nbsp;₽</span>
										)}
									</div>
								</div>
							)}
						</Droppable>
					}
					value={item.tableNum}
					key={item.tableNum}
					style={{ width: '150px', padding: '12px' }}
				/>
			))}
		</Tabs>
	)
}

export default TableTabs
