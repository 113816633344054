import React, { useEffect } from 'react'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import AdminCompaniesTable from './components/AdminCompaniesTable'
import Add from '@material-ui/icons/Add'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import Loader from '@root-gipro/shared/Loader/Loader'
import AdminCompanyDetails from './components/AdminCompanyDetails/AdminCompanyDetails'
import { getCompanyGroupsFetch, setCompanyState, setOpenModalCreateCompany } from '../../store/actions'
import { ROLE_OWNER } from '../../store/helpers'

const AdminPanelCompany = () => {
	const { openModalCompany, companies } = useSelector((state: IState) => state.adminPanel)
	const dispatch = useDispatch()
	const roleId = localStorage.getItem('roleId')

	useEffect(() => {
		dispatch(getCompanyGroupsFetch())
	}, [])

	const handleClose = () => {
		dispatch(setOpenModalCreateCompany({open: false}))
	}

	const handleBtn = () => {
		dispatch(setOpenModalCreateCompany({open: true, mode: "createCompany"}))
		dispatch(setCompanyState(null))
	}

	return (
		<div className='adminPanel-container'>
			{roleId && Number(roleId) <= ROLE_OWNER ? (
				<SecondaryBtn
					className='add_btn'
					onClick={() => handleBtn()}
					style={{ marginBottom: 15, padding: '4px 8px' }}
				>
					<Add style={{ marginRight: 8 }} />
					Добавить компанию
				</SecondaryBtn>
			) : null}
			{companies?.length ? <AdminCompaniesTable companies={companies} /> : <Loader />}
			<AdminCompanyDetails open={openModalCompany.open} handleClose={handleClose} action={openModalCompany.mode} />
		</div>
	)
}

export default AdminPanelCompany
