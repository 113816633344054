import { IconButton } from '@material-ui/core'
import { ModalContext } from '@root-gipro/context/modal-context'
import { RatesOptions, Сomment } from '@root-gipro/modules/commonProjects/components/SelectProjects/DropDown/'
import { cellData, titleData } from '@root-gipro/modules/commonProjects/models/const/rates-table-body-cell'
import { gridTemplateColumns, theadData } from '@root-gipro/modules/commonProjects/models/const/rates-table-thead.const'
import '@root-gipro/modules/commonProjects/styles/Rates.scss'
import { TableContainer } from '@root-gipro/modules/userProjects/components/Project/UncTable/TableContainer'
import { TableType } from '@root-gipro/shared/Table/components/Tbody'
import { ICommonRates, IUnc } from '@root-gipro/store/interfaces'
import { copyHrefToClipboard } from '@root-gipro/utils/helpers/copyToClipBoard'
import React, { MouseEvent, useCallback, useContext, useMemo, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

const Rates: React.FC<ICommonRates> = ({
	uncs,
	getUncsCommonProject,
	selectedProject,
	downloadCommonProject,
	addToUserProject,
	requestRgStatuses,
	policesRGCO,
	policesGipro,
	rgStatuses,
	idAddedUserProject,
}) => {
	const [filteredRates, setFilteredRates] = useState<IUnc[] | null>(null)
	const { setAnchorEl, anchorEl, setOpen, open } = useContext(ModalContext)
	const [comment, setComment] = useState<string>('')
	const [anchorElCom, setAnchorElCom] = useState<HTMLElement | null>(null)

	const handleShowComment = (event: MouseEvent<HTMLElement>, comment: string) => {
		setComment(comment)
		setAnchorElCom(event.currentTarget)
	}
	const onDragEnd = useCallback(params => {
		return undefined
	}, [])

	const tableObj = useMemo(() => {
		const obj: TableType = {
			1: [],
			2: [],
			3: [],
			4: [],
			5: [],
			8: [],
		}
		if (filteredRates && filteredRates.length > 0) {
			filteredRates.forEach((unc: IUnc): void => {
				const num: number = unc.tableNum
				obj[num].push(unc)
			})
		} else if (uncs && uncs.length > 0) {
			uncs.forEach((unc: IUnc): void => {
				const num: number = unc.tableNum
				obj[num] && obj[num].push(unc)
			})
		}

		delete obj[8]

		return obj
	}, [uncs, filteredRates])

	const getNumberUnc = (id: string) => {
		const index = uncs && uncs.findIndex((uncElem: IUnc) => uncElem.id === id)
		return (index || index === 0) && index + 1
	}
	const getRgStatusId = () => {
		if (selectedProject) {
			requestRgStatuses(selectedProject.id)
		}
	}

	const theadParams = useMemo(() => {
		return {
			gridTemplateColumns,
			theadData,
		}
	}, [uncs])

	const bodyParams = useMemo(() => {
		return { gridTemplateColumns, getNumberUnc, tableObj, cellData, titleData, handleShowComment }
	}, [uncs, filteredRates])

	return (
		<>
			<div className='common-projects__rates container'>
				<div className='common-projects__rates-head'>
					<div className='title'>Расценки УНЦ</div>
					<div className='common-projects__rates-actions'>
						{uncs && !!uncs?.length && (
							<div className='rates-actions__filter'>
								{/* <IconButton
									type='button'
									data-popup='filter-popup'
									onClick={e => {
										setAnchorEl!(e.currentTarget)
										setOpen!(!open)
									}}
								>
									<span className='filter-icon' />
								</IconButton>
								{anchorEl?.dataset.popup === 'filter-popup' && (
									<RatesFilter
										uncs={uncs}
										setFilteredRates={setFilteredRates}
										setAnchorEl={setAnchorEl!}
										anchorEl={anchorEl}
									/>
								)} */}
								<IconButton type='button' data-popup='options-popup' onClick={e => setAnchorEl!(e.currentTarget)}>
									<span className='dots-icon' />
								</IconButton>
							</div>
						)}
						{selectedProject && anchorEl?.dataset.popup === 'options-popup' && (
							<RatesOptions
								anchorEl={anchorEl!}
								setAnchorEl={setAnchorEl!}
								policesGipro={policesGipro}
								policesRGCO={policesRGCO}
								copyHrefToClipboard={copyHrefToClipboard}
								downloadCommonProject={downloadCommonProject}
								addToUserProject={addToUserProject}
								getRgStatusId={getRgStatusId}
								rgStatuses={rgStatuses}
								project={selectedProject}
								rgStatusId={selectedProject.rgStatusId}
								idAddedUserProject={idAddedUserProject}
							/>
						)}
					</div>
				</div>
			</div>

			{uncs && (
				<DragDropContext onDragEnd={onDragEnd}>
					<TableContainer uncs={uncs} sitePreparation={false} />
				</DragDropContext>
			)}
			{anchorElCom?.dataset.popup === 'comment-popup' && (
				<Сomment comment={comment} anchorEl={anchorElCom!} setAnchorEl={setAnchorElCom} />
			)}
		</>
	)
}

export default Rates
