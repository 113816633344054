
const roles = [
                    {
                        "id": 1,
                        "title": "Суперадмин",
                        "alias": "ROLE_SUPERADMIN"
                    },
                    {
                        "id": 3,
                        "title": "МЦР",
                        "alias": "ROLE_DEVELOPER"
                    },
                    {
                        "id": 5,
                        "title": "Владелец",
                        "alias": "ROLE_OWNER"
                    },
                    {
                        "id": 10,
                        "title": "Администратор",
                        "alias": "ROLE_ADMIN"
                    },
                    {
                        "id": 100,
                        "title": "Сотрудник",
                        "alias": "ROLE_USER"
                    }
                ]

function roleIdByAlias(alias: string) {
    const role = roles.filter(r => r.alias===alias)[0]
    return (role) && role.id
}

function roleTitleById(id: number) {
    const role = roles.filter(r => r.id===id)[0]
    return (role) ? role.title : {}
}

export {roles, roleIdByAlias, roleTitleById}
