import { ReactText } from 'react'
import { IUnc, IUncTableElem, IUserProject } from '@root-gipro/store/interfaces'
import { UserArchivesType } from '@root-gipro/modules/userArchive/models/interfaces/user-project.actions'

export const fetchRequestArchive = () => ({
	type: UserArchivesType.FETCH_REQUEST,
})
export const createProjectTemplateArchive = () => ({
	type: UserArchivesType.CREATE_PROJECT_TEMPLATE,
})

export const fetchUserProjectArchive = (id: string) => ({
	type: UserArchivesType.FETCH_USER_PROJECT,
	id,
})

export const saveUserProjectArchive = (body: IUserProject, isNew: boolean, id?: ReactText) => ({
	type: UserArchivesType.SAVE_USER_PROJECT,
	body,
	isNew,
	id,
})

export const deleteUserProjectArchive = (id: string, name: string) => ({
	type: UserArchivesType.DELETE_USER_PROJECT,
	id,
	name,
})

export const chooseProjectArchive = (project: IUserProject) => ({
	type: UserArchivesType.CHOOSE_PROJECT,
	project,
})

export const setOriginalArchive = (project: IUserProject) => ({
	type: UserArchivesType.SET_ORIGINAL_OBJ,
	project,
})

export const setToggleTabsArchive = (show: boolean) => ({
	type: UserArchivesType.SET_TOGGLE_TABS,
	show,
})

export const setUncEditModeArchive = (mode: boolean) => ({
	type: UserArchivesType.SET_UNC_EDIT_MODE,
	mode,
})

export const setT7EditModeArchive = (mode: boolean) => ({
	type: UserArchivesType.SET_T7_EDIT_MODE,
	mode,
})

export const setActiveUncsArchive = (uncs: IUnc[]) => ({
	type: UserArchivesType.SET_ACTIVE_UNCS,
	uncs,
})

export const getProjectsArchive = () => ({
	type: UserArchivesType.GET_USER_PROJECTS,
})

// used SelectedContainer in common-project
export const fetchUncsArchive = (id: string) => ({
	type: UserArchivesType.FETCH_UNCS,
	id,
})
export const getT7DataArchive = (id: string, version: number) => ({
	type: UserArchivesType.GET_T7_DATA,
	id,
	version,
})

export const editPositionUncArchive = (params: any) => {
	return {
		type: UserArchivesType.UNC_POSITION_EDIT,
		params,
	}
}
export const setProjectUncsArchive = (uncs: IUnc[]) => ({
	type: UserArchivesType.SET_PROJECT_UNCS,
	uncs,
})

export const setIsNewProjectArchive = (isNew: boolean) => ({
	type: UserArchivesType.SET_IS_NEW_PROJECT,
	isNew,
})

export const setProjectCodeArchive = (code: string) => ({
	type: UserArchivesType.SET_PROJECT_СODE,
	code,
})

export const setProjectNameArchive = (name: string) => ({
	type: UserArchivesType.SET_PROJECT_NAME,
	name,
})

export const setProjectYearsArchive = (begin: ReactText, end: ReactText) => ({
	type: UserArchivesType.SET_YEARS,
	begin,
	end,
})

export const setProjectRegionsArchive = (regionIds: string[]) => ({
	type: UserArchivesType.SET_REGION_IDS,
	regionIds,
})

export const setProjectValuationArchive = (valuation: number) => ({
	type: UserArchivesType.SET_VALUATION_OFFER_FORECAST,
	valuation,
})

export const setProjectAdditionalArchive = (offer: number) => ({
	type: UserArchivesType.SET_ADDITIONAL_FUNDING_OFFER,
	offer,
})

export const setProjectRationaleArchive = (offerRationale: string) => ({
	type: UserArchivesType.SET_OFFER_RATIONALE,
	offerRationale,
})

export const setProjectActualArchive = (actualFunding: number) => ({
	type: UserArchivesType.SET_ACTUAL_FUNDING,
	actualFunding,
})

export const setProjectNdsArchive = (nds: number) => ({
	type: UserArchivesType.SET_NDS,
	nds,
})

export const setProjectGroupNumArchive = (groupNum: string) => ({
	type: UserArchivesType.SET_GROUP_TYPE,
	groupNum,
})

export const setProjectPhaseArchive = (phase: string) => ({
	type: UserArchivesType.SET_PHASE,
	phase,
})

export const setProjectDecreeNameArchive = (decreeName: string) => ({
	type: UserArchivesType.SET_DECREE_NAME,
	decreeName,
})

export const setProjectDecreeNumberArchive = (decreeNumber: string) => ({
	type: UserArchivesType.SET_DECREE_NUM,
	decreeNumber,
})

export const setProjectExceeding = (notExceeding: string) => ({
	type: UserArchivesType.SET_NOT_EXCEED,
	notExceeding,
})

export const fetchUncTableDataArchive = (id: number,unc_sort: string) => ({
	type: UserArchivesType.FETCH_UNC_TABLE_DATA,
	id,
	unc_sort
})

export const selectTableElemArchive = (selectedElem: IUncTableElem | null) => ({
	type: UserArchivesType.SELECT_TABLE_ELEM,
	selectedElem,
})

export const changeCommentArchive = (
	id: ReactText,
	objectName: string,
	stage: string,
	kf: number,
	description: string,
	comment: string
) => ({
	type: UserArchivesType.CHANGE_UNC_COMMENT,
	id,
	objectName,
	stage,
	kf,
	description,
	comment,
})

export const changeCounterArchive = (id: ReactText, count: string, inBuffer?: boolean | undefined) => ({
	type: UserArchivesType.CHANGE_UNC_COUNTER,
	id,
	count,
	inBuffer,
})

export const changeRegionArchive = (id: ReactText, regionId: ReactText) => ({
	type: UserArchivesType.CHANGE_UNC_REGION,
	id,
	regionId,
})

export const deleteUncArchive = (id: ReactText, inBuffer?: boolean) => ({
	type: UserArchivesType.DELETE_UNC,
	id,
	inBuffer,
})

export const clearBufferArchive = () => ({
	type: UserArchivesType.SET_UNC_ADD_BUFFER,
	buffer: [],
})

export const changeVoltageArchive = (id: string, voltage: number, inBuffer?: boolean) => ({
	type: UserArchivesType.CHANGE_UNC_VOLTAGE,
	id,
	voltage,
	inBuffer,
})
/// используется в разных компонентах
export const setFilterArchive = (filter: string) => ({
	type: UserArchivesType.SET_UNC_FILTER,
	filter,
})

export const updateDeflatorsArchive = (start: ReactText, end: ReactText) => ({
	type: UserArchivesType.UPDATE_DEFLATORS,
	start,
	end,
})

export const setYearsArchive = (years: {
	[key: string]: {
		offer: number | null;
		deflator: number | null;
	};
}) => ({
	type: UserArchivesType.SET_PROJECT_YEARS,
	years,
})

export const setInterpolationArchive = (obj: { [key: string]: ReactText }) => ({
	type: UserArchivesType.SET_INTERPOLATION,
	obj,
})

export const createSpecialUncArchive = (
	uncMainId: ReactText,
	regionId: ReactText,
	interpolated: { [key: string]: ReactText },
	tableNum: ReactText
) => ({
	type: UserArchivesType.CREATE_SPECIAL_UNC,
	uncMainId,
	regionId,
	interpolated,
	tableNum,
})
export const addUncArchive = (tableNum: ReactText, uncCellId: ReactText, count: number, addBuffer: boolean) => ({
	type: UserArchivesType.ADD_UNC,
	tableNum,
	uncCellId,
	count,
	addBuffer,
})
export const setProjectFilterArchive = (filter: string) => ({
	type: UserArchivesType.SET_PROJECT_FILTER,
	filter,
})

export const getCheckedErrorsArchive = (id: ReactText | undefined, sectionIdsArr: number[]) => ({
	type: UserArchivesType.GET_CHECKED_ERRORS,
	id,
	sectionIdsArr,
})

export const setProjectErrorsArchive = (errors: { [key: string]: string[] } | null) => ({
	type: UserArchivesType.SET_PROJECT_ERRORS,
	errors,
})

export const showProjectErrorsArchive = (show: boolean) => ({
	type: UserArchivesType.SHOW_PROJECT_ERRORS,
	show,
})

export const selectVoltageArchive = (uncCellId: ReactText, uncId: ReactText) => ({
	type: UserArchivesType.SELECT_VOLTAGE,
	uncCellId,
	uncId,
})

export const selectVoltageIdArchive = (targetVoltageId: ReactText) => ({
	type: UserArchivesType.SELECT_VOLTAGE_ID,
	targetVoltageId,
})

export const setUserProjectsArchive = (
	projects: IUserProject[],
	totalInvestmentUncOfferSum: number,
	userProjectsCnt: number
) => ({
	type: UserArchivesType.SET_USER_PROJECTS,
	projects,
	totalInvestmentUncOfferSum,
	userProjectsCnt,
})

export const appendUserProjectsArchive = (projectsCount: number) => ({
	type: UserArchivesType.APPEND_USER_PROJECTS,
	projectsCount,
})

export const addUserProjectsArchive = (projects: IUserProject[]) => ({
	type: UserArchivesType.ADD_USER_PROJECTS,
	projects,
})

export const setAllowMoreUserProjectsArchive = (allowMoreUserProjects: boolean) => ({
	type: UserArchivesType.SET_ALLOW_MORE_USER_PROJECTS,
	allowMoreUserProjects,
})

export const loadingUserProjectsArchive = (loading: boolean) => ({
	type: UserArchivesType.LOADING_USER_PROJECTS,
	loading,
})

export const loadingT7Data = (loading: boolean) => ({
	type: UserArchivesType.LOADING_T7DATA,
	loading,
})

export const setUserProjectsPaginationCountArchive = (paginationCounter: number) => ({
	type: UserArchivesType.SET_USER_PROJECTS_COUNT,
	paginationCounter,
})

export const setVoltageArchive = (targetVoltageValues: ReactText[]) => ({
	type: UserArchivesType.SET_VOLTAGE,
	targetVoltageValues,
})

export const downloadRulesCheckArchive = () => ({
	type: UserArchivesType.DOWNLOAD_RULES_CHECK,
})

export const uploaderRulesCheckArchive = (file: any) => ({
	type: UserArchivesType.UPLOADER_RULES_CHECK,
	file,
})

export const downloadProjectArchive = (id: ReactText | null, name: string | null, version: string) => ({
	type: UserArchivesType.DOWNLOAD_PROJECT,
	id,
	name,
	version,
})

export const copyProjectArchive = (userProjectId: ReactText, code: ReactText) => ({
	type: UserArchivesType.COPY_PROJECT,
	userProjectId,
	code,
})

export const addPrivateProjectArchive = (projectId: ReactText) => ({
	type: UserArchivesType.ADD_PRIVATE_PROJECT,
	projectId,
})

export const getTplUnnormalizedСostsArchive = (id: ReactText) => ({
	type: UserArchivesType.GET_TEMPLATE_UNNORMOLIZED_COSTS,
	id,
})

export const getUnnormalizedСostsArchive = (id: ReactText) => ({
	type: UserArchivesType.GET_UNNORMOLIZED_COSTS,
	id,
})

export const uploaderUnnormalizedСostsArchive = (file: any, id: ReactText) => ({
	type: UserArchivesType.UPLOAD_UNNORMOLIZED_COSTS,
	file,
	id,
})

export const deleteUnnormalizedСostsArchive = (id: ReactText) => ({
	type: UserArchivesType.DELETE_UNNORMOLIZED_COSTS,
	id,
})

export const sortUserProjectsArchive = (name: string, sortName: string, order: boolean) => ({
	type: UserArchivesType.SORT_USER_PROJECTS,
	name,
	sortName,
	order,
})

export const createUncB1Archive = (
	uncMainId: number,
	regionId: ReactText,
	interpolated: number,
	tableNum: ReactText,
	count: number
) => ({
	type: UserArchivesType.CREATE_UNC_B1,
	uncMainId,
	regionId,
	interpolated,
	tableNum,
	count,
})
