import { ITableNames } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'

export const tableNames: ITableNames = {
	'1': 'Строительство ПС',
	'2': 'Реконструкция ПС',
	'3': 'Строительство КТП, РП 10(6) кВ',
	'4': 'Строительство (реконструкция) ВЛ 6-750 кВ',
	'5': 'Строительство (реконструкция) КЛ 6-500 кВ',
	'8': 'Расчет площади С1',
}
