import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded'
import { getAuthUserToken } from '@root-gipro/modules/AdminPanel/store/actions'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import Loader from '@root-gipro/shared/Loader/Loader'
import { IState } from '@root-gipro/store/interfaces'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdminPanelTable from './components/AdminPanelTable'
import AdminPanelCreateUser from './components/AdminCreateUser/AdminPanelCreateUser'

const AdminPanel: FC = () => {
	const dispatch = useDispatch()
	const { users, usersLoading } = useSelector((state: IState) => state.adminPanel)

	const [open, setOpen] = React.useState(false)
	const handleOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		dispatch(getAuthUserToken())
	}, [])

	return (
		<div className='adminPanel-container'>
			<div style={{ margin: '10px 10px 10px 0' }}>Количество пользователей: {users?.length ? users?.length : 0}</div>
			<SecondaryBtn className='add_btn' onClick={handleOpen} style={{ marginBottom: 15, padding: '4px 8px' }}>
				<PersonAddRoundedIcon style={{ marginRight: 8 }} />
				Добавить пользователя
			</SecondaryBtn>

			{usersLoading ? <Loader /> : <AdminPanelTable users={users} />}
			<AdminPanelCreateUser open={open} handleClose={handleClose} />
		</div>
	)
}

export default AdminPanel
