import { copyProjects2Calc, showBtnCopyProjects2Calc } from '@root-gipro/modules/filter/store/actions'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import '@root-gipro/shared/Filter/Filter/Filter.scss'
import { FilterProps } from '@root-gipro/shared/Filter/Filter/filter.props'
import { IState } from '@root-gipro/store/interfaces'
import { getNoun, lightPretty } from '@root-gipro/utils/helpers/common'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

const Filter: React.FC<FilterProps> = ({ resetFilterHandler, showFilterResult, resultCnt, children }) => {
	const dispatch = useDispatch()
	const routeMatch = useRouteMatch()
	const url = routeMatch.path.slice(1)
	const { resultFilterState, showBtnСopyProjects2Calc } = useSelector((state: IState) => state.filter.commonProjects)
	let resultName = ''
	const handleFilterResult = useCallback(() => {
		showFilterResult()
	}, [resultCnt])

	resultName = Number(resultCnt)
		? `Показать ${lightPretty(resultCnt)} ${getNoun(resultCnt, ['рeзультат', 'результата', 'результатов'])}`
		: 'Нет результата'

	useEffect(() => {
		resultName = Number(resultCnt)
			? `Показать ${lightPretty(resultCnt)} ${getNoun(resultCnt, ['рeзультат', 'результата', 'результатов'])}`
			: 'Нет результата'
	}, [resultCnt])

	const handleFilterCopy2Projects = useCallback(() => {
		dispatch(copyProjects2Calc())
		dispatch(showBtnCopyProjects2Calc(false))
	}, [])

	useEffect(() => {
		if (isEmpty(resultFilterState)) {
			dispatch(showBtnCopyProjects2Calc(false))
		}
	}, [resultFilterState])

	return (
		<aside data-filter-animate className='aside-filter'>
			<form className='aside-filter__form'>
				<div className='aside-filter__form-head'>
					<h2 className='aside-filter__title'>Фильтр</h2>
					<button type='reset' className='aside-filter__reset link link-blue' onClick={resetFilterHandler}>
						Сбросить
					</button>
				</div>

				{Object.values(children!).filter(item => {
					if (item?.props) {
						const { tagsFilter } = item.props
						return tagsFilter.find((tag: string) => {
							if (tag === url) return item
						})
					}
				})}
			</form>
			<div className='aside-filter__result'>
				<PrimaryBtn fullWidth type='submit' onClick={handleFilterResult}>
					{resultName}
				</PrimaryBtn>
				{url === 'common-projects' && showBtnСopyProjects2Calc && resultCnt ? (
					<PrimaryBtn fullWidth type='submit' onClick={handleFilterCopy2Projects}>
						Скопировать в расчеты
					</PrimaryBtn>
				) : null}
			</div>
		</aside>
	)
}

export default Filter
