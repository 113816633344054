import { TextField } from '@material-ui/core'
import React, { FC } from 'react'

interface IAdminPanelCreateUserInput {
	name: string
	label: string
	getValue: (name: string, value: string) => void
	type: string
	defaultValue: string
	error?: boolean
	dateError?: string
}

const AdminPanelUpdateUserInput: FC<IAdminPanelCreateUserInput> = ({
	name,
	label,
	getValue,
	type,
	defaultValue,
	error,
	dateError,
}) => {
	const [value, setValue] = React.useState(defaultValue)

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		getValue(name, event.target.value)
		setValue(event.target.value)
	}
	return (
		<div>
			<label style={{ marginBottom: 8, display: 'block' }}>{label}</label>
			<TextField
				type={type}
				id={name}
				placeholder={label}
				value={value}
				variant='outlined'
				onChange={handleChange}
				error={error}
				helperText={dateError}
			/>
		</div>
	)
}

export default AdminPanelUpdateUserInput
