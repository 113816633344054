import React, { FC } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { useDispatch } from 'react-redux'
import { downloadImportData } from '@root-gipro/modules/userProjects/store/actions'

interface IDataMagazine {
	anchorEl: boolean
	setAnchorEl(event: any): void
	data: any
	link?: string
	title?: string
}

const ImportDataMagazine: FC<IDataMagazine> = ({ anchorEl, setAnchorEl, data, title, link }) => {
	const dispatch = useDispatch()
	const buttons = {
		PrimaryBtn: {
			handler: () => {
				setAnchorEl(null!)
			},
			value: 'Готово',
		},
		SecondaryBtn: {
			handler: () => {
				dispatch(downloadImportData(link))
			},
			value: 'Экспорт в Excel',
			disabled: !link,
		},
	}

	return (
		<Modal buttonOptions={buttons} setAnchorEl={setAnchorEl} anchorEl={anchorEl} widthWindow='600px'>
			<div style={{ maxHeight: '400px', height: '100%', overflowY: 'scroll' }}>
				<h3 style={{ display: 'flex', justifyContent: 'center', fontSize: '25px' }}>Журнал импорта форм</h3>

				{data?.Error?.length > 0 && (
					<>
						<h4 style={{ marginTop: '20px', fontSize: '18px', fontWeight: 600 }}>Ошибки:</h4>
						{data.Error?.map((item: any, ind: number) => (
							<p style={{ margin: '20px 0', fontSize: '16px', lineHeight: '20px' }} key={ind}>
								{item}
							</p>
						))}
					</>
				)}

				{data?.Notice?.length > 0 && (
					<>
						<h4 style={{ marginTop: '20px', fontSize: '18px', fontWeight: 600 }}>Замечания:</h4>
						{data.Notice?.map((item: any, ind: number) => (
							<p style={{ margin: '20px 0', fontSize: '16px', lineHeight: '20px' }} key={ind}>
								{item}
							</p>
						))}
					</>
				)}

				{!data?.Error?.length && !data?.Notice?.length && (
					<p style={{ fontSize: '18px', margin: '20px' }}>Нет пока данных</p>
				)}
			</div>
		</Modal>
	)
}

export default ImportDataMagazine
