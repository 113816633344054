import { createUncArr } from '@root-gipro/modules/userProjects/utils/helpers'
import { IUnc } from '@root-gipro/store/interfaces'

export type TbodyArrType = {
	[key: string]: unknown;
	tableNum: number;
	unc: (tableNum: number, uncs: IUnc[]) => IUnc[];
	title: string;
	titleShort: string;
	sitePreparation: boolean;
	addUncShowing: boolean;
}

export const tbodyArr: TbodyArrType[] = [
	{
		tableNum: 1,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Строительство ПС',
		titleShort: 'Строительство ПС',
		sitePreparation: true,
		addUncShowing: true,
	},
	{
		tableNum: 2,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Реконструкция ПС (элементов ПС), строительство элементов ПС 35-750 кВ',
		titleShort: 'Реконструкция ПС',
		sitePreparation: true,
		addUncShowing: true,
	},
	{
		tableNum: 3,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Строительство (реконструкция) КТП, ТП, РТП, СП 6 - 20 кВ',
		titleShort: 'Строительство (реконструкция) КТП, ТП, РТП, СП',
		sitePreparation: true,
		addUncShowing: true,
	},
	{
		tableNum: 4,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Строительство (реконструкция) ВЛ 0,4 - 750 кВ',
		titleShort: 'Строительство (реконструкция) ВЛ',
		sitePreparation: true,
		addUncShowing: true,
	},
	{
		tableNum: 5,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Строительство (реконструкция) КЛ 0,4 - 500 кВ',
		titleShort: 'Строительство (реконструкция) КЛ 0,4 - 500 кВ',
		sitePreparation: true,
		addUncShowing: true,
	},
	{
		tableNum: 8,
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		title: 'Расчет площади С1',
		titleShort: 'Расчет площади С1',
		sitePreparation: true,
		addUncShowing: true,
	},
	{
		tableNum: 6,
		title: 'Итого',
		unc: (tableNum: number, uncs: IUnc[]) => createUncArr(tableNum, uncs),
		titleShort: 'Итого',
		sitePreparation: true,
		addUncShowing: false,
	},
]
