import React, { ReactText, MouseEvent } from 'react'
import { IRegion } from '@root-gipro/store/interfaces'
import { capitalizeFirstLetter, formatDate } from '@root-gipro/utils/helpers/common'
import { IPurchase } from '@root-gipro/modules/purchases/models/interfaces/purchase.model'
import PurchaseInvestCount from '@root-gipro/modules/purchases/components/PurchaseInvestCount'
import { isHidden } from '@root-gipro/modules/purchases/utils/isHidden'
import { gridItemMd } from '@root-gipro/modules/purchases/utils/gridItemMd'
import { gridItemMdLg } from '@root-gipro/modules/purchases/utils/gridItemMdLg'
import { ToggleInvestDetailTypes } from '../../containers/PurchaseDetail'

interface ISelectedPurchaseCollapseDetail {
	title: string;
	extraClass: string;
	value: (item: IPurchase) => string | JSX.Element;
	clickLabel?: (event: MouseEvent<HTMLDivElement>) => void | undefined;
	clickValue?: (purchase_id: ReactText) => void;
	projects?: (item: number | undefined) => number | undefined;
}

export const SelectedPurchaseCollapseDetail = (
	regions: IRegion[],
	toggleInvestProjectDetails: ToggleInvestDetailTypes | undefined
): ISelectedPurchaseCollapseDetail[] => [

	// {
	// 	title: 'Способ закупки ',
	// 	extraClass: 'grid-item',
	// 	value: (item: IPurchase) => capitalizeFirstLetter(item.type),
	// },
	{
		title: 'Дата публикации',
		extraClass: 'grid-item',
		value: (item: IPurchase) => formatDate(item.publish_date),
	},
	// {
	// 	title: 'Начало действия плана',
	// 	extraClass: 'grid-item',
	// 	value: (item: IPurchase) => formatDate(item.startDate),
	// },
	{
		title: 'Окончание действия плана',
		extraClass: 'grid-item',
		value: (item: IPurchase) => formatDate(item.plan_expiration_date),
	},
	{
		title: 'Регион поставки',
		extraClass: 'grid-item',
		value: (item: IPurchase): string | JSX.Element => regions.find(region => region.code == item.region_code)?.name || '',
	},
	{
		title: 'Закупка у СМП',
		extraClass: 'grid-item',
		value: (item: IPurchase): string => (item.isSMB ? 'Да' : 'Нет'),
	},
	{
		title: 'Способ закупки',
		extraClass: 'grid-item-md',
		value: (item: IPurchase) => capitalizeFirstLetter(item.type),
	},
	// {
	// 	title: 'Закупка в электронной форме',
	// 	extraClass: 'grid-item',
	// 	value: (item: IPurchase): string => (Boolean(item.isElectronic) ? 'Да' : 'Нет'),
	// },
	// {
	// 	title: 'Инновационная продукция',
	// 	extraClass: 'grid-item',
	// 	value: (item: IPurchase): string => (Boolean(item.planType === 'I') ? 'Да' : 'Нет'),
	// },
	// {
	// 	title: 'Минимальные предъявляемые требования',
	// 	extraClass: 'grid-item',
	// 	value: (item: IPurchase) => capitalizeFirstLetter(item.minimumRequirements),
	// },
	// {
	// 	title: 'Дополнительная информация',
	// 	extraClass: gridItemMd(model),
	// 	value: (item: IPurchase) => item.additionalInfo,
	// }

]
