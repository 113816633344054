import { IUnc } from '@root-gipro/store/interfaces'

export const sortUncCellCode = function (sortField: string, sortOrder: string, uncs: IUnc[]): IUnc[] {
	let sorted: [] | IUnc[] = []
	if (sortField) {
		sorted = [...uncs].sort((a, b) => {
			if (a.uncCell.code === null) return 1
			if (b.uncCell.code === null) return -1
			if (a.uncCell.code === null && b.uncCell.code === null) return 0
			return (
				a.uncCell.code.toString().localeCompare(b.uncCell.code.toString(), 'ru', {
					numeric: true,
				}) * (sortOrder === 'asc' ? 1 : -1)
			)
		})
		return sorted ? sorted : []
	}
	return sorted ? sorted : []
}

export const sortUncTechnicalSolutions = function (sortField: string, sortOrder: string, uncs: IUnc[]): IUnc[] {
	let sorted: [] | IUnc[] = []
	if (sortField) {
		sorted = [...uncs].sort((a, b) => {
			if (a.uncMain.name === null) return 1
			if (b.uncMain.name === null) return -1
			if (a.uncMain.name === null && b.uncMain.name === null) return 0
			return (
				a.uncMain.name.toString().localeCompare(b.uncMain.name.toString(), 'ru', {
					numeric: true,
				}) * (sortOrder === 'asc' ? 1 : -1)
			)
		})
		return sorted ? sorted : []
	}

	return sorted ? sorted : []
}

export const sortUncCount = function (sortField: string, sortOrder: string, uncs: IUnc[]): IUnc[] {
	let sorted: [] | IUnc[] = []
	if (sortField) {
		sorted = [...uncs].sort((a, b) => {
			if (a.count === null) return 1
			if (b.count === null) return -1
			if (a.count === null && b.count === null) return 0
			return (
				a.count.toString().localeCompare(b.count.toString(), 'ru', {
					numeric: true,
				}) * (sortOrder === 'asc' ? 1 : -1)
			)
		})
		return sorted ? sorted : []
	}
	return sorted ? sorted : []
}

export const sortUncUnit = function (sortField: string, sortOrder: string, uncs: IUnc[]): IUnc[] {
	let sorted: [] | IUnc[] = []
	if (sortField) {
		sorted = [...uncs].sort((a, b) => {
			if (a.uncMain.unit === null) return 1
			if (b.uncMain.unit === null) return -1
			if (a.uncMain.unit === null && b.uncMain.unit === null) return 0
			return (
				a.uncMain.unit.toString().localeCompare(b.uncMain.unit.toString(), 'ru', {
					numeric: true,
				}) * (sortOrder === 'asc' ? 1 : -1)
			)
		})
		return sorted ? sorted : []
	}
	return sorted ? sorted : []
}
export const sortUncParameters = function (sortField: string, sortOrder: string, uncs: IUnc[]): IUnc[] {
	let sorted: [] | IUnc[] = []
	if (sortField) {
		sorted = [...uncs].sort((a, b) => {
			if (a.uncCell.parameter === null) return 1
			if (b.uncCell.parameter === null) return -1
			if (a.uncCell.parameter === null && b.uncCell.parameter === null) return 0
			return (
				a.uncCell.parameter.toString().localeCompare(b.uncCell.parameter.toString(), 'ru', {
					numeric: true,
				}) * (sortOrder === 'asc' ? 1 : -1)
			)
		})
		return sorted ? sorted : []
	}
	return sorted ? sorted : []
}

export const sortUncVoltage = function (sortField: string, sortOrder: string, uncs: IUnc[]): IUnc[] {
	let sorted: [] | IUnc[] = []
	if (sortField) {
		sorted = [...uncs].sort((a, b) => {
			if (a.voltage === null) return 1
			if (b.voltage === null) return -1
			if (a.voltage === null && b.voltage === null) return 0
			return (
				a.voltage.toString().localeCompare(b.voltage.toString(), 'ru', {
					numeric: true,
				}) * (sortOrder === 'asc' ? 1 : -1)
			)
		})
		return sorted ? sorted : []
	}
	return sorted ? sorted : []
}

export const sortUncCost = function (sortField: string, sortOrder: string, uncs: IUnc[]): IUnc[] {
	let sorted: [] | IUnc[] = []
	if (sortField) {
		sorted = [...uncs].sort((a, b) => {
			if (a.uncCell.cost === null) return 1
			if (b.uncCell.cost === null) return -1
			if (a.uncCell.cost === null && b.cost === null) return 0
			return (
				a.uncCell.cost.toString().localeCompare(b.uncCell.cost.toString(), 'ru', {
					numeric: true,
				}) * (sortOrder === 'asc' ? 1 : -1)
			)
		})
		return sorted ? sorted : []
	}
	return sorted ? sorted : []
}

export const sortUncRegionFactor = function (sortField: string, sortOrder: string, uncs: IUnc[]): IUnc[] {
	let sorted: [] | IUnc[] = []
	if (sortField) {
		sorted = [...uncs].sort((a, b) => {
			if (a.regionFactor?.value === null) return 1
			if (b.regionFactor?.value === null) return -1
			if (a.regionFactor?.value === null && b.cost === null) return 0
			return (
				a.regionFactor?.value.toString().localeCompare(b.regionFactor?.value.toString(), 'ru', {
					numeric: true,
				}) * (sortOrder === 'asc' ? 1 : -1)
			)
		})
		return sorted ? sorted : []
	}
	return sorted ? sorted : []
}

export const sortUncTotalCost = function (sortField: string, sortOrder: string, uncs: IUnc[]): IUnc[] {
	let sorted: [] | IUnc[] = []
	if (sortField) {
		sorted = [...uncs].sort((a, b) => {
			if (a.totalCost === null) return 1
			if (b.totalCost === null) return -1
			if (a.totalCost === null && b.cost === null) return 0
			return (
				a.totalCost.toString().localeCompare(b.totalCost.toString(), 'ru', {
					numeric: true,
				}) * (sortOrder === 'asc' ? 1 : -1)
			)
		})
		return sorted ? sorted : []
	}
	return sorted ? sorted : []
}
