import React, { useEffect, useState } from 'react'

const CardPeriods = ({ yearBegin, yearEndOffer }: { yearBegin: number | null; yearEndOffer: number | null }) => {
	const [fillWidth, setFillWidth] = useState(0)
	const [rightDotColor, setRightDotColor] = useState('rgb(215, 215, 215)')
	const [leftDotColor, setLeftDotColor] = useState('#292a34')
	useEffect(() => {
		if (yearEndOffer && yearBegin) {
			const full = yearEndOffer - yearBegin
			const currentYear = new Date().getFullYear()
			const pastYears = currentYear - yearBegin
			if (yearBegin === currentYear) {
				setLeftDotColor('#d7d7d7')
			}
			if (yearEndOffer === yearBegin) {
				setFillWidth(0)
			} else if (pastYears > full) {
				setFillWidth(100)
			} else {
				setFillWidth((pastYears / full) * 100)
			}
		}
	}, [])

	useEffect(() => {
		if (fillWidth >= 97) {
			setRightDotColor('#292a34')
		}
	}, [fillWidth])

	return (
		<div className='card-container__periods'>
			<h3 className='card-container__periodsTitle'>Период реализации</h3>
			<div className='card-container__periods-range'>
				<div
					className='card-container__periods-dot card-container__periods-dot--left'
					style={{ background: leftDotColor }}
				></div>
				<div
					className='card-container__periods-dot card-container__periods-dot--right'
					style={{ background: rightDotColor }}
				></div>
				<div className='card-container__periods-fill' style={{ width: `${fillWidth}%` }}></div>
			</div>
			<div className='card-container__periodSubTitles'>
				<div className='card-container__period'>c {yearBegin}</div>
				<div className='card-container__period'>по {yearEndOffer}</div>
			</div>
		</div>
	)
}

export default CardPeriods
