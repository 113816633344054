import React from 'react'

interface IClasses {
	classes?: string;
	keyVal?: string;
}
export const ArrowDownSVG: React.FC<IClasses> = ({ classes, keyVal }) => {
	return (
		<svg
			className={`ArrowDownSVG ${classes && classes}`}
			width='10'
			height='6'
			viewBox='0 0 10 6'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			key={keyVal && keyVal}
		>
			<path d='M9 1L5 5L1 1' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	)
}
