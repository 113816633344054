import { ReactText } from 'react'
import { IParams } from '../models/interfaces/filter-state.model'
import { firstCheckExclude, noUncs, valuationUncOfferForecast, estimatedOfferYM } from './exclude-array'

export const excludeData = (object: IParams) => {
	if (firstCheckExclude(object)) return object

	const noUncsArr = noUncs(object)
	const valuationUncOfferForecastArr = valuationUncOfferForecast(object)
	const estimatedOfferYMArr = estimatedOfferYM(object)

	const excludeArr = [...noUncsArr, ...valuationUncOfferForecastArr, ...estimatedOfferYMArr]
	const obj: { [key: string]: ReactText | boolean } = {}

	Object.entries(object)
		.filter((item: IParams[]) => {
			return JSON.stringify(excludeArr).indexOf(JSON.stringify(item[0])) === -1
		})
		.forEach(item => (obj[item[0]] = item[1]))

	return obj
}
