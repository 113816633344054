import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { CardHeaderTitleProps } from '@root-gipro/shared/Card/CardHeaderTitle/card-header-title.props'
import { Alert } from '@material-ui/lab'
import { Tooltip } from '@material-ui/core'

const CardHeaderTitle: FC<CardHeaderTitleProps> = ({
	commonProjectId,
	title,
	iconType,
	toggleType,
	expandCardHandler,
	isExpanded,
	path,
	children,
	getProjectById,
	purchaseId,
}) => {
	const [showAlert, setShowAlert] = useState(false)
	const role = Number(window.localStorage.getItem('roleId'))

	const projectId = purchaseId || commonProjectId
	let classes

	if (!toggleType) toggleType = 'PROJECTS'

	if (projectId) {
		classes = 'space-between'
	} else {
		classes = 'flex-end'
	}

	return (
		<>
			<div className='card-container__head'>
				{path && role != 999 ? (
					<Tooltip title={title} enterDelay={1000} enterNextDelay={2000} placement='top'>
						<Link
							to={path}
							onClick={() => getProjectById && getProjectById(projectId as string)}
							className='card-container__code link-blue'
						>
							<span className='code'>{title}</span>
						</Link>
					</Tooltip>
				) : (
					<Tooltip title={title} enterDelay={1000} enterNextDelay={2000} placement='top'>
						<div className='card-container__code link-blue'>
							{iconType && <span className={iconType} />}
							<span
								className={`${iconType && 'code'}`}
								onClick={() => {
									if (role && role == 999) {
										setShowAlert(true)
									} else {
										getProjectById && getProjectById(projectId as string)
									}
								}}
							>
								{title}
							</span>
						</div>
					</Tooltip>
				)}

				<div className={`card-container__wrapper-actions ${classes}`}>
					<div className='card-container__actions'>
						<span
							className='card-container__detailed-btn'
							onClick={() => projectId && expandCardHandler && expandCardHandler(projectId)}
						>
							{isExpanded ? 'Свернуть' : 'Подробнее'}
						</span>
					</div>
				</div>
			</div>

			{showAlert === true && (
				<Alert severity='warning' action={<Link to='/tariffs'>Подключить</Link>}>
					Возможно только при подключении тарифа
				</Alert>
			)}
			{children}
		</>
	)
}

export default CardHeaderTitle
