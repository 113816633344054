import React, { FC, useCallback, useEffect, useState } from 'react'
import { Fade, Modal, Theme, createStyles, makeStyles } from '@material-ui/core'
import { ICompany, IState } from '@root-gipro/store/interfaces'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import AdminCompanyInput from './components/AdminCompanyInput'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import AdminCompanySelect from './components/AdminCompanySelect'
import { createCompany, updateCompanyInfo } from '@root-gipro/modules/AdminPanel/store/actions'

interface IAdminPanelDetails {
	open: boolean;
	handleClose: () => void;
	action?: string;
	getCurrentRegions?: any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			justifyContent: 'center',
			outline: 'none',
			height: '100%',
			alignItems: 'center',
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	})
)

const AdminCompanyDetails: FC<IAdminPanelDetails> = ({ open, handleClose, action }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const { company } = useSelector((state: IState) => state.adminPanel)

	const [companyObj, setCompanyObj] = useState<ICompany>({
		id: '',
		name: '',
		full_name: '',
		inn: '',
		ogrn: '',
		okato: '',
		kpp: '',
		regionIds: [],
		deflator_precision: '',
		ipr_start_year: '',
		ipr_end_year: '',
		ipr:''
	})

	const checkDisabled = useCallback((obj: any) => {
		for (const key in obj) {
			if (key !== 'parent_id' && key !== 'id') {
				if (obj[key] == '' || obj[key] == undefined) {
					dispatch(
						showNotify({
							type: 'error',
							message: 'Заполните все необходимые поля!',
							duration: 10,
						})
					)
					return false
				}
			}
		}
		return true
	}, [])

	const hadnlleBtn = () => {
		if (checkDisabled(companyObj)) {
			if (action === 'updateCompany' && company) {
				dispatch(updateCompanyInfo(companyObj))
			} else {
				dispatch(createCompany(companyObj))
			}
			handleClose()
		}
	}

	useEffect(() => {
		if (action === 'updateCompany' && company) {
			setCompanyObj(company)
		} else {
			setCompanyObj({
				id: '',
				name: '',
				full_name: '',
				inn: '',
				ogrn: '',
				okato: '',
				kpp: '',
				regionIds: [],
				deflator_precision: '',
				ipr_start_year: '',
				ipr_end_year: '',
			})
		}
	}, [open])

	const getValue = (name: string, value: string) => {
		setCompanyObj((prev: any) => ({ ...prev, [name]: value }))
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			className={classes.modal}
			closeAfterTransition
		>
			<Fade in={open} style={{ maxHeight: '650px', overflowY: 'scroll' }}>
				<form
					noValidate
					autoComplete='off'
					style={{ background: 'white', padding: '32px', maxWidth: 550, width: '100%' }}
				>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: 16 }}>
						<AdminCompanyInput
							getValue={getValue}
							defaultValue={company?.name}
							type='string'
							id='name'
							label='Наименование компании'
						/>
						<AdminCompanyInput
							getValue={getValue}
							defaultValue={company?.full_name}
							type='string'
							id='full_name'
							label='Полное наименование компании'
						/>
						<AdminCompanyInput
							getValue={getValue}
							defaultValue={company?.deflator_precision}
							type='number'
							id='deflator_precision'
							label='Точность'
						/>
						<AdminCompanyInput
							getValue={getValue}
							defaultValue={company?.ipr_start_year}
							type='number'
							id='ipr_start_year'
							label='Начало ИПР'
						/>
						<AdminCompanyInput
							getValue={getValue}
							defaultValue={company?.ipr_end_year}
							type='number'
							id='ipr_end_year'
							label='Конец ИПР'
						/>
						<AdminCompanyInput getValue={getValue} defaultValue={company?.inn} type='number' id='inn' label='ИНН' />
						<AdminCompanyInput getValue={getValue} defaultValue={company?.kpp} type='number' id='kpp' label='КПП' />
						<AdminCompanyInput getValue={getValue} defaultValue={company?.ogrn} type='number' id='ogrn' label='ОГРН' />
						<AdminCompanyInput
							getValue={getValue}
							defaultValue={company?.okato}
							type='number'
							id='okato'
							label='ОКАТО'
						/>
						<AdminCompanySelect companyObj={companyObj} setCompanyObj={setCompanyObj} label='Субъект РФ' />
					</div>
					<PrimaryBtn onClick={() => hadnlleBtn()} style={{ width: '100%' }}>
						{action === 'updateCompany' ? <>Изменить</> : <>Добавить</>}
					</PrimaryBtn>
				</form>
			</Fade>
		</Modal>
	)
}

export default AdminCompanyDetails
