export const hidenUncs = [
	//A6
	{ id: '237', y: [15, 16, 17] },
	//B1
	{ id: '200', y: [7, 8], x: [5], col: 2 },
	//B4
	{ id: '203', y: [6], x: [5], col: 2 },
	//B5
	{ id: '204', y: [6], x: [5], col: 2 },
	//И1
	{ id: '247', x: [5], col: 2 },
	//И2
	{ id: '248', x: [5], col: 2 },
	//И3
	{ id: '249', y: [2, 3, 4], x: [5], col: 2 },
	//И5
	{ id: '251', x: [8], col: 6 },
	//И6
	{ id: '252', x: [5], col: 2 },
	//И7
	{ id: '253', x: [5], col: 2 },
	//И8
	{ id: '254', y: [2, 3, 4], x: [5], col: 2 },
	// И10
	{ id: '256', x: [8], col: 6 },
]
