import React from 'react'
import { DropDown } from '@root-gipro/shared/Windows/DropDown/DropDown'
import { TabsToggle } from '@root-gipro/shared/TabsComponents/TabsToggle'
import { types } from '@root-gipro/shared/FilterHeader/FilterHeader'

interface TabsDropDownProps {
	anchorEl: (EventTarget & HTMLElement) | null;
	setAnchorEl(event: HTMLElement): void;
	editToggleType(value: string): void;
	toggleType: string;
	types: types[];
}

const TabsDropDown: React.FC<TabsDropDownProps> = ({ types, anchorEl, editToggleType, setAnchorEl, toggleType }) => {
	return (
		<DropDown
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			style={{ borderRadius: 4, width: 'auto' }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			transformOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<TabsToggle types={types} editToggleType={(value: string) => editToggleType(value)} toggleType={toggleType} />
		</DropDown>
	)
}
export { TabsDropDown }
