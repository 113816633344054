import cn from 'classnames'
import React, { useState } from 'react'

import { Tooltip } from '@material-ui/core'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'

import { ArrowDownSVG } from '../../SVGComponents/ArrowDownSVG'
import TheadCellFilteredField from './TheadCellFilteredField'

export interface TheadCellProps {
	item: { title: string; textAlign: string; accessor?: string; tooltip?: string }
	handleSorting: (sortField: string, setOrder: string) => void
	handleChange: (inputFiled: string, value: string) => void
}

const TheadCell: React.FC<TheadCellProps> = ({ item, handleSorting, handleChange }) => {
	const [order, setOrder] = useState<string>('asc')
	const [sortField, setSortField] = useState<string>('')

	const handleReverseSort = (title: string) => {
		const sortOrder = title === sortField && order === 'asc' ? 'desc' : 'asc'
		setSortField(title)
		setOrder(sortOrder)
		handleSorting(title, sortOrder)
	}

	const handleChangeInput = (inputFiled: string, value: string) => {
		handleChange(inputFiled, value)
	}

	return (
		<Tooltip
			key={item.title}
			title={item.tooltip ? item.tooltip : item.title}
			enterDelay={500}
			enterNextDelay={500}
			placement='top'
		>
			<div className={cn(styles.theadCellWrap)}>
				<div className={cn(styles.theadCell)}>
					<span>{item.title}</span>
					{item.title !== '' ? (
						<div
							onClick={() => handleReverseSort(item.title)}
							className={`projects__sort__arrow-options ${order === 'asc' ? 'active-up' : 'active-down'}`}
						>
							<ArrowDownSVG />
							<ArrowDownSVG />
						</div>
					) : null}
				</div>
				<TheadCellFilteredField item={item} handleChangeInput={handleChangeInput} />
			</div>
		</Tooltip>
	)
}

export default TheadCell
