import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { IT7RowForm20 } from '@root-gipro/store/interfaces'
import React, { useEffect } from 'react'

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: '1px solid #000',
		borderRadius: '10px !important',
		height: '27px !important',
		color: 'white',
		padding: '4px 8px !important',
	},
})

const useStylesFormControl = makeStyles({
	root: {
		'& .MuiSelect-icon': {
			padding: 0,
			top: 'calc(50% - 12px)',
			color: 'rgba(0, 0, 0, 0.54)',
			right: 4,
			position: 'absolute',
			pointerEvents: 'none',
		},
	},
})

interface IUnNormalizedCostsSelectProps {
	t7row: IT7RowForm20;
	handler: any;
	items: any[];
	value: any;
	name: string;
	disabled: boolean;
}

const TableSelect: React.FC<IUnNormalizedCostsSelectProps> = ({ t7row, handler, items, value, name, disabled }) => {
	const classes = useStyles()
	const classesFromControl = useStylesFormControl()

	const [localValue, setLocalValue] = React.useState('')

	useEffect(() => {
		setLocalValue(value)
	}, [value])

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setLocalValue(event.target.value as string)
		handler(event, t7row)
	}

	return (
		<FormControl classes={classesFromControl}>
			<Select
				defaultValue={1}
				id='tableSelect'
				value={localValue === null ? '' : localValue}
				onChange={handleChange}
				autoWidth
				name={name}
				classes={classes}
				disabled={disabled}
				IconComponent={() => <ExpandMoreIcon className='MuiSelect-icon' />}
			>
				<MenuItem value={value}>
					<span>{value}</span>
				</MenuItem>
				{items.map((item, i) => (
					<MenuItem value={item.value} key={i}>
						<span style={{ width: '100%' }}>{item.value}</span>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default TableSelect
