import { Fade, Modal, Theme, createStyles, makeStyles } from '@material-ui/core'
import { IUser } from '@root-gipro/modules/AdminPanel/interfaces/user'
import { createUsers } from '@root-gipro/modules/AdminPanel/store/actions'
import { PrimaryBtn } from '@root-gipro/shared/Button/Button'
import React, { FC, useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import AdminPanelCreateUserCompany from './AdminPanelCreateUserCompany'
import AdminPanelCreateUserFilial from './AdminPanelCreateUserFilial'
import AdminPanelCreateUserInput from './AdminPanelCreateUserInput'
import { disabledAdd, validateEmail, validatePassword } from '@root-gipro/modules/AdminPanel/store/helpers'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			overflow: 'auto',
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	})
)

interface IAdminPanelCreateUser {
	open: boolean
	handleClose: () => void
}

const AdminPanelCreateUser: FC<IAdminPanelCreateUser> = ({ open, handleClose }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [userObj, setUserObj] = useState<IUser | { data: Record<string, any> }>({ data: {} })
	const [selectedCompany, setSelectedCompany] = useState<number>(999)
	const [errorData, setErrorData] = useState<{
		mail: { error: boolean; message: string }
		password: { error: boolean; message: string }
	}>({
		mail: { error: false, message: '' },
		password: { error: false, message: '' },
	})

	useEffect(() => {
		setErrorData({
			mail: { error: false, message: '' },
			password: { error: false, message: '' },
		})
		setUserObj({ data: {} })
	}, [open])

	const addUser = () => {
		if (
			//@ts-ignore
			typeof userObj['firstname'] !== 'undefined' &&
			//@ts-ignore
			typeof userObj['lastname'] !== 'undefined' &&
			//@ts-ignore
			typeof userObj['email'] !== 'undefined' &&
			//@ts-ignore
			typeof userObj['password'] !== 'undefined' &&
			//@ts-ignore
			typeof userObj['data'] !== 'undefined'
		) {
			dispatch(createUsers(userObj as IUser))
			handleClose()
			setUserObj({ data: {} })
		}
	}

	const getValue = (name: string, value: string) => {
		if (name === 'firstname') {
			setUserObj(prev => ({ ...prev, firstname: value }))
		} else if (name === 'lastname') {
			setUserObj(prev => ({ ...prev, lastname: value }))
		} else if (name === 'email') {
			const emailError = validateEmail(value)
			setErrorData(prev => ({ ...prev, mail: emailError }))
			setUserObj(prev => ({ ...prev, email: value }))
		} else if (name === 'password') {
			const passwordError = validatePassword(value)
			setErrorData(prev => ({ ...prev, password: passwordError }))
			setUserObj(prev => ({ ...prev, password: value }))
		} else if (name === 'phone') {
			setUserObj(prev => ({ ...prev, data: { ...prev.data, phone: value } }))
		} else if (name === 'company') {
			setSelectedCompany(Number(value))
			setUserObj(prev => ({ ...prev, data: { ...prev.data, company: value } }))
		} else if (name === 'filial') {
			setUserObj(prev => ({ ...prev, data: { ...prev.data, filial: value } }))
		}
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			className={classes.modal}
			closeAfterTransition
		>
			<Fade in={open}>
				<form
					noValidate
					autoComplete='off'
					style={{ background: 'white', padding: '32px', maxWidth: 550, width: '100%' }}
				>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: 16 }}>
						<AdminPanelCreateUserCompany getValue={getValue} type='company' name='company' label='Компания' />
						<AdminPanelCreateUserFilial
							getValue={getValue}
							type='filial'
							name='filial'
							label='Филиал'
							selectedCompany={selectedCompany}
						/>
						<AdminPanelCreateUserInput getValue={getValue} type='text' name='lastname' label='Фамилия' />
						<AdminPanelCreateUserInput getValue={getValue} type='text' name='firstname' label='Имя' />
						<AdminPanelCreateUserInput
							getValue={getValue}
							type='email'
							name='email'
							label='Почта'
							error={errorData.mail.error}
							dateError={errorData.mail.message}
						/>
						<AdminPanelCreateUserInput
							getValue={getValue}
							type='password'
							name='password'
							label='Пароль'
							error={errorData.password.error}
							dateError={errorData.password.message}
						/>
						<AdminPanelCreateUserInput getValue={getValue} type='tel' name='phone' label='Номер телефона' />
					</div>
					<PrimaryBtn
						onClick={addUser}
						style={{ width: '100%' }}
						disabled={disabledAdd(userObj, errorData, 'createUser')}
					>
						Добавить
					</PrimaryBtn>
				</form>
			</Fade>
		</Modal>
	)
}

export default AdminPanelCreateUser
