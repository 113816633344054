import React, { useCallback, useEffect, useState } from 'react'
import { TabsGroup } from '../TabsComponents/CustomTabs'
import style from '@root-gipro/shared/TabsComponents/TabsComponents.module.scss'
import { Tab, Tooltip, Typography } from '@material-ui/core'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces'
import { setUncSort } from '@root-gipro/modules/userProjects/store/actions'
import { useHistory } from 'react-router-dom'

interface IVersion {
	userProjectId: number
	name: string
	is_actual: boolean
	unc_sort: string
}

const types = [
	{
		name: '2018',
		path: '',
	},
	{
		name: '2024',
		path: '',
	},
]

const TabUnc = ({ path }: { path: string }) => {
	const history = useHistory()
	const dispatch = useDispatch()

	const currentUrl = path.split('/')[1]
	const currentState = currentUrl === 'user-archive' ? 'userArchives' : 'userProjects'
	const { originalObj, projectObj } = useSelector((state: IState) => state[currentState])

	const [selectedTab, setSelectedTab] = useState<string | null>(null)
	const [versions, setVersions] = useState<IVersion[] | []>([])
	useEffect(() => {
		let versionsProject: IVersion[] | [] = []
		if (projectObj.versions) {
			versionsProject = [...projectObj.versions]
		}
		setVersions(versionsProject)
	}, [originalObj])

	const handleChange = useCallback(
		(_: React.ChangeEvent<{}>, newVal: string) => {
			if (currentUrl === 'user-archive') return
			dispatch(setUncSort(newVal))
			
			const uncSort = [...versions].find((version: IVersion) => version?.unc_sort === newVal)

			if (uncSort) {
				history.push(`/${currentUrl}/project/${uncSort.userProjectId}/parameters`)
			}
		},
		[projectObj, originalObj]
	)

	return (
		<>
			<TabsGroup
				value={projectObj.unc_sort}
				disabled={currentUrl === 'user-archive'}
				onChange={handleChange}
				className={style.checkPlateTabs}
			>
				{types &&
					types.map((type: any) => (
						<Tooltip
							key={type.name}
							title={
								type.name == 2018 ? (
									<Typography>
										Режим расчета по сборнику УНЦ, утверждённому в 2019 году. Базовыми являются расценки 2018 года
									</Typography>
								) : (
									<Typography>
										Режим расчета по сборнику УНЦ, утверждённому в 2024 году. Базовыми являются расценки 2023 года
									</Typography>
								)
							}
						>
							{projectObj.unc_sort == type.name ? (
								<span>
									<Tab
										className={cn({
											[style.active]: projectObj.unc_sort === type.name,
										})}
										disabled={selectedTab == type.name}
										disableRipple
										label={type.name == 2018 ? '2019' : type.name}
										value={type.name}
									/>
								</span>
							) : (
								<Tab
									className={cn({
										[style.active]: projectObj.unc_sort === type.name,
									})}
									disabled={projectObj.unc_sort == type.name}
									disableRipple
									label={type.name == 2018 ? '2019' : type.name}
									value={type.name}
								/>
							)}
						</Tooltip>
					))}
			</TabsGroup>
		</>
	)
}

export default TabUnc
