import React, { FC, useEffect } from 'react'
import List from '@root-gipro/modules/userProjects/components/UserProjects/List'
import { HeaderWrapper } from '@root-gipro/modules/userProjects/components/HeaderWrapper'
import { UserProjectsNav } from '@root-gipro/modules/userProjects/components/UserProjects/UserProjectsNav'
import '@root-gipro/modules/userProjects/styles/UserProjects.scss'
import FilterContainer from '@root-gipro/modules/filter/FilterContainer'
import { useDispatch } from 'react-redux'
import { filtersRequest, setCallbacks } from '@root-gipro/modules/filter/store/actions'
import { useRouteMatch } from 'react-router-dom'
import {
	getCommonProjects,
	loadingCommonProjects,
	setCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { sortUserProjects } from '../store/actions'
import { userProjectsApi } from '@root-gipro/store/api'
import sortList from '@root-gipro/modules/userProjects/models/consts/sort-list.consts'

const UserProjects: FC = () => {
	const dispatch = useDispatch()
	const routeMatch = useRouteMatch()

	const url: string = routeMatch.path.slice(1)

	useEffect(() => {
		dispatch(setCallbacks(getCommonProjects, userProjectsApi, setCommonProjects, loadingCommonProjects))
		dispatch(filtersRequest(url))
	}, [url])

	const sort = (name: string, sortName: string, order: boolean): void => {
		dispatch(sortUserProjects(name, sortName, order))
	}

	return (
		<>
			{
				<>
					<div className='user-projects__container'>
						<FilterContainer />

						<div className='projects-wrapper'>
							<HeaderWrapper>
								<UserProjectsNav sort={sort} sortList={sortList} />
							</HeaderWrapper>
							<List />
						</div>
					</div>
				</>
			}
		</>
	)
}

export default UserProjects
