import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

interface IData {
	[index: string]: { title: string; value: string; valueRaw: number };
}

const CardAmountOfFunding = ({ data }: { data: IData }) => {
	const [fillWidthLeft, setFillWidthLeft] = useState(0)
	const [fillWidthRight, setFillWidthRight] = useState(0)
	const fullWidth = 99.5

	useEffect(() => {
		const totalInvestmentUncOffer = data.totalInvestmentUncOffer.valueRaw
		const limit = data.limit.valueRaw
		const valuationOfferForecast = data.valuationOfferForecast.valueRaw

		if (totalInvestmentUncOffer && limit) {
			const full = valuationOfferForecast + limit
			const valuationOfferForecastPercent = valuationOfferForecast / full
			setFillWidthLeft(valuationOfferForecastPercent * fullWidth)
		} else if (valuationOfferForecast === 0) {
			setFillWidthRight(fullWidth)
		} else if (limit === 0) {
			setFillWidthLeft(fullWidth)
		}
	}, [data])

	useEffect(() => {
		setFillWidthRight(fullWidth - fillWidthLeft)
	}, [fillWidthLeft])

	let red
	if (typeof data.totalInvestmentUncOffer.valueRaw === 'number') {
		red = data.totalInvestmentUncOffer.valueRaw < 0 ? { color: 'red' } : {}
	}

	return (
		<div className='card-container__amountOfFunding'>
			<Grid container spacing={1} className='card-container__amountOfFunding-grid'>
				<Grid item xs={12}>
					<Grid
						container
						spacing={2}
						className='card-container__totalInvestmentUncOffer'
						alignItems='center'
						justify='space-between'
					>
						<Grid item xs={8} className='card-container__totalInvestmentUncOffer-title'>
							<span>{data.totalInvestmentUncOffer.title}</span>
						</Grid>
						<Grid item xs={1} className='card-container__totalInvestmentUncOffer-unc'>
							<span>УНЦ</span>
						</Grid>
						<Grid item xs={3} className='card-container__totalInvestmentUncOffer-value' style={red}>
							<span>{data.totalInvestmentUncOffer.value} млн ₽</span>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid
						container
						spacing={2}
						alignItems='center'
						justify='space-between'
						className='card-container__additionalFundingOffer'
					>
						<Grid item xs={8} className='card-container__additionalFundingOffer-title'>
							<span className='card-container__additionalFundingOffer-title'>{data.additionalFundingOffer.title}</span>
						</Grid>
						<Grid item xs={1}></Grid>
						<Grid item xs={3} className='card-container__additionalFundingOffer-value'>
							<span>{data.additionalFundingOffer.value} млн ₽</span>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<div className='card-container__periods-range cardRight'>
				<div className='card-container__periods-fill cardRight' style={{ width: `${fillWidthLeft}%` }}></div>
				<div
					className='card-container__periods-fill card-container__periods-fill--reverse'
					style={{ width: `${fillWidthRight}%` }}
				></div>
			</div>
			<div className='card-container__periodSubTitles'>
				<div className='card-container__valuationOfferForecast'>
					<span className='card-container__valuationOfferForecast-value'>
						{data.valuationOfferForecast.value} млн ₽
					</span>
					<span className='card-container__valuationOfferForecast-title'>{data.valuationOfferForecast.title}</span>
				</div>
				<div className='card-container__limit'>
					<span className='card-container__limit-value'>{data.limit.value} млн ₽</span>
					<span className='card-container__limit-title'>{data.limit.title}</span>
				</div>
			</div>
		</div>
	)
}

export default CardAmountOfFunding
