import React, { ReactNode } from 'react'

interface IWrapperCellProps {
	currentCellTags: string;
	children: ReactNode;
}

export const WrapperCell = ({ children, currentCellTags }: IWrapperCellProps) => {
	if (children) {
		return (
			<>
				{Object.values(children).filter(item => {
					const { cellTags } = item.props
					return cellTags.find((tag: string) => {
						if (tag === currentCellTags) {
							return item
						}
					})
				})}
			</>
		)
	} else {
		return <></>
	}
}
