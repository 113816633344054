import { ReactText } from 'react'
import { ProjectType } from '../enums/projects.enum'

export enum FavoritesType {
	SET_FAVORITES_PROJECTS = 'SET_FAVORITES_PROJECTS',
	SET_FAVORITES_PROJECTS_COUNT = 'SET_FAVORITES_PROJECTS_COUNT',
	SET_FAVORITES_PROJECTS_SUM = 'SET_FAVORITES_PROJECTS_SUM',
	SET_FAVORITES_PURCHSES_COUNT = 'SET_FAVORITES_PURCHSES_COUNT',
	SET_FAVORITES_PURCHSES_SUM = 'SET_FAVORITES_PURCHSES_SUM',
	GET_FAVORITES_PROJECTS = 'GET_FAVORITES_PROJECTS',
	GET_FAVORITES_PROJECTS_SET_PURCHASES = 'GET_FAVORITES_PROJECTS_SET_PURCHASES',
	SORT_FAVORITES_PROJECTS = 'SORT_FAVORITES_PROJECTS',
	SORT_FAVORITES_PURCHASES = 'SORT_FAVORITES_PURCHASES',
	DELETE_FAVORITES_PROJECTS = 'DELETE_FAVORITES_PROJECTS',
	EDIT_TOGGLE_TYPE_FAVORITES = 'EDIT_TOGGLE_TYPE_FAVORITES',
	SET_LOADING_FAVORITES = 'SET_LOADING_FAVORITES',
	GET_COUNT_PURCHASES = 'GET_COUNT_PURCHASES',
}

interface SortFavoritesProjects {
	type: FavoritesType.SORT_FAVORITES_PROJECTS;
	name: string;
	sortName: string;
	order: boolean;
}
interface SortFavoritesPurchases {
	type: FavoritesType.SORT_FAVORITES_PURCHASES;
	name: string;
	sortName: string;
	order: boolean;
}
interface GetCountPurchases {
	type: FavoritesType.GET_COUNT_PURCHASES;
	id: ReactText;
}

interface SetFavoritesProjects {
	type: FavoritesType.SET_FAVORITES_PROJECTS;
	projects: any;
	resultsCnt: number;
	priceSum: number;
}

interface GetFavoritesProjects {
	type: FavoritesType.GET_FAVORITES_PROJECTS;
	toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES;
}
interface GetFavoritesProjectsSetPurchases {
	type: FavoritesType.GET_FAVORITES_PROJECTS_SET_PURCHASES;
	toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES;
}

interface EditToggleType {
	type: FavoritesType.EDIT_TOGGLE_TYPE_FAVORITES;
	toggleType: ProjectType.PROJECTS | ProjectType.PURCHASES;
}

interface DeleteFavoritesProjects {
	type: FavoritesType.DELETE_FAVORITES_PROJECTS;
	projectId: string | string[];
	project: string;
}
interface SetLoadingFavorites {
	type: FavoritesType.SET_LOADING_FAVORITES;
	loading: boolean;
}

interface SetFavoritesProjectsCount {
	type: FavoritesType.SET_FAVORITES_PROJECTS_COUNT;
	projectsCount: number;
}
interface SetFavoritesProjectsSum {
	type: FavoritesType.SET_FAVORITES_PROJECTS_SUM;
	valuationOfferForecastSum: number;
	valuationPlanForecastSum: number;
}
interface SetFavoritesPurchsesCount {
	type: FavoritesType.SET_FAVORITES_PURCHSES_COUNT;
	projectsCount: number;
}
interface SetFavoritesPurchsesSum {
	type: FavoritesType.SET_FAVORITES_PURCHSES_SUM;
	maximumPriceSum: number;
}

export type FavoritesTypes =
	| SetLoadingFavorites
	| EditToggleType
	| SetFavoritesProjects
	| GetFavoritesProjects
	| DeleteFavoritesProjects
	| GetCountPurchases
	| SortFavoritesProjects
	| GetFavoritesProjectsSetPurchases
	| SortFavoritesPurchases
	| SetFavoritesProjectsCount
	| SetFavoritesProjectsSum
	| SetFavoritesPurchsesCount
	| SetFavoritesPurchsesSum
