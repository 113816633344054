import React from 'react'
import styled from 'styled-components'

const BlockFailure = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100px;
	margin: auto;
	text-align: center;
	font-size: 22px;
`

const FailurePage = () => {
	return <BlockFailure>Страница не найдена</BlockFailure>
}

export default FailurePage
