import { fetchAuthHeaders } from '@root-gipro/store/api'
import { UserRequiredObj } from '../interfaces/user'

export const ROLE_OWNER = 5
export const ROLE_DEVELOPER = 3

export async function getUserRole(id: number) {
	try {
		const getUserRoleData = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}/roles`, {
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${localStorage.getItem('service_auth_token')}`,
				},
			})
			const data = await req.json()

			const roleId = await data.userRoles.filter((userRole: any) => userRole?.serviceId === 'gipro')[0].id
			return roleId
		}
		const roleId = await getUserRoleData()
		return roleId
	} catch (error) {
		console.log(error)
	}
}

export function getUserAuthRole(id: number) {
	try {
		const getUserRoleData = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}/roles`, {
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${localStorage.getItem('service_auth_token')}`,
				},
			})
			const data = await req.json()

			const roleId = await data.userRoles.filter((userRole: any) => userRole?.serviceId === 'auth')[0].id
			return roleId
		}
		const roleId = getUserRoleData()
		return roleId
	} catch (error) {
		console.log(error)
	}
}

export async function getUsersRoles() {
	try {
		const usersRoles = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/user-roles`, {
			method: 'GET',
			headers: fetchAuthHeaders(),
		})

		const data = await usersRoles.json()

		if (data.status === 'success') {
			return data.userRoles.filter((userRole: any) => userRole?.serviceId === 'gipro')
		} else {
			throw Error('Не удалось получить список id')
		}
	} catch (error) {
		console.log(error)
	}
}

export async function getUserAccess(id: number) {
	try {
		const getUserAccessData = async () => {
			const req = await fetch(`https://${process.env.REACT_APP_ENV_AUTH}/api/v1/users/${id}/access`, {
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${localStorage.getItem('service_auth_token')}`,
				},
			})
			const data = await req.json()
			console.log(data)
			return data.userAccess
		}
		const userAccess = await getUserAccessData()

		return userAccess
	} catch (error) {
		console.log(error)
	}
}

export const disabledAdd = (userObj: UserRequiredObj | any, errorData: any, type: string): boolean => {
	const requiredFields =
		type === 'changeUser' ? ['firstname', 'lastname', 'email'] : ['firstname', 'lastname', 'email', 'password']

	const hasMissingRequiredField = requiredFields.some(field => {
		const value = userObj[field as keyof UserRequiredObj]
		return value === undefined || value === ''
	})
	let hasMissingCompany

	if (type === 'changeUser') {
		hasMissingCompany = !userObj.data || !userObj.companyId || userObj.companyId === ''

		return hasMissingRequiredField || hasMissingCompany || errorData.mail.error
	} else {
		hasMissingCompany = !userObj.data || !userObj.data.company

		return hasMissingRequiredField || hasMissingCompany || errorData.mail.error || errorData.password.error
	}
}

export const validatePassword = (password: string) => {
	if (!/^[a-zA-Z0-9]+$/.test(password)) {
		return { error: true, message: 'Пароль должен содержать только латинские буквы и цифры.' }
	} else if (password.length > 20) {
		return { error: true, message: 'Длина пароля не должна превышать 20 символов.' }
	} else if (password.length < 6) {
		return { error: true, message: 'Длина пароля должна быть не менее 6 символов.' }
	} else if (!/[0-9]/.test(password)) {
		return { error: true, message: 'Пароль должен содержать минимум одну цифру.' }
	} else if (!/[a-zA-Z]/.test(password)) {
		return { error: true, message: 'Пароль должен содержать хотя бы одну букву.' }
	} else {
		return { error: false, message: '' }
	}
}
export const validateEmail = (email: string) => {
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
	if (email.length > 254) {
		return { error: true, message: 'Длина электронной почты не должна превышать 254 символов.' }
	} else if (!emailRegex.test(email)) {
		return { error: true, message: 'Неверный формат электронной почты.' }
	} else {
		return { error: false, message: '' }
	}
}
