export enum NameLink {
	COMMON_PROJECTS = 'Проекты',
	USER_PROJECTS = 'Расчеты',
	USER_ARCHIVE = 'Архив',
	PURCHASES = 'Закупки',
}
export enum NameLinkShrt {
	COMMON_PROJECTS = 'Проекты',
	USER_PROJECTS = 'Расчеты',
	USER_ARCHIVE = 'Архив',
	PURCHASES = 'Закупки',
}
