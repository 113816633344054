import React from 'react'
type Chosen = {
	chosen?: boolean
	classes?: string
	onClick?: any
}

export const StarSVG: React.FC<Chosen> = ({ chosen = false }) =>
	chosen ? (
		<svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M17.4182 5.98618L12.0222 5.21059L9.60999 0.374144C9.54411 0.241725 9.43572 0.134529 9.30183 0.0693701C8.96604 -0.0945772 8.55799 0.0420456 8.39009 0.374144L5.97791 5.21059L0.581865 5.98618C0.433096 6.0072 0.297079 6.07657 0.192941 6.18166C0.0670438 6.30964 -0.00233122 6.48181 5.98176e-05 6.66035C0.00245086 6.83889 0.0764123 7.00918 0.205692 7.13382L4.10981 10.8983L3.18744 16.214C3.16581 16.3376 3.17965 16.4648 3.22738 16.5811C3.27511 16.6973 3.35483 16.7981 3.4575 16.8718C3.56016 16.9456 3.68166 16.9894 3.80823 16.9983C3.9348 17.0072 4.06136 16.9809 4.17356 16.9223L9.00004 14.4127L13.8265 16.9223C13.9583 16.9917 14.1113 17.0148 14.258 16.9896C14.6277 16.9265 14.8764 16.5797 14.8126 16.214L13.8903 10.8983L17.7944 7.13382C17.9007 7.03082 17.9708 6.8963 17.992 6.74917C18.0494 6.38134 17.7901 6.04083 17.4182 5.98618Z'
				fill='#1BAAF0'
			/>
		</svg>
	) : (
		<svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M17.4182 5.98618L12.0222 5.21059L9.60999 0.374144C9.54411 0.241725 9.43572 0.134529 9.30183 0.0693701C8.96604 -0.0945772 8.55799 0.0420455 8.39009 0.374144L5.97791 5.21059L0.581865 5.98618C0.433096 6.0072 0.297079 6.07656 0.192941 6.18166C0.0670438 6.30964 -0.00233122 6.48181 5.98176e-05 6.66035C0.00245086 6.83889 0.0764123 7.00918 0.205692 7.13381L4.10981 10.8983L3.18744 16.214C3.16581 16.3376 3.17965 16.4648 3.22738 16.5811C3.27511 16.6973 3.35483 16.7981 3.4575 16.8718C3.56016 16.9456 3.68166 16.9894 3.80823 16.9983C3.9348 17.0072 4.06136 16.9809 4.17356 16.9223L9.00004 14.4127L13.8265 16.9223C13.9583 16.9917 14.1113 17.0148 14.258 16.9896C14.6277 16.9265 14.8764 16.5797 14.8126 16.214L13.8903 10.8983L17.7944 7.13381C17.9007 7.03082 17.9708 6.8963 17.992 6.74917C18.0494 6.38134 17.7901 6.04083 17.4182 5.98618ZM12.2474 10.3686L13.0147 14.7889L9.00004 12.7038L4.98542 14.791L5.75264 10.3707L2.50523 7.23891L6.99379 6.59363L9.00004 2.57272L11.0063 6.59363L15.4949 7.23891L12.2474 10.3686Z'
				fill='#3F4F5E'
			/>
		</svg>
	)
