import styled from 'styled-components'

export const ModalFavorites = styled.div`
	display: flex;
	backdrop-filter: blur(4px);
	z-index: 1;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	position: absolute;
	left: 0;
	top: 0;
`

export const WarningBlockDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 200px;
	width: 450px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 16px;
	border: 1px solid #1baaf0;
	border-radius: 16px;
	background-color: white;
	font-size: 16px;
	line-height: 24px;
	font-family: 'Manrope', sans-serif;
	font-family: 'Manrope', sans-serif;
	color: rgba(0, 0, 0, 0.87);
`

export const Title = styled.div`
	display: flex;
	text-align: center;
	align-items: center;
	padding-bottom: 12px;
`

export const WrapperButton = styled.div`
	display: flex;
`
export const ButtonStyle = styled.div<{ type?: string }>`
	border: 1px solid #3f4f5e;
	height: 32px;
	cursor: pointer;
	display: flex;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	padding: 0 8px;
	margin-right: 8px;
	font-size: 13px;
	font-style: normal;
	align-items: center;
	font-weight: normal;
	line-height: 16px;
	border-radius: 4px;
	color: ${({ type }) => (type === 'cancel' ? 'rgba(0, 0, 0, 0.87)' : '#fff')};
	background-color: ${({ type }) => (type === 'cancel' ? '#f7f7f7' : '#3f4f5e')};
	margin-top: 25px;
	&:hover {
		color: rgba(0, 0, 0, 0.87);
		background-color: #f7f7f7;
	}
`
