import styled from 'styled-components'

export const ButtonStyle = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	font-family: 'Manrope', sans-serif;
	font-family: 'Manrope', sans-serif;
	font-size: 13px;
	line-height: 16px;
	height: 32px;
	padding: 0 8px;
	border-radius: 4px;
	color: #1baaf0 !important;
	border: 1px solid #1baaf0 !important;
	width: max-content;
`

export const InformationBlockDiv = styled.div`
	display: flex;
	align-items: center;
	height: 32px;
	min-width: 250px;
	font-size: 14px;
	line-height: 16px;
`
export const OfferInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #3f4f5e;
	height: 32px;
	width: 50%;
`
export const PlanInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #e0e0e0;
	height: 32px;
	width: 50%;
`
