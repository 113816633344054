import React, { useEffect } from 'react';
import { Switch, Typography, Box,withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '@root-gipro/store/interfaces';
import { updateAssistStatus } from '@root-gipro/modules/userProjects/store/actions';


const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: "8px 0 10px",
      backgroundColor: '#ffffff',
    
    },
    text: {
      fontSize: '16px',
      flexGrow: 1, // Заполняет свободное пространство, чтобы текст и переключатель были на разных сторонах
    },
  }));
  const CustomSwitch = withStyles({
    switchBase: {
        top: 3,
        left: 2,
      color: '#fff',  // Цвет ручки в выключенном состоянии
      '&$checked': {
        color: '#fff',  // Цвет ручки в включенном состоянии
      },
      '&$checked + $track': {
        backgroundColor: '#1baaf0',  // Цвет трека в включенном состоянии
        opacity: 1
      },
      '& + $track': {
        backgroundColor: '#ccc',  // Цвет трека в выключенном состоянии
      },
    },
    checked: {},
    track: {
      borderRadius: 26 / 2,
      backgroundColor: '#ccc',  // Цвет трека в выключенном состоянии
      opacity: 1,
    },
    thumb: {
      width: 20,  // Ширина ручки
      height: 20, // Высота ручки
    },
    root: {
      width: 42,  // Ширина переключателя
      height: 23, // Высота переключателя
      padding: 0,
    },
  })(Switch);

  interface Props {
    handleOffAssist: () => void
  }
const AssistantToggle: React.FC<Props> = ({handleOffAssist}) => {
  const classes = useStyles();
	const { enableUncAssist } = useSelector((state: IState) => state.userProjects)

  const [checked, setChecked] = React.useState(enableUncAssist);
    const dispatch = useDispatch()
  const handleToggle = () => {
    setChecked((prev) => {

        dispatch(updateAssistStatus(!prev == true?"1":"0"))
        handleOffAssist()
        return !prev
    }
);
  };
 
useEffect(()=>{
    setChecked(enableUncAssist)
},[enableUncAssist])

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>Ассистент работы с УНЦ</Typography>
      <CustomSwitch checked={checked} onChange={handleToggle} color="primary" />
    </Box>
  );
};

export default AssistantToggle;
