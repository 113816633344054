import React, { FC } from 'react'
import { ICommonProject } from '@root-gipro/store/interfaces'
import { getNoun } from '@root-gipro/utils/helpers/common'
import ModalTable from '@root-gipro/shared/Table/components/ModalTable'
import { Modal } from '@root-gipro/shared/Modal/Modal'
import { Item } from '@root-gipro/shared/Table/components/Thead'
import { Notices, Plans } from '@root-gipro/modules/commonProjects/models/interfaces/common-projects.model'

interface TableInvestListProps {
	projects: Notices[] | Plans[] | null | ICommonProject[] | undefined;
	onClose(): void;
	investProjectDetailsAnchorEl: boolean | HTMLElement | null;
	theadParams: { theadData: Item[]; gridTemplateColumns: string };
	bodyParams: any;
}

const TableInvestList: FC<TableInvestListProps> = ({
	theadParams,
	projects,
	onClose,
	investProjectDetailsAnchorEl,
	bodyParams,
}) => {
	const title = [
		{
			classes: 'leftTitle',
			value: `${projects && projects?.length} ${
				projects &&
				getNoun(projects?.length, ['инвестиционный проект', 'инвестиционных проекта', 'инвестиционных проектов'])
			}`,
			action: undefined,
		},
		{
			classes: 'rightTitleGray',
			value: 'Назад',
			action: onClose,
		},
	]
	return (
		<Modal title={title} anchorEl={investProjectDetailsAnchorEl} setAnchorEl={onClose} widthWindow='736px'>
			<ModalTable theadParams={theadParams} bodyParams={bodyParams} items={projects} />
		</Modal>
	)
}

export default TableInvestList
