// ЭТОТ ФАЙЛ НЕ ТРОГАТЬ !!!!!

import { AxiosInstance } from 'axios'
import { authorizeApi, modeURL } from '@root-gipro/store/api/index'

const interseptor = (root: AxiosInstance) => {
	let isAlreadyFetchingAccessToken = false
	let subscribers: Array<(access_token: string) => void> = []

	const onAccessTokenFetched = (access_token: string): void => {
		subscribers = subscribers.filter(callback => callback(access_token))
	}

	const addSubscriber = (callback: (access_token: string) => void): void => {
		subscribers.push(callback)
	}

	root.interceptors.response.use(
		response => {
			return response
		},
		error => {
			const {
				config,
				response: { status },
			} = error
			const originalRequest = config

			if (status === 401) {
				if (!isAlreadyFetchingAccessToken) {
					isAlreadyFetchingAccessToken = true

					authorizeApi()
						.then(({ access_token }: { access_token: string }) => {
							originalRequest.headers.Authorization = `Bearer ${access_token}`
							root.defaults.headers.Authorization = `Bearer ${access_token}`
							localStorage.setItem('access_token', access_token)

							isAlreadyFetchingAccessToken = false
							onAccessTokenFetched(access_token)
						})
						.catch(() => (window.location.href = modeURL))
				}

				const retryOriginalRequest = new Promise(resolve => {
					addSubscriber((access_token: string) => {
						originalRequest.headers.Authorization = `Bearer ${access_token}`
						root.defaults.headers.Authorization = `Bearer ${access_token}`
						localStorage.setItem('access_token', access_token)

						resolve(root(originalRequest))
					})
				})
				return retryOriginalRequest
			}

			return Promise.reject(error)
		}
	)
}

export default interseptor

// const interseptor = (root: AxiosInstance) => {
// 	let isRefreshing = false
// 	let failedQueue: any[] = []

// 	const processQueue = (error: any, token: string | null = null) => {
// 		failedQueue.forEach(prom => {
// 			if (error) {
// 				prom.reject(error)
// 			} else {
// 				prom.resolve(token)
// 			}
// 		})

// 		failedQueue = []
// 	}

// 	root.interceptors.response.use(
// 		response => {
// 			return response
// 		},

// 		error => {
// 			const {
// 				config,
// 				response: { status },
// 			} = error
// 			const originalRequest = config

// 			if (status === 401 && !originalRequest.retry && !originalRequest.url.includes('/authorize')) {
// 				console.log(originalRequest.url)
// 				if (isRefreshing) {
// 					return new Promise((resolve, reject) => {
// 						failedQueue.push({ resolve, reject })
// 					})
// 						.then(token => {
// 							root.defaults.headers['Authorization'] = `Bearer ${token}`
// 							originalRequest.headers['Authorization'] = `Bearer ${token}`
// 							root.defaults.headers.Authorization = `Bearer ${token}`
// 							return root(originalRequest)
// 						})
// 						.catch(err => {
// 							return Promise.reject(err)
// 						})
// 				}

// 				originalRequest.retry = true
// 				isRefreshing = true

// 				new Promise((resolve, reject) => {
// 					authorizeApi()
// 						.then(res => {
// 							localStorage.setItem('access_token', res.access_token)

// 							root.defaults.headers['Authorization'] = `Bearer ${res.access_token}`
// 							originalRequest.headers['Authorization'] = `Bearer ${res.access_token}`
// 							root.defaults.headers.Authorization = `Bearer ${res.access_token}`
// 							processQueue(null, res.access_token)

// 							resolve(root(originalRequest))
// 						})
// 						.catch((err: any) => {
// 							processQueue(err, null)
// 							reject(err)
// 							window.location.href = modeURL
// 						})
// 						.finally(() => {
// 							isRefreshing = false
// 						})
// 				})
// 			}

// 			return Promise.reject(error)
// 		}
// 	)
// }

// export default interseptor
