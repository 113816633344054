import React, { FC, useEffect } from 'react'
import List from '@root-gipro/modules/userArchive/components/UserArchive/List'
import { HeaderWrapper } from '@root-gipro/modules/userArchive/components/HeaderWrapper'
import '@root-gipro/modules/userArchive/styles/UserArchive.scss'
import { Text, TextLink, WrapperInformation } from './styles'
import store from '@root-gipro/store'
import FilterContainer from '@root-gipro/modules/filter/FilterContainer'
import { useDispatch, useSelector } from 'react-redux'
import { filtersRequest, setCallbacks, setFilterSortParams } from '@root-gipro/modules/filter/store/actions'
import { useRouteMatch } from 'react-router-dom'
import {
	getCommonProjects,
	loadingCommonProjects,
	setCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { sortUserProjectsArchive } from '../store/actions'
import { userArchivesApi } from '@root-gipro/store/api'
import { IState } from '@root-gipro/store/interfaces/state'
import sortList from '@root-gipro/modules/userArchive/models/consts/sort-list.consts'
import UserArchiveNav from '../components/UserArchive/UserArchiveNav'

const UserArchive: FC = () => {
	// const { policesGipro } = useSelector((state: IState) => state.public)
	const { roleId } = store.getState().auth.user
	const dispatch = useDispatch()
	const routeMatch = useRouteMatch()
	const url: string = routeMatch.path.slice(1)
	const { name, sortName, order } = useSelector((state: IState) => state.userProjects.sort)

	useEffect(() => {
		const params = {
			limit: 5,
		}
		dispatch(setCallbacks(getCommonProjects, userArchivesApi, setCommonProjects, loadingCommonProjects))
		dispatch(filtersRequest(url))
	}, [url])
	const sort = (name: string, sortName: string, order: boolean): void => {
		dispatch(sortUserProjectsArchive(name, sortName, order))
	}

	return (
		<>
			{
				<>
					<div className='user-projects__container'>
						<FilterContainer />

						<div className='projects-wrapper'>
							<HeaderWrapper>
								<UserArchiveNav sort={sort} sortList={sortList} />
							</HeaderWrapper>
							<List />
						</div>
					</div>
				</>
			}
		</>
	)
}

export default UserArchive
