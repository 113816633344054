import { IT7Data, IUnc, IUncAddBuffer, IUncCell, IUncTableRequest, IUserProject } from '@root-gipro/store/interfaces'
import { ReactText } from 'react'

export enum UserProjectsType {
	ADD_NEW_VERSION = 'ADD_NEW_VERSION',
	AUTO_ADD_UNC = 'AUTO_ADD_UNC',
	SET_SELECTED_SECTION_IDS = 'SET_SELECTED_SECTION_IDS',
	СHANGE_VERSION_NAME = 'СHANGE_VERSION_NAME',
	CREATE_PROJECT_TEMPLATE = 'SET_CREATE_PROJECT_TEMPLATE',
	FETCH_USER_PROJECT = 'FETCH_USER_PROJECT',
	SAVE_USER_PROJECT = 'SAVE_USER_PROJECT',
	UPDATE_STATUS_USER_PROJECT = 'UPDATE_STATUS_USER_PROJECT',
	CHOOSE_PROJECT = 'CHOOSE_PROJECT',
	SET_ORIGINAL_OBJ = 'SET_ORIGINAL_OBJ',
	DELETE_USER_PROJECT = 'DELETE_PROJECT',
	SET_UNC_SORT = 'SET_UNC_SORT',
	SET_TOGGLE_TABS = 'SET_TOGGLE_TABS',
	FETCH_REQUEST = 'FETCH_REQUEST',
	UNC_POSITION_EDIT = 'UNC_POSITION_EDIT',
	CHANGE_UNC_CHECKED_TABLE = 'CHANGE_UNC_CHECKED_TABLE',
	ADD_UNC = 'ADD_UNC',
	LOADING_ADD_UNC = 'LOADING_ADD_UNC',
	REPLACE_UNC = 'REPLACE_UNC',
	CREATE_UNCS_B1 = 'CREATE_UNCS_B1',
	CREATE_UNC_B1 = 'CREATE_UNC_B1',
	CREATE_SPECIAL_UNC = 'CREATE_SPECIAL_UNC',
	FETCH_MINI_TABLE = 'FETCH_MINI_TABLE',
	DELETE_UNC = 'DELETE_UNC',
	DELETE_UNC_STATUS = 'DELETE_UNC_STATUS',
	GET_ANALOG_UNC = 'GET_ANALOG_UNC',
	SET_ANALOG_UNC = 'SET_ANALOG_UNC',
	CHANGE_REGION_FACTOR = 'CHANGE_REGION_FACTOR',
	CHANGE_FORM_TWENTY_NAME = 'CHANGE_FORM_TWENTY_NAME',
	FETCH_COST_TABLE = 'FETCH_COST_TABLE',
	APPEND_UNC_SEARCH = 'APPEND_UNC_SEARCH',
	GET_USER_PROJECTS = 'GET_USER_PROJECTS',
	FETCH_UNCS = 'FETCH_UNCS',
	GET_T7_DATA = 'GET_T7_DATA',
	// FETCH_UNC_TABLE_DATA = 'FECHANGE_UNC_COMMENTUNC', // возможно нужно удалить ( перепутал при добавлении )
	FETCH_UNC_TABLE_DATA = 'FETCH_UNC_TABLE_DATA',
	CHANGE_UNC_COMMENT = 'CHANGE_UNC_COMMENT',
	CHANGE_UNC_CF_ARCHITECTURE = 'CHANGE_UNC_CF_ARCHITECTURE',

	CHANGE_UNC_COUNTER = 'CHANGE_UNC_COUNTER',
	CHANGE_UNC_REGION = 'CHANGE_UNC_REGION',
	CHANGE_UNC_VOLTAGE = 'CHANGE_UNC_VOLTAGE',
	SELECT_VOLTAGE = 'SELECT_VOLTAGE',
	UPDATE_DEFLATORS = 'UPDATE_DEFLATORS',
	UPDATE_DEFLATORS_VALUE = 'UPDATE_DEFLATORS_VALUE',
	GET_CHECKED_ERRORS = 'GET_CHECKED_ERRORS',
	DOWNLOAD_RULES_CHECK = 'DOWNLOAD_RULES_CHECK',
	UPLOADER_RULES_CHECK = 'UPLOADER_RULES_CHECK',
	DOWNLOAD_F2 = 'DOWNLOAD_F2',
	UPLOAD_F2 = 'UPLOAD_F2',
	DOWNLOAD_F20 = 'DOWNLOAD_F20',
	DOWNLOAD_F20_ASYNC = 'DOWNLOAD_F20_ASYNC',
	DOWNLOAD_CHECK_WARNS = 'DOWNLOAD_CHECK_WARNS',
	UPLOAD_F20 = 'UPLOAD_F20',
	DOWNLOAD_SVOD = 'DOWNLOAD_SVOD',
	UPLOAD_SVOD = 'UPLOAD_SVOD',
	SHOW_LOADER = 'SHOW_LOADER',
	SHOW__BACKDROP_LOADER = 'SHOW__BACKDROP_LOADER',
	MASS_CHECK_LOADER = 'MASS_CHECK_LOADER',
	LOADING_UPDATE_CALC = 'LOADING_UPDATE_CALC',
	GET_TEMPLATE_UNNORMOLIZED_COSTS = 'GET_TEMPLATE_UNNORMOLIZED_COSTS',
	COPY_PROJECT = 'COPY_PROJECT',
	GET_UNNORMOLIZED_COSTS = 'GET_UNNORMOLIZED_COSTS',
	UPLOAD_UNNORMOLIZED_COSTS = 'UPLOAD_UNNORMOLIZED_COSTS',
	DELETE_UNNORMOLIZED_COSTS = 'DELETE_UNNORMOLIZED_COSTS',
	ADD_PRIVATE_PROJECT = 'ADD_PRIVATE_PROJECT',
	SET_VOLTAGE = 'SET_VOLTAGE',
	SET_UNC_FULL_INFO = 'SET_UNC_FULL_INFO',
	SET_UNC_CELLS = 'SET_UNC_CELLS',
	GET_UNC_CELLS = 'GET_UNC_CELLS',
	SELECT_VOLTAGE_ID = 'SELECT_VOLTAGE_ID',
	SHOW_PROJECT_ERRORS = 'SHOW_PROJECT_ERRORS',
	SET_PROJECT_ERRORS = 'SET_PROJECT_ERRORS',
	SET_MASS_ERRORS = 'SET_MASS_ERRORS',
	SET_PROJECT_FILTER = 'SET_PROJECT_FILTER',
	SET_INTERPOLATION = 'SET_INTERPOLATION',
	SET_PROJECT_YEARS = 'SET_PROJECT_YEARS',
	SET_UNC_FILTER = 'SET_UNC_FILTER',
	SET_UNC_ADD_BUFFER = 'SET_UNC_ADD_BUFFER',
	SET_UNC_FILTER_BUFFER = 'SET_UNC_FILTER_BUFFER',
	SELECT_TABLE_ELEM = 'SELECT_TABLE_ELEM',
	SET_ACTUAL_FUNDING = 'SET_ACTUAL_FUNDING',
	SET_OFFER_RATIONALE = 'SET_OFFER_RATIONALE',
	SET_ADDITIONAL_FUNDING_OFFER = 'SET_ADDITIONAL_FUNDING_OFFER',
	SET_VALUATION_OFFER_FORECAST = 'SET_VALUATION_OFFER_FORECAST',
	SET_FINANCIAL_NEEDS = 'SET_FINANCIAL_NEEDS',
	SET_YEARS = 'SET_YEARS',
	SET_PROJECT_NAME = 'SET_PROJECT_NAME',
	SET_PROJECT_СODE = 'SET_PROJECT_СODE',
	SET_PROJECT_UNCS = 'SET_PROJECT_UNCS',
	SET_T7_DATA = 'SET_T7_DATA',
	SAVE_T7_DATA = 'SAVE_T7_DATA',
	SET_IS_NEW_PROJECT = 'SET_IS_NEW_PROJECT',
	SET_NDS = 'SET_NDS',
	SET_ACTIVE_UNCS = 'SET_ACTIVE_UNCS',
	SET_UNC_EDIT_MODE = 'SET_UNC_EDIT_MODE',
	SET_T7_EDIT_MODE = 'SET_T7_EDIT_MODE',
	SET_USER_PROJECTS = 'SET_USER_PROJECTS',
	APPEND_USER_PROJECTS = 'APPEND_USER_PROJECTS',
	ADD_USER_PROJECTS = 'ADD_USER_PROJECTS',
	SET_ALLOW_MORE_USER_PROJECTS = 'SET_ALLOW_MORE_USER_PROJECTS',
	SET_USER_PROJECTS_COUNT = 'SET_USER_PROJECTS_COUNT',
	LOADING_USER_PROJECTS = 'LOADING_USER_PROJECTS',
	LOADING_T7DATA = 'LOADING_T7DATA',
	SET_REGION_IDS = 'SET_REGION_IDS',
	SET_FILIAL_IDS = 'SET_FILIAL_IDS',
	SET_UNC_TABLE_DATA = 'SET_UNC_TABLE_DATA',
	SET_VOLTAGE_ID = 'SET_VOLTAGE_ID',
	SORT_USER_PROJECTS = 'SORT_USER_PROJECTS',
	SET_GROUP_TYPE = 'SET_GROUP_TYPE',
	SET_PHASE = 'SET_PHASE',
	SET_DECREE_NAME = 'SET_DECREE_NAME',
	SET_DECREE_NUM = 'SET_DECREE_NUM',
	SET_NOT_EXCEED = 'SET_NOT_EXCEED',
	TECH_CONNECT = 'TECH_CONNECT',
	ONLY_PIR = 'ONLY_PIR',
	LOCKED = 'LOCKED',
	SET_LOCKED_IN_LIST = 'SET_LOCKED_IN_LIST',
	SET_USER_PROJECT_IN_LIST = 'SET_USER_PROJECT_IN_LIST',
	SET_EXPORTABLE = 'SET_EXPORTABLE',
	MASS_CHECK = 'MASS_CHECK',
	SET_F20_TASK = 'SET_F20_TASK',
	CHECK_F20_LINK = 'CHECK_F20_LINK',
	SET_F20_LINK = 'SET_F20_LINK',
	SET_T7_VERSION = 'SET_T7_VERSION',
	GET_DEFLATORS = 'GET_DEFLATORS',
	SET_DEFLATORS = 'SET_DEFLATORS',
	UPDATE_CALC = 'UPDATE_CALC',
	GET_NON_STANDARDIZED_COSTS = 'GET_NON_STANDARDIZED_COSTS',
	SET_NON_STANDARDIZED_COSTS = 'SET_NON_STANDARDIZED_COSTS',
	SET_ACTIVE_TOTAL = 'SET_ACTIVE_TOTAL',
	SET_LOADING_STATE = 'SET_LOADING_STATE',
	SET_DATA_IMPORT_MAGAZINE = 'SET_DATA_IMPORT_MAGAZINE',
	SET_MODAL_OPEN_IMPORT_MAGAZINE = 'SET_MODAL_OPEN_IMPORT_MAGAZINE',
	SET_DOWNLOAD_IMPORT_DATA = 'SET_DOWNLOAD_IMPORT_DATA',
	SHOW_WARNING_UNC_KF = 'SHOW_WARNING_UNC_KF',
	SET_ASSIST_STATUS = 'SET_ASSIST_STATUS',
	GET_ASSIST_STATUS = 'GET_ASSIST_STATUS',
	UPDATE_ASSIST_STATUS = 'UPDATE_ASSIST_STATUS',
	SET_PROGRESS_UPDATE = 'SET_PROGRESS_UPDATE',
	GET_PROGRESS_UPDATE = 'GET_PROGRESS_UPDATE',
}

interface AddNewVersion {
	type: UserProjectsType.ADD_NEW_VERSION
	data: IUserProject
	versionName: string
	currentUrl: string
	uncSort?: string
}
interface ChangeVersionName {
	type: UserProjectsType.СHANGE_VERSION_NAME
	versionName: string
}
interface LoadingAddUnc {
	type: UserProjectsType.LOADING_ADD_UNC
	loading: boolean
}
interface FetchRequest {
	type: UserProjectsType.FETCH_REQUEST
}
interface SetUncEditMode {
	type: UserProjectsType.SET_UNC_EDIT_MODE
	mode: boolean
}
interface ChangeUncCheckedTable {
	type: UserProjectsType.CHANGE_UNC_CHECKED_TABLE
	uncCheckedTable: number
}
interface SetT7EditMode {
	type: UserProjectsType.SET_T7_EDIT_MODE
	mode: boolean
}
interface SetUserProjects {
	type: UserProjectsType.SET_USER_PROJECTS
	projects: IUserProject[]
	totalInvestmentUncOfferSum: number
	userProjectsCnt: number
}
interface SetSelectedSectionIds {
	type: UserProjectsType.SET_SELECTED_SECTION_IDS
	selectedSectionIds: number[]
}

interface AppendUserProjects {
	type: UserProjectsType.APPEND_USER_PROJECTS
	projectsCount: number
}
interface AddUserProjects {
	type: UserProjectsType.ADD_USER_PROJECTS
	projects: IUserProject[]
}
interface SetAllowMoreUserProjects {
	type: UserProjectsType.SET_ALLOW_MORE_USER_PROJECTS
	allowMoreUserProjects: boolean
}

interface SetUserProjectsPaginationCount {
	type: UserProjectsType.SET_USER_PROJECTS_COUNT
	paginationCounter: number
}

interface LoadingUserProjects {
	type: UserProjectsType.LOADING_USER_PROJECTS
	loading: boolean
}
interface LoadingT7Data {
	type: UserProjectsType.LOADING_T7DATA
	loading: boolean
}
interface LoadingUpdateCalc {
	type: UserProjectsType.LOADING_UPDATE_CALC
	loading: boolean
}
interface LoadingMassCheck {
	type: UserProjectsType.MASS_CHECK_LOADER
	loading: boolean
}

interface ShowProjectErrors {
	type: UserProjectsType.SHOW_PROJECT_ERRORS
	show: boolean
}

interface CreateProjectTemplate {
	type: UserProjectsType.CREATE_PROJECT_TEMPLATE
}
interface ChooseProject {
	type: UserProjectsType.CHOOSE_PROJECT
	project: IUserProject
}
interface SetOriginal {
	type: UserProjectsType.SET_ORIGINAL_OBJ
	project: IUserProject
}
interface SetActiveUncs {
	type: UserProjectsType.SET_ACTIVE_UNCS
	uncs: IUnc[]
}

interface SetProjectUncs {
	type: UserProjectsType.SET_PROJECT_UNCS
	uncs: IUnc[]
}
interface SetT7Data {
	type: UserProjectsType.SET_T7_DATA
	t7Data: IT7Data
}
interface SaveT7Data {
	type: UserProjectsType.SAVE_T7_DATA
	t7Data: IT7Data
	version: number
}
interface SetIsNewProject {
	type: UserProjectsType.SET_IS_NEW_PROJECT
	isNew: boolean
}

interface SetProjectName {
	type: UserProjectsType.SET_PROJECT_NAME
	name: string
}

interface SetProjectYears {
	type: UserProjectsType.SET_YEARS
	begin: number | null
	end: number | null
}

interface SetProjectValuation {
	type: UserProjectsType.SET_VALUATION_OFFER_FORECAST
	valuation: number
}
interface SetProjectFinancialNeeds {
	type: UserProjectsType.SET_FINANCIAL_NEEDS
	financial_needs_withNds: number
}
interface SetProjectAdditional {
	type: UserProjectsType.SET_ADDITIONAL_FUNDING_OFFER
	offer: number
}
interface SetProjectRationale {
	type: UserProjectsType.SET_OFFER_RATIONALE
	offerRationale: string
}
interface SetProjectActual {
	type: UserProjectsType.SET_ACTUAL_FUNDING
	actualFunding: number
}
interface SetProjectNds {
	type: UserProjectsType.SET_NDS
	nds: number
}
interface SetProjectGroupNum {
	type: UserProjectsType.SET_GROUP_TYPE
	groupNum: string
}
interface SetProjectPhase {
	type: UserProjectsType.SET_PHASE
	phase: string
}
interface SetProjectDecreeName {
	type: UserProjectsType.SET_DECREE_NAME
	decreeName: string
}
interface SetProjectDecreeNumber {
	type: UserProjectsType.SET_DECREE_NUM
	decreeNumber: string
}
interface SetProjectExceeding {
	type: UserProjectsType.SET_NOT_EXCEED
	notExceeding: number | null
}
interface SelectTableElem {
	type: UserProjectsType.SELECT_TABLE_ELEM
	selectedElem: Omit<IUncTableRequest, 'uncTable'> | null
}
interface ClearBuffer {
	type: UserProjectsType.SET_UNC_ADD_BUFFER
	buffer: IUncAddBuffer[]
}
interface FilterBuffer {
	type: UserProjectsType.SET_UNC_FILTER_BUFFER
	buffer: IUncAddBuffer[]
}

interface SetYears {
	type: UserProjectsType.SET_PROJECT_YEARS
	years: { [key: string]: { offer: number | null; deflator: number | null } }
}
interface SetInterpolation {
	type: UserProjectsType.SET_INTERPOLATION
	obj: { [key: string]: string }
}

interface SetProjectFilter {
	type: UserProjectsType.SET_PROJECT_FILTER
	filter: string
}

interface SetProjectErrors {
	type: UserProjectsType.SET_PROJECT_ERRORS
	errors: { [key: string]: string[] } | null
}
interface SetMassErrors {
	type: UserProjectsType.SET_MASS_ERRORS
	errors: { userProjectCode: string; messages: { [key: string]: string[] }; id: string }[] | null
}

interface SetVoltage {
	type: UserProjectsType.SET_VOLTAGE
	targetVoltageValues: { [key: string]: string }
	uncId: ReactText
}
interface SetUncFullInfo {
	type: UserProjectsType.SET_UNC_FULL_INFO
	voltage: any
}
interface GetUncCells {
	type: UserProjectsType.GET_UNC_CELLS
}
interface SetUncCells {
	type: UserProjectsType.SET_UNC_CELLS
	uncCells: IUncCell[]
}
interface SetProjectCode {
	type: UserProjectsType.SET_PROJECT_СODE
	code: string
}

interface SetRegionIds {
	type: UserProjectsType.SET_REGION_IDS
	regionIds: string[]
}
interface SetFilialIds {
	type: UserProjectsType.SET_FILIAL_IDS
	filialId: string
}

interface SetUncTableData {
	type: UserProjectsType.SET_UNC_TABLE_DATA
	uncTableData: any
}

interface SetUncFilter {
	type: UserProjectsType.SET_UNC_FILTER
	filter: string
}

interface SetUncFilter {
	type: UserProjectsType.SET_UNC_FILTER
	filter: string
}

interface SetVoltageId {
	type: UserProjectsType.SET_VOLTAGE_ID
	targetVoltageId: string
	uncId: string
}
interface SortUserProjects {
	type: UserProjectsType.SORT_USER_PROJECTS
	name: string
	sortName: string
	order: boolean
}
interface ShowLoader {
	type: UserProjectsType.SHOW_LOADER
	showLoader: boolean
}

interface ShowBackdropLoader {
	type: UserProjectsType.SHOW__BACKDROP_LOADER
	showBackdropLoader: boolean
}
interface SetTechConnect {
	type: UserProjectsType.TECH_CONNECT
	techConnect: boolean
}
interface SetOnlyPIR {
	type: UserProjectsType.ONLY_PIR
	onlyPIR: boolean
}
interface SetLocked {
	type: UserProjectsType.LOCKED
	locked: boolean
}
interface SetLockedInList {
	type: UserProjectsType.SET_LOCKED_IN_LIST
	locked: boolean
}
interface SetUserProjectInList {
	type: UserProjectsType.SET_USER_PROJECT_IN_LIST
	project: IUserProject
}
interface SetExportable {
	type: UserProjectsType.SET_EXPORTABLE
	exportable: boolean
}
interface MassCheck {
	type: UserProjectsType.MASS_CHECK
	sectionIdsArr: number[]
}
interface SetF20Task {
	type: UserProjectsType.SET_F20_TASK
	task: string | null
}
interface CheckF20Link {
	type: UserProjectsType.CHECK_F20_LINK
	task: string | null
}

interface SetF20Link {
	type: UserProjectsType.SET_F20_LINK
	f20link: string | null
}

interface SetT7Version {
	type: UserProjectsType.SET_T7_VERSION
	t7Version: number | null
}
interface SetDeflators {
	type: UserProjectsType.SET_DEFLATORS
	deflators: { year: number; value: string }[]
	precision: number
}
interface UpdateCalc {
	type: UserProjectsType.UPDATE_CALC
	params: any
}
interface SetUncSort {
	type: UserProjectsType.SET_UNC_SORT
	unc_sort: string
}
interface SetNonStandardizedCosts {
	type: UserProjectsType.SET_NON_STANDARDIZED_COSTS
	non_standardized_costs: any
}
interface GetNonStandardizedCosts {
	type: UserProjectsType.GET_NON_STANDARDIZED_COSTS
}

interface SetActiveTotal {
	type: UserProjectsType.SET_ACTIVE_TOTAL
	activeTotal: number | null
}

interface SetLoadingStatesAction {
	type: UserProjectsType.SET_LOADING_STATE
	payload: { itemKey: string; isLoading: boolean }
}

interface SetDataImportMagazine {
	type: UserProjectsType.SET_DATA_IMPORT_MAGAZINE
	data: any
	link: string
}

interface SetModalImportMagazine {
	type: UserProjectsType.SET_MODAL_OPEN_IMPORT_MAGAZINE
	open: boolean
}
interface SetShowWarningUncIds {
	type: UserProjectsType.SHOW_WARNING_UNC_KF
	showWarningUncIds: any[]
}
interface SetAssistStatus {
	type: UserProjectsType.SET_ASSIST_STATUS
	enableUncAssist: boolean
}
interface GetAssistStatus {
	type: UserProjectsType.GET_ASSIST_STATUS
}
interface UpdateAssistStatus {
	type: UserProjectsType.UPDATE_ASSIST_STATUS
	enableUncAssist: string
}
interface DeleteUncStatus {
	type: UserProjectsType.DELETE_UNC_STATUS
	isDeleted: boolean
}
interface GetUpdateProgressPrecent {
	type: UserProjectsType.GET_PROGRESS_UPDATE
	processName: string
	userId: string | number
}

interface SetProgressPrecent {
	type: UserProjectsType.SET_PROGRESS_UPDATE
	progressPrecent: string | number
}
export type UserProjectActionsTypes =
	| SetVoltageId
	| SetUncFilter
	| SetUncTableData
	| SetRegionIds
	| SetFilialIds
	| SetProjectCode
	| SetUserProjects
	| AppendUserProjects
	| AddUserProjects
	| SetAllowMoreUserProjects
	| SetUserProjectsPaginationCount
	| LoadingUserProjects
	| SetVoltage
	| SetProjectErrors
	| SetMassErrors
	| SetProjectFilter
	| SetInterpolation
	| SetYears
	| ClearBuffer
	| SelectTableElem
	| SetProjectNds
	| SetProjectGroupNum
	| SetProjectPhase
	| SetProjectDecreeName
	| SetProjectDecreeNumber
	| SetProjectExceeding
	| SetProjectActual
	| SetProjectRationale
	| SetProjectAdditional
	| SetProjectValuation
	| SetProjectFinancialNeeds
	| SetProjectYears
	| SetProjectName
	| SetIsNewProject
	| SetProjectUncs
	| SetActiveUncs
	| SetOriginal
	| ChooseProject
	| FetchRequest
	| CreateProjectTemplate
	| SetUncEditMode
	| ShowProjectErrors
	| SortUserProjects
	| ShowLoader
	| ShowBackdropLoader
	| SetT7Data
	| SetT7EditMode
	| SaveT7Data
	| SetTechConnect
	| SetOnlyPIR
	| SetLocked
	| SetLockedInList
	| SetUserProjectInList
	| SetExportable
	| LoadingT7Data
	| LoadingUpdateCalc
	| MassCheck
	| LoadingMassCheck
	| SetF20Task
	| CheckF20Link
	| SetF20Link
	| SetT7Version
	| SetDeflators
	| UpdateCalc
	| ChangeVersionName
	| AddNewVersion
	| SetUncSort
	| ChangeUncCheckedTable
	| SetNonStandardizedCosts
	| GetNonStandardizedCosts
	| SetSelectedSectionIds
	| SetActiveTotal
	| SetLoadingStatesAction
	| SetDataImportMagazine
	| SetModalImportMagazine
	| SetUncFullInfo
	| SetUncCells
	| GetUncCells
	| FilterBuffer
	| SetAssistStatus
	| GetAssistStatus
	| UpdateAssistStatus
	| SetShowWarningUncIds
	| DeleteUncStatus
	| GetUpdateProgressPrecent
	| SetProgressPrecent
	| LoadingAddUnc
