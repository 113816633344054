import { IParams } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { ICommonProject, IUncInfo } from '@root-gipro/store/interfaces'
import { PurchasesTypeEnum } from '../enums/purchases-type.enum'
import { IPurchaseFilterState } from './purchase-filter-state.model'
import { IPurchase } from './purchase.model'
import { IPurchasesList } from './purchases-list-request'
import { IPurchasesViewState } from './purchases-view-state.model'

export enum PurchasesType {
	GET_ALL_FAVORITE_PURCHASES = 'GET_ALL_FAVORITE_PURCHASES',
	GET_LIST = 'PURCHASES/GET_LIST',
	SET_PURCHASES = 'PURCHASES/SET_PURCHASES',
	REFRESH_FILTER_STATE = 'PURCHASES/REFRESH_FILTER_STATE',
	REFRESH_PURCHASES_STATE = 'PURCHASES/REFRESH_PURCHASES_STATE',
	EXPAND_PURCHASE_CARD_LIST = 'PURCHASES/EXPAND_PURCHASE_CARD_LIST',
	SORT_PURCHASES = 'PURCHASES/SORT_PURCHASES',
	CHANGE_PURCHASES_TYPE = 'PURCHASES/CHANGE_PURCHASES_TYPE',
	SET_FILTER_COUNT_RESULT = 'PURCHASES/SET_FILTER_COUNT_RESULT',
	SHOW_FILTER_RESULT = 'PURCHASES/SHOW_FILTER_RESULT',
	SELECT_PURCHASE = 'PURCHASES/SELECT_PURCHASE',
	SET_SELECTED_PURCHASE = 'PURCHASES/SET_SELECTED_PURCHASE',
	APPEND_PURCHASES = 'PURCHASES/APPEND_PURCHASES',
	SET_PURCHASE_ITEMS = 'PURCHASES/SET_PURCHASE_ITEMS',
	APPEND_PURCHASE_INFO = 'PURCHASES/APPEND_PURCHASE_INFO',
	REFRESH_LIST = 'PURCHASES/REFRESH_LIST',
	TOGGLE_LOADING_LIST = 'PURCHASES/TOGGLE_LOADING_LIST',
	SET_PURCHASES_PAGINATION_COUNTER = 'SET_PURCHASES_PAGINATION_COUNTER',

	ACTIONS_FAVORITE_PURCHASES = 'ACTIONS_FAVORITE_PURCHASES',
	SET_FILTER_IDS_PURCHASES = 'SET_FILTER_IDS_PURCHASES',
	SET_PURCHASE_FAVORITE = 'SET_PURCHASE_FAVORITE',
}

interface ActionsFavoritePurchases {
	type: PurchasesType.ACTIONS_FAVORITE_PURCHASES
	projectId: string | 'multiple'
	action: 'DELETE' | 'POST'
}

interface SetPurchasesFavorite {
	type: PurchasesType.SET_PURCHASE_FAVORITE
	list: IPurchase[]
}

interface GetPurchasesList {
	type: PurchasesType.GET_LIST
}

interface RefreshList {
	type: PurchasesType.REFRESH_LIST
	list: IPurchasesList
	isFavorite: boolean
}

interface ToggleLoadingPurchaseList {
	type: PurchasesType.TOGGLE_LOADING_LIST
}

interface SetPurchases {
	type: PurchasesType.SET_PURCHASES
	state: {
		list: IPurchase[]
		count: number
		mSum: number
		iSum: number
		purchasesType: PurchasesTypeEnum
		state: IPurchasesViewState
		sortParams: IParams
		uncInfo?: IUncInfo
		selectedPurchase?: IPurchase
		paginationCounter: number
	}
	isFavorite: boolean
}

interface RefreshPurchasesState {
	type: PurchasesType.REFRESH_PURCHASES_STATE
	fieldName: keyof IPurchasesViewState
	value: boolean
}

interface SetExpandCardState {
	type: PurchasesType.EXPAND_PURCHASE_CARD_LIST
	purchase: IPurchase
}

interface SortPurchaseList {
	type: PurchasesType.SORT_PURCHASES
	name: string
	sortName: string
	order?: boolean
}

interface ChangePurchasesType {
	type: PurchasesType.CHANGE_PURCHASES_TYPE
	purchasesType: PurchasesTypeEnum
}

interface SetFilterCountResult {
	type: PurchasesType.SET_FILTER_COUNT_RESULT
	count: number
	fieldName: string
	value: string
}

interface ShowFilterResult {
	type: PurchasesType.SHOW_FILTER_RESULT
	filterParams: IPurchaseFilterState
}

interface SelectPurchase {
	type: PurchasesType.SELECT_PURCHASE
	purchaseID: number | string
	purchaseType: PurchasesTypeEnum
}

interface SetSelectedPurchase {
	type: PurchasesType.SET_SELECTED_PURCHASE
	selectedPurchase: IPurchase
}

interface AppendPurchases {
	type: PurchasesType.APPEND_PURCHASES
}

interface SetPurchasesPaginationCounter {
	type: PurchasesType.SET_PURCHASES_PAGINATION_COUNTER
	paginationCounter: number
}

interface SetPurchaseItems {
	type: PurchasesType.SET_PURCHASE_ITEMS
	items: IPurchase[]
}

interface AppendPurchaseInfo {
	type: PurchasesType.APPEND_PURCHASE_INFO
	id: string
	fieldName: string
	value: ICommonProject[]
}

export interface SetFilterIdsPurchases {
	type: PurchasesType.SET_FILTER_IDS_PURCHASES
	IDs: string[]
}

export type PurchasesTypes =
	| SetFilterIdsPurchases
	| SetFilterCountResult
	| ChangePurchasesType
	| RefreshPurchasesState
	| SetPurchases
	| GetPurchasesList
	| RefreshList
	| SetExpandCardState
	| ToggleLoadingPurchaseList
	| SortPurchaseList
	| ShowFilterResult
	| SetSelectedPurchase
	| AppendPurchases
	| SetPurchasesPaginationCounter
	| SetPurchaseItems
	| AppendPurchaseInfo
	| ActionsFavoritePurchases
	| SetPurchasesFavorite
