import React, { useState, useRef, useEffect } from 'react'
import { setInterpolation } from '@root-gipro/modules/userProjects/store/actions'
import { connect } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'
import { ListItem } from '@material-ui/core/'

function changeHandler(arr: any, interpolation: any, action: any) {
	const clone = Object.assign({}, interpolation)
	arr.forEach((elem: any) => {
		clone[elem[0]] = elem[1]
	})
	action(clone)
}

const mapStateToProps = (state: IState) => {
	return {
		interpolation: state.userProjects.interpolation,
	}
}

const mapDispatchToProps = (dispatch: (arg0: { type: string; obj: any }) => any) => {
	return {
		setInterpolation: (obj: any) => dispatch(setInterpolation(obj)),
	}
}

const Select: React.FC<any> = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ data, interpolation, setInterpolation, updateIndexToInterPol }: any) => {
	const [value, setValue] = useState(Array.isArray(data?.cases) ? data?.cases[0] : '')
	const [show, setShow] = useState(false)
	const [byHands, setBy] = useState(false)
	const [index, setIndex] = useState(0)
	const [subSelects, setSubSelects] = useState<any>(null)

	const listElem = useRef<any>(null)
	const titleElem = useRef<any>(null)
	const outside = (e: any) => {
		if (
			listElem.current &&
			titleElem.current &&
			!listElem.current.contains(e.target) &&
			!titleElem.current.contains(e.target)
		) {
			setShow(false)
		}
	}

	useEffect(() => {
		document.addEventListener('click', outside)
		return () => document.removeEventListener('click', outside)
	})

	const handleChangeIndex = (index: number) => {
		if (typeof updateIndexToInterPol === 'function') {
			updateIndexToInterPol(index)
		}
	}

	useEffect(() => {
		if (Array.isArray(data.cases)) {
			setValue(data.cases[0])
			changeHandler([[data.key, data.cases[0]]], interpolation, setInterpolation)
		}
	}, [data])

	useEffect(() => {
		data.selects && setSubSelects(Object.values(data.selects))
	}, [data.selects])

	return (
		<>
			<div className='interpol_elem'>
				<div className='interpol_title'>{data.title}</div>
				<div
					ref={titleElem}
					onClick={() => {
						setShow(true)
					}}
					className='current_value'
				>
					{byHands ? (
						<input
							onClick={e => e.stopPropagation()}
							className='hands_input'
							onChange={e => {
								let val = e.currentTarget.value
								val = val.replace(/[^0-9.,]/, '')
								val = val.replace(/,/, '.')
								setValue(val)
								changeHandler([[data.key, val]], interpolation, setInterpolation)
							}}
							value={value}
							placeholder='Значение'
						/>
					) : (
						<div className='interpol_value'>{value}</div>
					)}
				</div>
				{show && (
					<div ref={listElem} className='interpol_list'>
						{Array.isArray(data.cases)
							? data.cases.map((elem: any, ind: number) => {
									if (elem) {
										return (
											<ListItem
												button
												key={`${ind}_${performance.now}`}
												onClick={e => {
													e.stopPropagation()
													setBy(false)
													setValue(elem)
													setIndex(ind)
													handleChangeIndex(ind)
													changeHandler([[data.key, elem]], interpolation, setInterpolation)
													setTimeout(() => setShow(false), 0)
												}}
											>
												{elem}
											</ListItem>
										)
									} else {
										return (
											<ListItem
												button
												key={`${ind}_${performance.now}`}
												onClick={() => {
													setValue('')
													setBy(true)
													setIndex(ind)
													handleChangeIndex(ind)
													changeHandler(
														[
															[data?.key, ''],
															[data?.selects?.[0].key, 'deleted'],
														],
														interpolation,
														setInterpolation
													)
													setTimeout(() => setShow(false), 0)
												}}
											>
												Ввести значение
											</ListItem>
										)
									}
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  })
							: null}
					</div>
				)}
			</div>

			{subSelects &&
				subSelects[index] &&
				(Array.isArray(subSelects[index]) ? (
					subSelects[index].map((item: any, idx: number) =>
						item.type === 'select' || item.type === 'input-select' ? (
							<Select key={idx} changeHandler={changeHandler} data={item} />
						) : (
							<Input key={idx} changeHandler={changeHandler} data={item} />
						)
					)
				) : subSelects[index].type === 'select' || subSelects[index].type === 'input-select' ? (
					<Select changeHandler={changeHandler} data={subSelects[index]} />
				) : (
					<Input changeHandler={changeHandler} data={subSelects[index]} />
				))}
		</>
	)
})

const Input: React.FC<any> = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ data, interpolation, setInterpolation }: any) => {
	const [value, setValue] = useState('')

	return (
		<div className='interpol_elem'>
			<div className='interpol_title'>{data.title}</div>
			<input
				type='text'
				onChange={e => {
					let val = e.currentTarget.value
					val = val.replace(/[^0-9.,]/, '')
					val = val.replace(/,/, '.')
					setValue(val)
					changeHandler([[data.key, val]], interpolation, setInterpolation)
				}}
				value={value}
			/>
		</div>
	)
})

const InterPolL7: React.FC<any> = ({ data, setInterpolation }) => {
	const [index, setIndex] = useState(0)
	useEffect(() => {
		const obj: any = {}

		function updateObj2(data: any, obj: any = {}) {
			if (Array.isArray(data)) {
				data.forEach((elem: any) => {
					if (!elem) return
					const key = elem.key

					if (elem.selects) {
						updateObj2(elem.selects, obj)
					}

					if (elem.type === 'select' || elem.type === 'input-select') {
						if (Array.isArray(elem?.cases)) obj[key] = elem?.cases[0]
					} else {
						obj[key] = ''
					}
					if (obj[key]) return
				})
			} else {
				const key = data.key
				if (data.type === 'select' || data.type === 'input-select') {
					if (Array.isArray(data?.cases)) obj[key] = data?.cases[0]
				} else if (data.selects) {
					obj = updateObj2(data.selects, obj)
				} else {
					obj[key] = ''
				}
			}
			return obj
		}

		function updateObj(data: any, obj: any = {}) {
			data.forEach((elem: any, inx: number) => {
				const key = elem.key

				if (key && !obj[key]) {
					if (elem.type === 'select' || elem.type === 'input-select') {
						if (Array.isArray(elem?.cases)) {
							obj = elem.cases.map((caseValue: any, caseIndex: any) => {
								const childSelects = elem.selects?.[caseIndex] || null
								return childSelects
									? {
											selects: { ...updateObj2(childSelects), [key]: caseValue },
									  }
									: caseIndex
							})
						}
					} else {
						obj[key] = ''
					}
				}
			})

			return obj
		}

		const resultObj = updateObj(data, obj)
		setInterpolation(resultObj[index]?.selects)
	}, [index])

	const setIndexToInterPol = (inx: number) => {
		setIndex(inx)
	}

	return (
		<div className='interpol'>
			{Object.values(data).map((elem: any, ind: number) => {
				return (
					<>
						{elem.type === 'select' || elem.type === 'input-select' ? (
							<Select data={elem} updateIndexToInterPol={setIndexToInterPol} key={`${ind}_${performance.now}`} />
						) : (
							<Input data={elem} key={`${ind}_${performance.now}`} />
						)}
					</>
				)
			})}
		</div>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(InterPolL7)
