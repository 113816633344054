import React, { MutableRefObject, memo, useEffect, useRef, useState, FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { BreadcrumbsComponent } from '@root-gipro/shared/BreadcrumbsComponent/BreadcrumbsComponent'
import {
	getSelectCommonProject,
	getUncsCommonProject,
	getUncTechnicalSpecificationsCommonProject,
} from '@root-gipro/modules/commonProjects/store/actions'
import { searchByKey } from '@root-gipro/utils/helpers/common'
import { IState } from '@root-gipro/store/interfaces/state'
import { Routes } from '@root-gipro/router'
import { usePageLink } from '@root-gipro/shared/hooks/usePageLink'
import { fetchUncs } from '@root-gipro/modules/userProjects/store/actions'
import { fetchUserProject } from '@root-gipro/modules/userProjects/store/actions'
import { TypesNavLink } from '@root-gipro/shared/TabsComponents/tabs.props'
import { fetchUserProjectArchive, fetchUncsArchive } from '@root-gipro/modules/userArchive/store/actions'

const SelectedContainer: FC = memo(({ children }) => {
	const { selectedProject } = useSelector((state: IState) => state.commonProjects)
	const { selectedPurchase } = useSelector((state: IState) => state.purchases)
	const { groups } = useSelector((state: IState) => state.public)
	const [typesRoute, setTypesRoute] = useState<TypesNavLink[]>()
	const [group, setGroup] = useState<number>()
	const { pathRoute, nameLink }: { currentPath: string; pathRoute: string; nameLink: string } = usePageLink()
	const { pathname } = useLocation()

	const currentUrl = pathRoute.split('/')[1]
	const currentState = currentUrl === 'user-archive' ? 'userArchives' : 'userProjects'
	const { originalObj, projectObj } = useSelector((state: IState) => state[currentState])
	const dispatch = useDispatch()
	const urlId: MutableRefObject<string | undefined> = useRef()

	useEffect(() => {
		urlId.current = pathname.replace(/[^\d]/g, '')

		const checkUrl =
			pathname.includes('parameters') || pathname.includes('rates') || pathname.includes('construction-resources')

		if (pathRoute === Routes.COMMON_PROJECTS && checkUrl && urlId.current && groups.length && !selectedProject?.groupId) {
			dispatch(getSelectCommonProject(urlId.current))
			dispatch(getUncsCommonProject(urlId.current))
			dispatch(getUncTechnicalSpecificationsCommonProject(urlId.current))
		}

		if (urlId.current !== '0' && pathRoute === Routes.USER_PROJECTS && groups.length) {
			dispatch(fetchUserProject(urlId.current))
			dispatch(fetchUncs(urlId.current))
		} else if (urlId.current !== '0' && pathRoute === Routes.USER_ARCHIVE) {
			dispatch(fetchUserProjectArchive(urlId.current))
			dispatch(fetchUncsArchive(urlId.current))
		}

		const routeCommonProjectsParameters = Routes.COMMON_PROJECTS_PARAMETERS.replace(/:id/g, urlId.current)
		const routeCommonProjectsRates = Routes.COMMON_PROJECTS_RATES.replace(/:id/g, urlId.current)

		const routeUserProjectsRates = Routes.USER_PROJECTS_RATES.replace(/:id/g, urlId.current)
		const routeUserProjectsParameters = Routes.USER_PROJECTS_PARAMETERS.replace(/:id/g, urlId.current)
		const routeUserArchivesRates = Routes.USER_ARCHIVE_RATES.replace(/:id/g, urlId.current)
		const routeUserArchivesParameters = Routes.USER_ARCHIVE_PARAMETERS.replace(/:id/g, urlId.current)

		if (
			pathname === routeCommonProjectsParameters &&
			groups &&
			selectedProject &&
			selectedProject?.groupId !== '' &&
			selectedProject?.groupId !== undefined
		) {
			setGroup(searchByKey(groups, 'id', selectedProject?.groupId))
		}
		const typesRoute = [
			{
				title: 'Параметры',
				path:
					pathRoute === Routes.COMMON_PROJECTS
						? routeCommonProjectsParameters
						: pathRoute === Routes.USER_PROJECTS
						? routeUserProjectsParameters
						: routeUserArchivesParameters,
				tags: [Routes.COMMON_PROJECTS, Routes.USER_PROJECTS, Routes.USER_ARCHIVE],
			},
			{
				title: 'Расценки',
				path:
					pathRoute === Routes.COMMON_PROJECTS
						? routeCommonProjectsRates
						: pathRoute === Routes.USER_PROJECTS
						? routeUserProjectsRates
						: routeUserArchivesRates,
				tags: [Routes.COMMON_PROJECTS, Routes.USER_PROJECTS, Routes.USER_ARCHIVE],
			},
		]
		setTypesRoute(typesRoute)
	}, [groups, pathname, selectedProject?.groupId, selectedPurchase?.company_id])
	return (
		<>
			<div className='container'>
				<BreadcrumbsComponent
					typesRoute={typesRoute as TypesNavLink[]}
					group={groups[group as number] || originalObj}
					path={pathRoute && pathRoute}
					nameLink={nameLink}
					urlId={urlId.current}
				/>
			</div>
			{children}
		</>
	)
})
export default SelectedContainer
