import sortList from '@root-gipro/modules/commonProjects/models/const/sort-list.const'
import {
	UserArchivesActionsTypes,
	UserArchivesType,
} from '@root-gipro/modules/userArchive/models/interfaces/user-project.actions'
import {
	IUnc,
	IUserArchive,
	IUserArchiveState,
} from '@root-gipro/modules/userArchive/models/interfaces/user-projects.model'
import produce, { Draft } from 'immer'

const clearProject: IUserArchive = {
	code: '',
	name: '',
	sumUncCost: null,
	sumUncCostWithNds: null,
	totalInvestmentUncOffer: null,
	yearBegin: null,
	yearEndOffer: null,
	actualFunding: null,
	valuationOfferForecast: null,
	additionalFundingOffer: null,
	offerRationale: '',
	years: {},
	regionIds: [],
	filialId: '',
	nds: null,
	t7Id: '',
	created_at: Math.floor(Date.now() / 1000),
	archived_at: 0,
	archive: 0,
	groupNum: '',
	phase: '',
	decreeName: '',
	decreeNumber: '',
	notExceeding: null,
	techConnect: false,
	onlyPIR: false,
	locked: false,
	t7Version: null,
	version: '',
	versions: [],
	unc_sort: '2018',
	ipr_id: null,
}

const initialState: IUserArchiveState = {
	projectList: [],
	totalInvestmentUncOfferSum: 0,
	userProjectsCnt: 0,
	paginationCounter: 0,
	originalObj: clearProject,
	projectObj: clearProject,
	isNew: false,
	activeUncs: [],
	uncs: [],
	targetVoltageValues: {},
	targetVoltageId: null,
	uncTableData: null,
	selectedElem: null,
	uncAddBuffer: [],
	uncFilter: '',
	projectFilter: '',
	interpolation: {},
	projectErrors: null,
	sectionIds: [
		{ id: '1', name: 'Подготовка территории' },
		{ id: '2', name: 'ПИР' },
		{ id: '3', name: 'Задвоения с ячейками' },
		{ id: '4', name: 'Задвоения прочие' },
		{ id: '5', name: 'ВЛ' },
		{ id: '6', name: 'КЛ' },
	],
	loading: false,
	uncEditMode: false,
	projectsErrors: false,
	sort: {
		name: sortList[0].name,
		sortName: sortList[0].sortName,
		order: true,
	},
	showLoader: false,
	t7Data: [],
	loadingT7Data: false,
	t7EditMode: false,
	groupNum: '',
	phase: '',
	decreeName: '',
	allowMoreUserProjects: true,
	decreeNumber: '',
	notExceeding: null,
	locked: false,
	t7Version: null,
	uncCheckedTable: 6,
}

const userArchive = (draft: Draft<IUserArchiveState>, action: UserArchivesActionsTypes) => {
	switch (action.type) {
		case UserArchivesType.FETCH_REQUEST:
			draft.loading = true
			break
		case UserArchivesType.CHANGE_UNC_CHECKED_TABLE:
			draft.uncCheckedTable = action.uncCheckedTable
			break
		case UserArchivesType.LOADING_USER_PROJECTS:
			draft.loading = action.loading
			break
		case UserArchivesType.LOADING_T7DATA:
			draft.loadingT7Data = action.loading
			break
		case UserArchivesType.SET_USER_PROJECTS:
			draft.projectList = action.projects
			draft.totalInvestmentUncOfferSum = Number(action.totalInvestmentUncOfferSum)
			draft.userProjectsCnt = action.userProjectsCnt
			break
		case UserArchivesType.SET_USER_PROJECTS_COUNT:
			draft.paginationCounter = action.paginationCounter
			break
		case UserArchivesType.ADD_USER_PROJECTS:
			draft.projectList = [...draft.projectList, ...action.projects]
			break

		case UserArchivesType.SET_ALLOW_MORE_USER_PROJECTS:
			draft.allowMoreUserProjects = action.allowMoreUserProjects
			break
		case UserArchivesType.CHOOSE_PROJECT:
			draft.projectObj = action.project
			break

		case UserArchivesType.SET_ORIGINAL_OBJ:
			draft.originalObj = action.project
			break

		case UserArchivesType.SET_IS_NEW_PROJECT:
			draft.isNew = action.isNew
			break

		case UserArchivesType.SET_ACTIVE_UNCS:
			draft.activeUncs = action.uncs
			break
		case UserArchivesType.SET_PROJECT_UNCS:
			draft.loading = false
			draft.uncs = action.uncs
			// draft.activeUncs = action.uncs
			break
		case UserArchivesType.SET_UNC_EDIT_MODE:
			draft.uncEditMode = action.mode
			break
		case UserArchivesType.SET_T7_EDIT_MODE:
			draft.t7EditMode = action.mode
			break
		case UserArchivesType.CREATE_PROJECT_TEMPLATE:
			draft.projectObj = clearProject
			draft.originalObj = clearProject
			break
		case UserArchivesType.SHOW_PROJECT_ERRORS:
			draft.projectsErrors = action.show
			break
		case UserArchivesType.SET_PROJECT_NAME:
			draft.projectObj.name = action.name
			break

		case UserArchivesType.SET_PROJECT_СODE:
			draft.projectObj.code = action.code
			break
		case UserArchivesType.SET_PROJECT_YEARS:
			draft.projectObj.years = action.years
			break

		case UserArchivesType.SET_YEARS:
			draft.projectObj.yearBegin = action.begin
			draft.projectObj.yearEndOffer = action.end
			break

		case UserArchivesType.SET_REGION_IDS:
			draft.projectObj.regionIds = action.regionIds
			draft.originalObj.regionIds = action.regionIds
			break
		case UserArchivesType.SET_FILIAL_ID:
			draft.projectObj.filialId = action.filialId
			draft.originalObj.filialId = action.filialId
			break

		case UserArchivesType.SET_VALUATION_OFFER_FORECAST:
			draft.projectObj.valuationOfferForecast = action.valuation
			break
		case UserArchivesType.SET_ADDITIONAL_FUNDING_OFFER:
			draft.projectObj.additionalFundingOffer = action.offer
			break
		case UserArchivesType.SET_OFFER_RATIONALE:
			draft.projectObj.offerRationale = action.offerRationale
			break
		case UserArchivesType.SET_ACTUAL_FUNDING:
			draft.projectObj.actualFunding = action.actualFunding
			break
		case UserArchivesType.SET_NDS:
			draft.projectObj.nds = action.nds
			break

		case UserArchivesType.SET_GROUP_TYPE:
			draft.projectObj.groupNum = action.groupNum
			break
		case UserArchivesType.SET_PHASE:
			draft.projectObj.phase = action.phase
			break
		case UserArchivesType.SET_DECREE_NAME:
			draft.projectObj.decreeName = action.decreeName
			break
		case UserArchivesType.SET_DECREE_NUM:
			draft.projectObj.decreeNumber = action.decreeNumber
			break
		case UserArchivesType.SET_NOT_EXCEED:
			draft.projectObj.notExceeding = action.notExceeding
			break

		case UserArchivesType.SET_UNC_TABLE_DATA:
			draft.uncTableData = action.uncTableData
			break
		case UserArchivesType.SELECT_TABLE_ELEM:
			draft.selectedElem = action.selectedElem
			break
		case UserArchivesType.SET_UNC_ADD_BUFFER:
			draft.loading = false
			draft.uncAddBuffer = action.buffer
			break
		case UserArchivesType.SET_UNC_FILTER:
			draft.uncFilter = action.filter
			break
		case UserArchivesType.SET_INTERPOLATION:
			draft.interpolation = action.obj
			break
		case UserArchivesType.SET_PROJECT_FILTER:
			draft.projectFilter = action.filter
			break
		case UserArchivesType.SET_PROJECT_ERRORS:
			draft.projectErrors = action.errors
			break
		case UserArchivesType.SET_VOLTAGE:
			const indUncAddBuffer = draft.uncAddBuffer.findIndex(item => {
				if (item.id === action.uncId) return item
			})
			const indUncs = draft.uncs.findIndex(item => item.id === action.uncId)
			const newArrUncAddBuffer = draft.uncAddBuffer
			const newArrUncs = draft.uncs
			if (indUncAddBuffer >= 0) {
				newArrUncAddBuffer[indUncAddBuffer]['targetVoltageValues'] = action.targetVoltageValues
			}
			if (indUncs >= 0) {
				newArrUncs[indUncs]['targetVoltageValues'] = action.targetVoltageValues
			}
			draft.targetVoltageValues = action.targetVoltageValues // возможно нужно удаалить
			draft.uncAddBuffer = [...newArrUncAddBuffer]
			draft.uncs = [...newArrUncs]
			break
		case UserArchivesType.SET_VOLTAGE_ID:
			const indUncAddBuffer_ID = draft.uncAddBuffer.findIndex(item => item.id === action.uncId)
			const indUncs_ID = draft.uncs.findIndex(item => item.id === action.uncId)

			const newArrUncAddBuffer_ID = draft.uncAddBuffer
			const newArrUncs_ID: IUnc[] = draft.uncs
			if (indUncAddBuffer_ID >= 0) {
				newArrUncAddBuffer_ID[indUncAddBuffer_ID]['targetVoltageId'] = action.targetVoltageId
			}
			if (indUncs_ID >= 0) {
				newArrUncs_ID[indUncs_ID]['targetVoltageId'] = action.targetVoltageId
			}
			draft.targetVoltageId = action.targetVoltageId // возможно нужно удаалить
			draft.uncAddBuffer = [...newArrUncAddBuffer_ID]
			draft.uncs = [...newArrUncs_ID]
			break

		case UserArchivesType.SORT_USER_PROJECTS:
			draft.sort.name = action.name
			draft.sort.sortName = action.sortName
			draft.sort.order = action.order
			break
		default:
			return draft
	}
}

export const userArchivesReducer = produce(userArchive, initialState)
