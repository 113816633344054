import React from 'react'
import { CardDetailedInfoItemProps } from '@root-gipro/shared/Card/CardDetailedInfo/card-detailed-info.props'

const CardDetailedInfoItem: React.FC<CardDetailedInfoItemProps> = ({
	title,
	value,
	projects,
	clickLabel,
	clickValue,
	classes,
	registration_number,
}) => {
	value = value || 'Нет данных'
	let className = 'card-container__detailed-info__item-value'

	if (value === null) {
		className += ' card-container__detailed--disabled'
	}
	if (clickValue) {
		className += ' link link-blue'
	}
	const handleClickValue = () => {
		if (clickValue && registration_number) clickValue(registration_number)
		return
	}
	return (
		<div className={`card-container__detailed-info__item ${classes ? classes : ''}`}>
			<div
				className={`card-container__detailed-info__item-title ${
					projects && projects > 0 && 'project__link link link-blue'
				}`}
				onClick={clickLabel}
			>
				{title}
			</div>
			<div className={className} onClick={handleClickValue}>
				{value}
			</div>
		</div>
	)
}

export default CardDetailedInfoItem
