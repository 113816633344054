import React, { FC } from 'react'
import { Modal } from '@root-gipro/shared/Modal/Modal'
export interface SelectF20Props {
	title?: string;
	handler1: () => void;
	handler2: () => void;
	setAnchorEl(event: HTMLElement): void;
	anchorEl: (EventTarget & HTMLElement) | null | undefined;
}
const SelectF20Window: FC<SelectF20Props> = ({ anchorEl, handler1, handler2, setAnchorEl }) => {
	const buttons = {
		PrimaryBtn: {
			handler: handler1,
			value: 'Скачать',
		},

		SecondaryBtn: {
			handler: handler2,
			value: 'Отменить',
		},
	}
	const title = [
		{
			classes: 'leftTitle',
			value: 'Скачать форму 20',
			action: undefined,
		},
	]
	return (
		<Modal buttonOptions={buttons} setAnchorEl={setAnchorEl} anchorEl={anchorEl} title={title}>
			<div>Выберите версию</div>
		</Modal>
	)
}
export default SelectF20Window
