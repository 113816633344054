import React from 'react'
import { Routes } from '@root-gipro/router'
import { useLocation } from 'react-router-dom'
import { NameLink } from '@root-gipro/modules/header/models/header-name-link.const'

const usePageLink = () => {
	const { pathname } = useLocation()
	if (pathname.indexOf(Routes.USER_PROJECTS) === 0) {
		return { currentPath: pathname, pathRoute: Routes.USER_PROJECTS, nameLink: NameLink.USER_PROJECTS }
	}
	if (pathname.indexOf(Routes.USER_ARCHIVE) === 0) {
		return { currentPath: pathname, pathRoute: Routes.USER_ARCHIVE, nameLink: NameLink.USER_ARCHIVE }
	}

	if (pathname.indexOf(Routes.COMMON_PROJECTS) === 0) {
		return { currentPath: pathname, pathRoute: Routes.COMMON_PROJECTS, nameLink: NameLink.COMMON_PROJECTS }
	}
	if (pathname.indexOf(Routes.PURCHASES) === 0) {
		return { currentPath: pathname, pathRoute: Routes.PURCHASES, nameLink: NameLink.PURCHASES }
	}
	return { currentPath: pathname, pathRoute: Routes.COMMON_PROJECTS, nameLink: NameLink.COMMON_PROJECTS }
}

export { usePageLink }
