import React, { FC, useEffect, useState } from 'react'

import style from '@root-gipro/modules/AdminPanel/styles/adminPanel.module.scss'

import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import AdminPanel from './components/AdminPanel/AdminPanel'
import AdminSettings from './components/AdminSettings/AdminSettings'
import { Routes } from '@root-gipro/router'
import { useDispatch } from 'react-redux'
import { getDeflators } from '../userProjects/store/actions'
import AdminPanelCompany from './components/AdminCompanies/AdminPanelCompany'

const drawerWidth = 256

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			margin: '0 auto',
			maxWidth: '1280px',
			padding: 0,
		},
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		appBar: {
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: drawerWidth,
			},
		},
		menuButton: {
			marginRight: theme.spacing(2),
			[theme.breakpoints.up('sm')]: {
				display: 'none',
			},
		},
		// necessary for content to be below app bar
		drawerPaper: {
			position: 'relative',
			paddingTop: 24,
			border: 'none !important',
			width: drawerWidth,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
	})
)

const drawerList = [
	{ title: 'Пользователи', slug: 'users' },
	{ title: 'Компании', slug: 'companies' },
	{ title: 'Настройки', slug: 'settings' },
]

const AdminContainer: FC = () => {
	const dispatch = useDispatch()

	const classes = useStyles()
	const theme = useTheme()
	const [mobileOpen, setMobileOpen] = useState(false)

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	useEffect(() => {
		dispatch(getDeflators())
	}, [])

	const drawer = (
		<div>
			<List>
				{drawerList.map(item => (
					<ListItem
						button
						key={item.title}
						component={NavLink}
						activeClassName={style.active}
						className={style.navLink}
						to={'/admin/' + item.slug}
					>
						<ListItemText primary={item.title} />
					</ListItem>
				))}
			</List>
		</div>
	)

	return (
		<div className={classes.root}>
			<nav className={classes.drawer} aria-label='mailbox folders'>
				<Hidden smUp implementation='css'>
					<Drawer
						variant='temporary'
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation='css'>
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant='permanent'
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content}>
				<Switch>
					<Route path={Routes.ADMIN_USERS} component={AdminPanel} />
					<Route path={Routes.ADMIN_COMPANIES} component={AdminPanelCompany} />
					<Route path={Routes.ADMIN_SETTINGS} component={AdminSettings} />
					<Redirect from={Routes.ADMIN} to={Routes.ADMIN_USERS} />
				</Switch>
			</main>
		</div>
	)
}

export default AdminContainer
