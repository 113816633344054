import React, { useState } from 'react'
import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'

const classesFormControlLabelStyle = makeStyles(theme => ({
	root: {
		padding: '0 4px 4px',
		margin: 0,
	},
	label: {
		position: 'relative',
		fontSize: theme.typography.pxToRem(14),
		letterSpacing: '0.03333em',
		lineHeight: 1.3,
	},
}))

const classesCheckboxStyle = makeStyles(() => ({
	root: {
		padding: '0',
		marginRight: 8,
		color: 'gray',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',

		backgroundColor: '#fff',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.0)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '##fff',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(255,255,255,.5)',
		},
	},
}))

interface ICheckboxItem {
	checked: boolean
	disabled: boolean
	handler: (name: string, checked: boolean) => void
	label: string
	name: string
}

const CheckboxItem: React.FC<ICheckboxItem> = ({ checked, disabled, handler, label, name }) => {
	const classes = classesFormControlLabelStyle()
	const classesCheckbox = classesCheckboxStyle()

	const [checkedLocal, setCheckedLocal] = useState<boolean>(false)

	const handlerChange = () => {
		setCheckedLocal(!checkedLocal)
		handler(name, !checkedLocal)
	}

	return (
		<FormControlLabel
			classes={classes}
			label={label}
			control={
				<Checkbox
					color='primary'
					size='small'
					name={name}
					checked={checked}
					disabled={disabled}
					onChange={() => handlerChange()}
					className={classesCheckbox.root}
				/>
			}
		/>
	)
}

export default CheckboxItem
