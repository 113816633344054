import { IUnc } from '@root-gipro/store/interfaces'

const minPrice = 0
const maxPrice = 90000000000

export const filteredUncCellCode = function (query: string, uncs: IUnc[]): IUnc[] {
	const localQuery = query !== null ? query.toLowerCase().trim() : ''

	return uncs.filter(unc => unc.uncCell?.code.toLowerCase().trim().includes(localQuery))
}

export const filteredUncTechnicalSolutions = function (query: string, uncs: IUnc[]): IUnc[] {
	const localQuery = query !== null ? query.toLowerCase().trim() : ''

	return uncs.filter(unc => unc.uncMain.name.toLowerCase().trim().includes(localQuery))
}

export const filteredUncCount = function (query: string, uncs: IUnc[]) {
	let [startValue = minPrice, endValue = maxPrice] = query !== null ? query.split('-') : [minPrice, maxPrice]

	startValue = Number(startValue.toString().trim())

	if (endValue === '') {
		endValue = maxPrice
	}

	return uncs.filter(unc => Number(unc.count) >= startValue && Number(unc.count) <= endValue)
}

export const filteredUncUnit = function (query: string, uncs: IUnc[]) {
	const localQuery = query !== null ? query.toLowerCase().trim() : 'all'

	if (query === 'all' || localQuery === 'all') {
		return uncs
	} else {
		return uncs.filter(unc => unc.uncMain.unit.toLowerCase().trim().includes(localQuery))
	}
}

export const filteredUncParameters = function (query: string, uncs: IUnc[]) {
	const localQuery = query !== null ? query.toLowerCase().trim() : 'all'

	if (query === 'all' || localQuery === 'all') {
		return uncs
	} else {
		return uncs.filter(unc => unc.uncCell.parameter && unc.uncCell.parameter.toLowerCase().trim() === localQuery)
	}
}
export const filteredUncVoltage = function (query: string, uncs: IUnc[]) {
	const localQuery = query !== null ? query.toLowerCase().trim() : 'all'

	if (query === 'all' || localQuery === 'all') {
		return uncs
	} else {
		return uncs.filter(unc => unc.voltage && unc.voltage === localQuery)
	}
}

export const filteredUncCost = function (query: string, uncs: IUnc[]) {
	let [startValue = minPrice, endValue = maxPrice] = query !== null ? query.split('-') : [minPrice, maxPrice]
	startValue = Number(startValue.toString().trim())

	if (endValue === '') {
		endValue = maxPrice
	}

	return uncs.filter(unc => Number(unc.uncCell.cost) >= startValue && Number(unc.uncCell.cost) <= endValue)
}

export const filteredUncRegionFactor = function (query: string, uncs: IUnc[]): IUnc[] {
	const localQuery = query !== null ? query.toString().toLowerCase().trim() : ''

	const copyUnc = JSON.parse(JSON.stringify(uncs))
	// такой костыль в виде копирование объекта нужен, потому-что regionFactor может быть null и для фильтрации пришлось изменить null => 1.00
	const filteredUncsIds = copyUnc
		.map((unc: any) => {
			if (unc.regionFactor === null) {
				unc.regionFactor = {}
				unc.regionFactor.value = '1.00'
			}
			return unc
		})
		.filter((unc: any) => unc.regionFactor?.value.toString().toLowerCase().trim().includes(localQuery))
		.map((unc: any) => unc.id)

	return uncs.filter(unc => filteredUncsIds.some((uncId: number) => Number(unc.id) === Number(uncId)))
}

export const filteredTotalUncCost = function (query: string, uncs: IUnc[]) {
	let [startValue = minPrice, endValue = maxPrice] = query !== null ? query.split('-') : [minPrice, maxPrice]

	startValue = Number(startValue.toString().trim())

	if (endValue === '') {
		endValue = maxPrice
	}
	return uncs.filter(unc => Number(unc.totalCost / 1000) >= startValue && Number(unc.totalCost / 1000) <= endValue)
}
