import React, { useState, useRef, useEffect } from 'react'
import { setInterpolation } from '@root-gipro/modules/userProjects/store/actions'
import { connect } from 'react-redux'
import { IState } from '@root-gipro/store/interfaces/state'
import { ListItem } from '@material-ui/core/'

function changeHandler(arr: any, interpolation: any, action: any) {
	const clone = Object.assign({}, interpolation)
	arr.forEach((elem: any) => {
		clone[elem[0]] = elem[1]
	})
	action(clone)
}

const mapStateToProps = (state: IState) => {
	return {
		interpolation: state.userProjects.interpolation,
	}
}

const mapDispatchToProps = (dispatch: (arg0: { type: string; obj: any }) => any) => {
	return {
		setInterpolation: (obj: any) => dispatch(setInterpolation(obj)),
	}
}

const Select: React.FC<any> = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ data, interpolation, setInterpolation }: any) => {
	const [value, setValue] = useState(Array.isArray(data?.cases) ? data?.cases[0] : '')
	const [show, setShow] = useState(false)
	const [byHands, setBy] = useState(false)
	const [index, setIndex] = useState(0)

	const listElem = useRef<any>(null)
	const titleElem = useRef<any>(null)
	const outside = (e: any) => {
		if (
			listElem.current &&
			titleElem.current &&
			!listElem.current.contains(e.target) &&
			!titleElem.current.contains(e.target)
		) {
			setShow(false)
		}
	}

	useEffect(() => {
		document.addEventListener('click', outside)
		return () => document.removeEventListener('click', outside)
	})

	useEffect(() => {
		if (Array.isArray(data.cases)) {
			setValue(data.cases[0])
			changeHandler([[data.key, data.cases[0]]], interpolation, setInterpolation)
		}
	}, [data])

	let subSelects: any = null
	if (data.selects) subSelects = Object.values(data.selects)
	return (
		<>
			<div className='interpol_elem'>
				<div className='interpol_title'>{data.title}</div>
				<div
					ref={titleElem}
					onClick={() => {
						setShow(true)
					}}
					className='current_value'
				>
					{byHands ? (
						<input
							onClick={e => e.stopPropagation()}
							className='hands_input'
							onChange={e => {
								let val = e.currentTarget.value
								val = val.replace(/[^0-9.,]/, '')
								val = val.replace(/,/, '.')
								setValue(val)
								changeHandler([[data.key, val]], interpolation, setInterpolation)
							}}
							value={value}
							placeholder='Значение'
						/>
					) : (
						<div className='interpol_value'>{value}</div>
					)}
				</div>
				{show && (
					<div ref={listElem} className='interpol_list'>
						{Array.isArray(data.cases)
							? data.cases.map((elem: any, ind: number) => {
									if (elem) {
										return (
											<ListItem
												button
												key={`${ind}_${performance.now}`}
												onClick={e => {
													e.stopPropagation()
													setBy(false)
													setValue(elem)
													setIndex(ind)
													changeHandler([[data.key, elem]], interpolation, setInterpolation)
													setTimeout(() => setShow(false), 0)
												}}
											>
												{elem}
												{/* </div> */}
											</ListItem>
										)
									} else {
										return (
											<ListItem
												button
												key={`${ind}_${performance.now}`}
												onClick={() => {
													setValue('')
													setBy(true)
													setIndex(ind)
													changeHandler(
														[
															[data?.key, ''],
															[data?.selects?.[0].key, 'deleted'],
														],
														interpolation,
														setInterpolation
													)
													setTimeout(() => setShow(false), 0)
												}}
											>
												Ввести значение
											</ListItem>
										)
									}
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  })
							: null}
					</div>
				)}
			</div>
			{subSelects &&
				subSelects[index] &&
				(subSelects[index].type === 'select' || subSelects[index].type === 'input-select' ? (
					<Select changeHandler={changeHandler} data={subSelects[index]} />
				) : (
					<Input changeHandler={changeHandler} data={subSelects[index]} />
				))}
		</>
	)
})

const Input: React.FC<any> = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ data, interpolation, setInterpolation }: any) => {
	const [value, setValue] = useState('')

	return (
		<div className='interpol_elem'>
			<div className='interpol_title'>{data.title}</div>
			<input
				type='text'
				onChange={e => {
					let val = e.currentTarget.value
					val = val.replace(/[^0-9.,]/, '')
					val = val.replace(/,/, '.')
					setValue(val)
					changeHandler([[data.key, val]], interpolation, setInterpolation)
				}}
				value={value}
			/>
		</div>
	)
})

const InterPol: React.FC<any> = ({ data, setInterpolation }) => {
	useEffect(() => {
		const obj: any = {}

		function updateObj(data: any) {
			data.forEach((elem: any) => {
				if (!elem) return
				if (elem.selects) updateObj(elem.selects)
				const key = elem.key
				if (obj[key]) return
				if (elem.type === 'select' || elem.type === 'input-select') {
					if (Array.isArray(elem?.cases)) obj[key] = elem?.cases[0]
				} else {
					obj[key] = ''
				}
			})
		}
		updateObj(data)
		setInterpolation(obj)
	}, [])

	return (
		<div className='interpol'>
			{Object.values(data).map((elem: any, ind: number) => {
				return (
					<>
						{elem.type === 'select' || elem.type === 'input-select' ? (
							<Select data={elem} key={`${ind}_${performance.now}`} />
						) : (
							<Input data={elem} key={`${ind}_${performance.now}`} />
						)}
					</>
				)
			})}
		</div>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(InterPol)
