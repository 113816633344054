import { TextField } from '@material-ui/core'
import React, { FC } from 'react'

interface IAdminCompanyInput {
	id: string
	label: string
	getValue: (name: string, value: string) => void
	type: string
	defaultValue: string | number | undefined
}

const AdminCompanyInput: FC<IAdminCompanyInput> = ({ id, label, getValue, type, defaultValue }) => {
	const [value, setValue] = React.useState(defaultValue)

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		getValue(id, event.target.value)
		setValue(event.target.value)
	}

	return (
		<div>
			<label style={{ marginBottom: 8, display: 'block' }}>{label}</label>
			<TextField
				type={type}
				// classes={value === "" ? classes : undefined}
				id={id}
				value={value}
				placeholder={`*${label}`}
				variant='outlined'
				onChange={handleChange}
			/>
		</div>
	)
}

export default AdminCompanyInput
