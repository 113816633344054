import React from 'react'
import { Tab as MuiTab, TabProps, Tabs, withStyles } from '@material-ui/core'

const TabsGroup = withStyles(() => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiTabs-flexContainer': { border: '1px solid #1baaf0', borderRadius: 4 },
	},

	indicator: {
		display: 'none',
	},
}))(Tabs)

const Tab = withStyles(() => ({
	root: {
		color: 'black',
		fontSize: '14px',
		lineHeight: '16px',
		height: 32,
		padding: '4px 8px',
		transition: '0.3s',
	},
}))((props: TabProps) => <MuiTab disableRipple {...props} />)

export { TabsGroup, Tab }
