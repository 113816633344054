import { Tab, Tabs } from '@material-ui/core/'
import { IToggleTabsProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import React, { useEffect } from 'react'

export const ToggleTabs: React.FC<IToggleTabsProps> = ({ items, clickHandler, defaultValue, selectedElemCode }) => {
	const [value, setValue] = React.useState(0)
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
	}

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue)
		} else {
			setValue(0)
		}
	}, [defaultValue])

	return (
		<Tabs value={value} onChange={handleChange} textColor='primary' className='toggler' variant='fullWidth'>
			{items.map((item, ind) => {
				return (
					<Tab
						label={item.name}
						key={ind}
						onClick={() => clickHandler(item)}
						disabled={selectedElemCode ? selectedElemCode === 'С1' && item.value !== '8' : false}
					/>
				)
			})}
		</Tabs>
	)
}
