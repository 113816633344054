import React, { FC, useEffect, useState } from 'react'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { ISelectTableTbodyProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { BodyTableData } from '@root-gipro/store/interfaces'
import { hidenUncs } from '@root-gipro/utils/helpers/hidenUncs'

export const SelectTableTbody: FC<ISelectTableTbodyProps> = ({ uncTableData, handlerAddUnc }) => {
	const [hideUnc, setHideUnc] = useState<any>()

	useEffect(() => {
		if (uncTableData) {
			const intersection = hidenUncs.find(item => item.id == uncTableData.id)
			setHideUnc(intersection)
		}
	}, [uncTableData])
	return (
		<tbody>
			{uncTableData &&
				uncTableData.body &&
				uncTableData.body?.map((elem: BodyTableData[], ind: number) => {
					return (
						<tr key={ind}>
							{elem.map(td => {
								if (td.class === 'invis') return null
								if (hideUnc?.y?.find((y: any) => y === td.y)) return null
								if (hideUnc?.x?.find((x: any) => x === td.x)) return null
								return (
									<td
										className={
											td.uncCellId && !uncTableData.interpolation && uncTableData.active ? 'active_unc_td' : ''
										}
										key={`${td.x}_${td.y}`}
										onClick={() => {
											td.uncCellId && handlerAddUnc(td.uncCellId, uncTableData.interpolation, uncTableData.active)
										}}
									>
										{td.uncCellId && !uncTableData.interpolation && uncTableData.active ? prettyCost(td.name) : td.name}
									</td>
								)
							})}
						</tr>
					)
				})}
		</tbody>
	)
}
