import React, { ChangeEvent, FC, FocusEvent, useEffect, useRef, useState } from 'react'
import { Input } from '@root-gipro/shared/FormElements'

interface Props {
	labelName?: string;
	inputName?: string;
	handleInput?(e: ChangeEvent<HTMLInputElement>): void;
	handleBlur?(e: ChangeEvent<HTMLInputElement>): void;
	onFocus?(e: FocusEvent<HTMLInputElement>): void;
	inputValue?: string | number | undefined;
	type?: string;
	disabled?: boolean;
	classes?: string;
	placeholderValue?: string;
	changeFunc?: (value: string) => void;
	InputIntShow?: boolean;
	inputRef?: unknown;
	required?: boolean;
	errors?: boolean;
	helperText?: string | undefined;
	mask?: string;
	precision?: number;
	inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
}
const InputBox: FC<Props> = props => {
	const inputRefer = useRef<HTMLInputElement>(null)
	const [focusPosition, setFocusPosition] = useState<number>(0)

	useEffect(() => {
		if (inputRefer.current !== null) {
			inputRefer.current.setSelectionRange(focusPosition, focusPosition)
		}
	}, [focusPosition])

	return (
		<>
			<Input inputRef={inputRefer} {...props} setFocusPosition={setFocusPosition}>
				<span className='currency'>млн.р</span>
			</Input>
		</>
	)
}

export default InputBox
