import { ISortItem } from '@root-gipro/store/interfaces'

const sortList: ISortItem[] = [
	{
		name: 'Идентификатор',
		subTitle: 'Прогнозируемые цены учитывают потенциальное изменение цен в результате сезонности и инфляции',
		sortName: 'code',
		order: true,
	},
	{
		name: 'Идентификатор',
		subTitle: 'Прогнозируемые цены учитывают потенциальное изменение цен в результате сезонности и инфляции',
		sortName: 'code',
		order: false,
	},
	{
		name: 'Наименование проекта',
		sortName: 'name',
		order: true,
	},
	{
		name: 'Наименование проекта',
		sortName: 'name',
		order: false,
	},
]

export default sortList
