import React, { memo, FC, useState } from 'react'
import { Collapse } from '@material-ui/core'
import { ISectionIds } from '@root-gipro/store/interfaces'

interface ErrorsListProps {
	projectErrors: {
		[key: string]: string[];
	} | null;
	sectionIds: ISectionIds[];
	name: string;
	index: number;
}

const MassErrorItem: FC<ErrorsListProps> = memo(({ projectErrors, sectionIds, name, index }) => {
	const [isExpand, setIsExpand] = useState(false)

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setIsExpand(!isExpand)
	}

	return (
		<div className='errors-item'>
			<div onClick={e => handleClick(e)} className='errors-item__title'>
				<span className={`dropdown-icon-bold mr-8 ${isExpand ? 'open' : ''}`}></span>
				{sectionIds[parseInt(name) - 1].name}
				{projectErrors![name].length > 0 && (
					<span className='count-error ml-8'> {projectErrors![name].length} ошибка</span>
				)}
			</div>
			<Collapse in={isExpand} unmountOnExit>
				{projectErrors![name].length > 0 ? (
					projectErrors![name].map((textErr: string, i: number) => (
						<div key={i + textErr} className='errors-item__desc'>
							<span className='error-icon'></span>
							<span className='errors-item__error-shrt-txt'>{textErr}</span>
						</div>
					))
				) : (
					<div key={'errors-item'} className='errors-item__success'>
						<span className='success-icon'></span>
						<span className='errors-item__success-shrt-txt'>Ошибок не обнаружено!</span>
					</div>
				)}
			</Collapse>
		</div>
	)
})
export default MassErrorItem
