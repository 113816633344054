import React, { useEffect, useState } from 'react'
import { ISelectTableTheadProps } from '@root-gipro/modules/userProjects/models/interfaces/project-unc-add.props'
import { hidenUncs } from '@root-gipro/utils/helpers/hidenUncs'

export const SelectTableThead: React.FC<ISelectTableTheadProps> = ({ uncTableData }) => {
	const [hideUnc, setHideUnc] = useState<any>()

	useEffect(() => {
		if (uncTableData) {
			const intersection = hidenUncs.find(item => item.id == uncTableData.id)
			setHideUnc(intersection)
		}
	}, [uncTableData])
	return (
		<thead>
			{uncTableData &&
				uncTableData.header &&
				uncTableData.header?.map((elem: any, ind: number) => {
					return (
						<tr key={ind}>
							{Object.values(elem).map((td: any) => {
								if (td.class === 'invis') return null
								if (hideUnc?.y?.find((y: any) => y === td.y)) return null
								if (hideUnc?.x?.find((x: any) => x === td.x)) return null
								return (
									<th
										key={`${td.x}_${td.y}`}
										colSpan={td.col ? (hideUnc?.col ? hideUnc.col : td.col) : null}
										rowSpan={td.row ? td.row : null}
									>
										{td.name}
									</th>
								)
							})}
						</tr>
					)
				})}
		</thead>
	)
}
