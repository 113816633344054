import React from 'react'
import { Checkbox, makeStyles, FormControlLabel } from '@material-ui/core'
import { FilterItemCheckboxProps } from '@root-gipro/shared/Filter/models/filter-item-checkbox.props'

const classesFormControlLabelStyle = makeStyles(theme => ({
	root: {
		padding: '0 4px 4px',
		margin: 0,
	},
	label: {
		position: 'relative',
		fontSize: theme.typography.pxToRem(14),
		letterSpacing: '0.03333em',
		lineHeight: 1.3,
	},
}))

const classesCheckboxStyle = makeStyles(() => ({
	root: {
		padding: '0',
		marginRight: 8,
		color: 'gray',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',

		backgroundColor: '#fff',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.0)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '##fff',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(255,255,255,.5)',
		},
	},
}))

const FilterItemCheckbox: React.FC<FilterItemCheckboxProps> = ({ value, setValue, name, isDisable, fieldName }) => {
	const classes = classesFormControlLabelStyle()
	const classesCheckbox = classesCheckboxStyle()

	return (
		<div className='aside-filter__checkbox'>
			<FormControlLabel
				classes={classes}
				label={name}
				control={
					<Checkbox
						color='primary'
						disabled={isDisable}
						checked={Boolean(value)}
						onChange={() => setValue(fieldName, !value)}
						className={classesCheckbox.root}
					/>
				}
			/>
		</div>
	)
}
export default FilterItemCheckbox
