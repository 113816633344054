import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import { createStyles, FormControl, Input, makeStyles, Theme } from '@material-ui/core'
import TheadCellFieldSelect from './TheadCellFieldSelect'
import { IState } from '@root-gipro/store/interfaces'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		margin: {},
		withoutLabel: {
			marginTop: theme.spacing(1),
		},
		textField: { border: '1px solid #e0e0e0', background: '#fff', borderRadius: '4px' },
	})
)

interface TheadCellFilteredFieldProps {
	item: { title: string; textAlign: string };
	handleChangeInput: (inputFiled: string, value: string) => void;
}

const TheadCellFilteredField: React.FC<TheadCellFilteredFieldProps> = ({ item, handleChangeInput }) => {
	const classes = useStyles()

	const { uncThDescriptions } = useSelector((state: IState) => state.public)

	const [inputValue, setInputValue] = useState('')
	useEffect(() => {
		const localInputValue = inputValue
		handleChangeInput(item.title, localInputValue)
	}, [inputValue])

	const handleChangeInputValue = (inputFiled: string, value: string) => {
		handleChangeInput(inputFiled, value)
	}

	return (
		<>
			{item.title === 'Техническое решение' ||
			item.title === 'Расценка' ||
			item.title === 'Кол-во' ||
			item.title === 'Величина затрат' ||
			item.title === 'Региональный коэф.' ||
			item.title === 'Укр. норматив цены' ? (
				<FormControl className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
					<Input
						onChange={e => setInputValue(e.target.value)}
						className={'theadCell'}
						id='standard-adornment-weight'
						aria-describedby='standard-weight-helper-text'
					/>
				</FormControl>
			) : null}
			{item.title === 'Ед.изм.' ? (
				<TheadCellFieldSelect
					item={item}
					handleChangeInputValue={handleChangeInputValue}
					label={item.title}
					width={80}
					selectItems={['ед', 'шт', 'к-т', 'м', 'т', 'км', 'м3', 'кг', 'м2', 'ячейка']}
				/>
			) : null}
			{item.title === 'Параметры' ? (
				<TheadCellFieldSelect
					item={item}
					handleChangeInputValue={handleChangeInputValue}
					label={item.title}
					width={120}
					selectItems={uncThDescriptions.map(item => item.title)}
				/>
			) : null}
			{item.title === 'Напр., кВ' ? (
				<TheadCellFieldSelect
					item={item}
					handleChangeInputValue={handleChangeInputValue}
					label={item.title}
					width={80}
					selectItems={['0.4', '35', '110', '220', '330', '500', '750']}
				/>
			) : null}
		</>
	)
}

export default TheadCellFilteredField
