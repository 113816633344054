import React, { memo, FC, useState } from 'react'
import { Collapse } from '@material-ui/core'
import { ISectionIds } from '@root-gipro/store/interfaces'
import MassErrorItem from './MassErrorItem'

interface ErrorsListProps {
	projectErrors: {
		[key: string]: string[];
	} | null;
	userProjectCode: string;
	sectionIds: ISectionIds[];
}

const MassErrorList: FC<ErrorsListProps> = memo(({ projectErrors, sectionIds, userProjectCode }) => {
	const [isExpand, setIsExpand] = useState(true)

	const handleClick = () => {
		setIsExpand(!isExpand)
	}
	return (
		<div className='errors-list'>
			<span className='massErrors__title' onClick={handleClick}>
				{userProjectCode}
			</span>
			{projectErrors ? (
				Object.keys(projectErrors).map((name: string, index: number) => {
					return (
						<div key={index}>
							<Collapse in={isExpand} unmountOnExit key={index}>
								<MassErrorItem projectErrors={projectErrors} sectionIds={sectionIds} name={name} index={index} />
							</Collapse>
						</div>
					)
				})
			) : (
				<>
					{sectionIds.map((item: ISectionIds) => (
						<div key={item.id} className='errors-item'>
							<div className='errors-item__title'>{item.name}</div>
							<div className='errors-item__success'>
								<span className='success-icon'></span>
								<span className='errors-item__success-shrt-txt'>Ошибок не обнаружено!</span>
							</div>
						</div>
					))}
				</>
			)}
		</div>
	)
})
export default MassErrorList
