import React, { useEffect, useState } from 'react'

import { createStyles, FormControl, InputBase, makeStyles, MenuItem, Select, styled, Theme } from '@material-ui/core'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	'label + &': {
		marginTop: theme.spacing(3),
	},
	'& .MuiInputBase-input': {
		borderRadius: 4,
		width: 120,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #e0e0e0',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'Manrope',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
		},
	},

	'& .MuiSelect-icon': {
		padding: '0',
	},
}))

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			height: 36,
			boxSizing: 'border-box',
		},
		margin: {},
		withoutLabel: {
			marginTop: theme.spacing(1),
		},
		textField: { border: '1px solid #e0e0e0', background: '#fff', borderRadius: '4px' },
	})
)

interface TheadCellFieldSelectProps {
	item: { title: string; textAlign: string };
	handleChangeInputValue: (inputFiled: string, value: string) => void;
	selectItems: any[];
	label: string;
	width: number;
}

const TheadCellFieldSelect: React.FC<TheadCellFieldSelectProps> = ({
	item,
	handleChangeInputValue,
	label,
	selectItems,
	width,
}) => {
	const classes = useStyles()
	const [inputValue, setInputValue] = useState('')

	const handleChange = (value: any) => {
		setInputValue(value)
	}

	useEffect(() => {
		const localInputValue = inputValue

		handleChangeInputValue(item.title, localInputValue)
	}, [inputValue])

	return (
		<div>
			<FormControl className={classes.margin} style={{ width: width, height: 36 }}>
				<Select
					labelId={label}
					id={label}
					value={inputValue}
					onChange={e => handleChange(e.target.value)}
					input={<BootstrapInput />}
				>
					<MenuItem value={'all'}>Все</MenuItem>
					{selectItems.map(item => (
						<MenuItem style={{ whiteSpace: 'unset', wordBreak: 'break-all', width: '300px' }} key={item} value={item}>
							{item}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	)
}

export default TheadCellFieldSelect
