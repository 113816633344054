import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Item } from '@root-gipro/modules/userProjects/components/UserProjects/Item'
import { IUserProject } from '@root-gipro/store/interfaces'
import { IState } from '@root-gipro/store/interfaces/state'
import ScrollHandler from '@root-gipro/shared/ScrollHandler/ScrollHandler'
import { appendUserProjectsArchive } from '../../store/actions'
import Loader from '@root-gipro/shared/Loader/Loader'

const List: React.FC = () => {
	const dispatch = useDispatch()
	let { paginationCounter } = useSelector((state: IState) => state.userArchives)
	const { projectList, projectFilter, loading, allowMoreUserProjects } = useSelector(
		(state: IState) => state.userArchives
	)

	const onScroll = (e: any) => {
		const heightToBottom = 200
		const scrollTop = e.target.scrollTop
		const scrollHeight = e.target.scrollHeight
		const height = e.target.getBoundingClientRect().height

		if (!loading && allowMoreUserProjects && scrollTop && scrollHeight - scrollTop <= height + heightToBottom) {
			dispatch(appendUserProjectsArchive(++paginationCounter))
		}
	}

	return (
		<>
			<ScrollHandler />
			<div className='user-projects__list' onScroll={onScroll}>
				{projectList &&
					projectList
						.filter((project: IUserProject) => project.code.match(projectFilter) || project.name.match(projectFilter))
						.map(item => <Item key={item.id} data={item} />)}
				<div>{loading && <Loader />}</div>
			</div>
		</>
	)
}

export default List
